import {ListSubheader, useMediaQuery, useTheme} from "@mui/material";
import React from "react";
import {useMarket} from "../../../../common/contexts/MarketContext";
import SideNavigationMenuGroup from "./SideNavigationMenuGroup";
import List from "@mui/material/List";

interface Product {
    metadata: {
        productGroup?: string;
        productType?: string;
    };
}

interface ProductGroup {
    groupId: string;
    displayName: string;
    products: Product[];
}

function SideNavigationMenu() {
    const { state } = useMarket();
    const { productDetails } = state;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    function showProducts() {
        if (productDetails?.data?.length) {
            const productGroups: ProductGroup[] = [];

            for (const product of productDetails.data) {
                let groupId = product.metadata.productGroup || product.metadata.productType;
                groupId = groupId.charAt(0).toUpperCase() + groupId.slice(1);
                let group = productGroups.find(g => g.groupId.toLowerCase() === groupId.toLowerCase());
                if (!group) {
                    group = {
                        groupId: groupId || "Other",
                        displayName: groupId || "Other",
                        products: []
                    };
                    productGroups.push(group);
                }
                group.products.push(product);
            }

            return productGroups.reverse().map((group, index) => (
                <SideNavigationMenuGroup
                    key={index}
                    menuOptions={group}
                />
            ));
        }
        return null;
    }

    return (
        <>
            <List
                sx={{
                    width: '100%',
                    maxWidth: isMobile ? '100%' : '240px',
                    bgcolor: 'background.paper',
                    border: 1,
                    borderColor: 'divider'
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Marketplace Products
                    </ListSubheader>
                }
            >
                {showProducts()}
            </List>
        </>
    )
}

export default SideNavigationMenu;
