import React, {useContext, useEffect} from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import ProgressCircle from "../components/ProgressCircle";
import useUserDetails from "../hooks/useUserDetails";

export default function OpenRouteGuard({ children }: any) {
    const {checkAccountStatuses} = useContext(GeneralContext);
    const {user, loadingUser: loading, userDetails: usermeta, loadingUserDetails: loadingUserMeta} = useUserDetails();

    useEffect(() => {
        if (user && !loading && usermeta) {
            checkAccountStatuses(usermeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usermeta])

    if (loading || loadingUserMeta) {
        return <ProgressCircle/>;
    }

    return children
};
