import { useState, useCallback } from 'react';
import { useMarket } from "../../common/contexts/MarketContext";
import useFindProductDetails from "./useFindProductDetails";

// This hook encapsulates the logic to add required items to the pids
function useAddRequiredItems() {
    const { state, getProductPrice } = useMarket(); // Destructure state and functions needed from the context
    const { annualSelected } = state; // Destructure specific state variables
    const findProductDetails = useFindProductDetails(); // Use the findProductDetails hook
    const [clearInvoices, setClearInvoices] = useState(true);

    // useCallback will return a memoized version of the callback that only changes if one of the dependencies has changed
    const addRequiredItems = useCallback((pids: any[]) => {
        // Logic remains largely the same, but now it's inside a useCallback hook
        for (const product in pids) {
            const { productId } = pids[product];

            const productDetails = findProductDetails(productId);
            if (productDetails?.metadata?.requiredProduct) {
                const requiredPids = productDetails.metadata.requiredProduct.split(',');

                for (const pid of requiredPids) {
                    let requiredPid: any;

                    if (pid.includes(':')) {
                        const [pid_productId, pid_priceId] = pid.split(':');
                        requiredPid = getProductPrice(pid_productId, pid_priceId);

                        if (annualSelected && requiredPid.metadata.annualEquivalent) {
                            requiredPid = changeToAnnualPrice(requiredPid, pid_productId);
                        }
                    } else {
                        requiredPid = state.products[pid];
                    }

                    if (requiredPid && !pids.some(p => p.priceId === requiredPid.priceId)) {
                        setClearInvoices(false);
                        pids.push({ priceId: requiredPid.priceId, quantity: 1, type: requiredPid.type });
                    }
                }
            }
        }

        return pids;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, getProductPrice, findProductDetails, annualSelected]); // Dependencies

    // Function to change product price to annual
    const changeToAnnualPrice = useCallback((product: any, productId: string) => {
        return getProductPrice(productId, product.metadata.annualEquivalent);
    }, [getProductPrice]);

    return { addRequiredItems, clearInvoices, setClearInvoices };
}

export default useAddRequiredItems;
