import {useContext} from "react";
import {GeneralContext} from "../../../../../common/contexts/GeneralContext";

export default function useBuildPriceIds() {
    const {cart} = useContext(GeneralContext);
    return (() => {
        const pIds = [];
        if (Object.keys(cart).length) {
            for (const key of Object.keys(cart)) {
                pIds.push({priceId: key, quantity: parseInt(cart[key].quantity)});
            }
        }
        return pIds || [];
    })();
}