import React from 'react';
import {Box} from "@mui/material";
import NestedList from "../../../components/NestedList";

function GreenChartStarterFeatures() {
    const featureList = [
        {
            feature: 'Charting Platform',
            listItems: [
                '4800+ live market symbols (stocks, crypto, FOREX, CFDs) to analyze',
                '100+ technical indicators and studies to time the market',
                'Simple Drawing and Measuring tools',
                'Replay historical price movements',
                'Use Trade Simulator to practice trading in the live market - no broker account needed!',
                'DayTradeAI - AI Coaching Tool - ask any question, anytime',
            ]
        },
        {
            feature: 'Strategy Suite',
            listItems: [
                'Pattern Builder: 1 pattern',
                'Automated Backtester: 1 backtest credit*',
                'Pattern Alerts: 1 custom alert',
                'Alert Notifications (via SMS**, email): 10',
                'Strategy Showcase: View Winning Backtests by other users',
            ]
        },
        {
            feature: 'Training - Free training videos',
            listItems: [
                'GreenChart Basics',
                // '100+ technical indicators and studies to time the market',
            ]
        },
        {
            feature: 'Weekly webinars and podcasts',
            listItems: [
                'GreenChart Basics',
                'Traders Pulse Podcast',
            ]
        }
    ];

    return (
        <Box sx={{
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            lineHeight: "1.5",
            letterSpacing: "0.00938em",
            fontSize: "16px",
            alignItems: "left",
            margin: "0px"
        }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <NestedList items={featureList} />
        </Box>
    );
}

export default GreenChartStarterFeatures;