import {Box, ToggleButtonGroup, ToggleButton, useTheme, useMediaQuery} from "@mui/material";
import { MouseEvent } from "react";
import {useMarket} from "../../../common/contexts/MarketContext";

export default function AnnualToggle(props: { products: any }) {
    const {products} = props;
    const {state: {annualSelected}, dispatch} = useMarket();
    const setAnnualSelected = (value: boolean) => dispatch({type: 'SET_ANNUAL_SELECTED', payload: value});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    function toggle(e: Event | undefined, v: boolean){
        if(v !== null){
            setAnnualSelected(v);
        }
    }

    function renderAnnualToggle() {
        const saving = {
            fontSize: 12,
            color: '#5DD203',
        }
        if(products.every((product: any) => product.hasOwnProperty('annual'))) {
            return (
            <Box >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }}>
                    <ToggleButtonGroup
                        color={"primary"}
                        value={annualSelected}
                        exclusive
                        onChange={(e: MouseEvent, value: boolean) => toggle(undefined, value)}
                        sx={{ width: '100%', height: '100%' }}
                    >
                        <ToggleButton value={false} sx={{flexGrow: '1'}}>Monthly</ToggleButton>
                        <ToggleButton value={true} sx={{flexGrow: '1', display: 'block', lineHeight: '15px'}}>Yearly <br/> <span style={saving}>10% Off!</span> </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>)
        }
    }

    return (
        <Box
            sx={{
            width: '100%',
            justifyContent: isMobile ? 'center' : null,
            maxWidth: '260px',
            mb: '1rem',
            mt: '1rem',
        }}>
            {renderAnnualToggle()}
        </Box>
    );
}