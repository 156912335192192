import findSelectedProduct from "../../../../pages/app/marketplace/helpers/findSelectedProduct";
import {useSearchParams} from "react-router-dom";
import {useMarket} from "../../../../../common/contexts/MarketContext";

export default function useGetProductDescription() {
    const [searchParams] = useSearchParams();
    const {state} = useMarket();
    return () => {
        const description: any = [];
        const itemQuery = searchParams.get('display');
        if (itemQuery) {
            let data = findSelectedProduct(state.productDetails, itemQuery)?.metadata;
            description.push(data)
        }
        return description[0];
    }
}