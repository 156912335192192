import useUserDetails from "../../../../../common/hooks/useUserDetails";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { firebase_functions } from "../../../../../config/firebaseConfig";
import { useDialog } from "../../../../../common/contexts/DialogContext";

export default function useDirectToCreditCardPage() {
    const { userDetails } = useUserDetails();
    const { setOpenDialog } = useDialog();
    const [createPortalSession] = useHttpsCallable(
        firebase_functions,
        'createPortalSession'
    );

    return async () => {
        setOpenDialog('');
        try {
            const portalSession: any = await createPortalSession({
                stripeId: userDetails?.stripeCustomerId,
                redirectUrl: window.location.href,
            });

            const url = portalSession?.data?.url;
            if (url) {
                window.location.replace(url);
            } else {
                console.error('No URL provided by the portal session');
            }
        } catch (error) {
            console.error('Failed to create portal session.', error);
        }
    };
}
