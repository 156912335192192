import React, {useContext, useEffect, useState} from 'react';
import Header from "../../../common/components/Header";
import Footer from "../../../common/components/Footer";
import {Box, Chip, Container, Grid, Menu, MenuItem, Paper, Stack, Typography} from "@mui/material";
import CelebrationIcon from '@mui/icons-material/Celebration';
import {TradingHouseMapper} from "../../../common/helpers/TradingHouseMap";
import {useNavigate} from "react-router-dom";
import {doc, getDoc} from "firebase/firestore";
import {firebase_firestore} from "../../../config/firebaseConfig";
import {GeneralContext} from "../../../common/contexts/GeneralContext";
import {useExternalRedirect} from "../../../common/hooks/useExternalRedirect";
import useUserDetails from "../../../common/hooks/useUserDetails";

export default function PurchaseConfirmation() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    };
    const [tradingHouseMenuOpen, setTradingHouseMenuOpen] = useState<boolean>(false);
    const [tradingHouses, setTradingHouses] = useState<any[]>([]);
    const navigate = useNavigate();
    const {userDetails} = useUserDetails();
    const tempTradingHouses: any[] = [];
    const { hasCourse } = useContext(GeneralContext);
    const redirectToExternalSite = useExternalRedirect();

    useEffect(() => {
        if (userDetails && !tradingHouses.length) {
            const allPromises = [];

            for (const tradingHouse of Object.keys(userDetails?.tradingHouses)) {
                const docRef = doc(firebase_firestore, "tradingHouses", tradingHouse);
                allPromises.push(getDoc(docRef).then(docSnap => {
                    tempTradingHouses.push(docSnap.data());
                }))
            }

            Promise.all(allPromises).then(() => {
                setTradingHouses(tempTradingHouses)
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails]);

    function headToAccount(event: any) {
        navigate('/dashboard/account');
    }

    function headToGreenChart(event: any) {
        directToSite();
        handleClick(event);
    }

    function directToSite(tradingHouseName?: string) {
        if (tradingHouses.length === 0) {
            console.error('No trading houses');
        } else if (tradingHouseName) {
            const redirectLink = tradingHouses.find((th: any) => th.name === tradingHouseName).routes.greenchart;
            redirectToExternalSite(redirectLink);
        } else if (tradingHouses.length > 1) {
            setTradingHouseMenuOpen(true);
        } else {
            const redirectLink = tradingHouses[0].routes.greenchart;
            redirectToExternalSite(redirectLink);
        }
    }

    const handleClose = () => {
        setTradingHouseMenuOpen(false);
        setAnchorEl(null);
    };

    return <>
        <Header/>

        <Container style={{minHeight: '100vh'}}>
            <Paper elevation={8} sx={{textAlign: "center", mt: 4, p: 4}}>
                <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
                    <Box sx={{fontSize: "140px"}}>
                        <CelebrationIcon fontSize={"inherit"}/>
                    </Box>

                    <Typography variant="h3" mt={0}>
                        Your purchase is complete!
                    </Typography>

                    <Typography variant="subtitle1" >
                        You will be receiving a confirmation email with order details.
                    </Typography>

                    <Grid container justifyContent={"center"} gap={4}>
                        <Chip label="Go to GreenChart" color="primary" variant="outlined" onClick={headToGreenChart} />
                        <Menu
                            open={tradingHouseMenuOpen}
                            onClose={handleClose}
                            anchorEl={anchorEl}>
                            {tradingHouses.map(tradingHouse => {
                                return (
                                    <MenuItem
                                        key={tradingHouse.name}
                                        onClick={() => {directToSite(tradingHouse.name)}}
                                    >{TradingHouseMapper[tradingHouse.name]}</MenuItem>)
                            })}
                        </Menu>
                        {hasCourse && <Chip
                            label="Go To Learning Center"
                            color="primary"
                            variant="outlined"
                            onClick={() => void navigate('/externalRedirect', { state: { link: redirectToExternalSite('https://learn.greenchart.com')}})}/>}
                        <Chip label="View Account" color="primary" variant="outlined" onClick={headToAccount} />
                    </Grid>
                </Stack>

            </Paper>
        </Container>


        <Footer/>
    </>

}
