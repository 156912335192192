import {Box, CircularProgress} from "@mui/material";
import React from "react";

// I can center this horizontally, but when trying to get this centered otherwise the parent will have
// to define boundaries vertically and fill the space that the spinner will fill.
export default function ProgressCircle(props: {paddingTop?: string, size?: number, center?: boolean}) {
    const {paddingTop, size, center = true} = props;

    return (
        <Box
            sx={{
                display: 'flex',
                mx: center ? 'auto' : 'none',
                flexGrow: 'auto',
                alignContent: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                paddingTop: paddingTop || '20%',
            }}
        >
            <CircularProgress sx={{color: '#008000'}} size={`${size || 5}rem`}/>
        </Box>
    )
}
