export default function calculateNextPurchaseDate(createdUnix: number): string {
    const currentDate = new Date();
    const createdDate = new Date(createdUnix * 1000); // Convert Unix timestamp to JavaScript Date object
    const yearsSinceCreation = currentDate.getFullYear() - createdDate.getFullYear();

    // Start with the creation date and add the years since creation to get to the current year
    let nextPurchaseDate = new Date(createdDate);
    nextPurchaseDate.setFullYear(createdDate.getFullYear() + yearsSinceCreation);

    // If the next purchase date is already past in the current year, add one more year
    if (nextPurchaseDate < currentDate) {
        nextPurchaseDate.setFullYear(nextPurchaseDate.getFullYear() + 1);
    }

    // Return the next purchase date in the specified format
    return `${new Date(nextPurchaseDate.getTime()).toLocaleDateString()}`;
}