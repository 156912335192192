import NoCreditCardDialog from "../../dashboard/components/marketplace/noCreditCardDialog";
import {Button} from "@mui/material";
import React, {useState} from "react";
import useDirectToCreditCardPage from "../../dashboard/components/marketplace/renderProductPrices/utils/useDirectToCreditCardPage";

export default function NoCardDialog() {
    const directToCreditCard = useDirectToCreditCardPage();
    const [ccDialogOpen, setCCDialogOpen] = useState(false);
    function cancelCCDialog() {
        setCCDialogOpen(false);
    }

    return <NoCreditCardDialog
        isOpen={ccDialogOpen}
        onClose={cancelCCDialog}
        cancelFunction={cancelCCDialog}
        title={'Add a Credit Card?'}
    >
        <>
            There is no credit card on file for your account, to add one click <Button onClick={ () => directToCreditCard}>here</Button>
        </>
    </NoCreditCardDialog>
}