import {useMarket} from "../contexts/MarketContext";

const useRefreshMarketplaceItems = () => {
    const { getProducts } = useMarket();

    // Return a function that when called, will invoke getProducts
    return () => {
        getProducts();
    };
}

export default useRefreshMarketplaceItems;