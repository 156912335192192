import {TextField} from "@mui/material";

export default function LastNameField(props: {setLastName: (n: string) => void, required?: boolean}) {
    const {setLastName, required} = props;

    return (
        <TextField
            required={required}
            label="Last Name"
            variant="outlined"
            onChange={e => {setLastName(e.target.value)}}
            inputProps={{
                style: {
                    padding: '16.5px 14px',
                }
            }}
            sx={{
                width: '100%'
            }}
        />
    );
}