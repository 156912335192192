import React, {useContext, useEffect} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {firebase_auth} from "../../../config/firebaseConfig";
import useTitle from "../../../common/hooks/useTitle";
import {GeneralContext} from "../../../common/contexts/GeneralContext";
import {useNavigate, useSearchParams} from "react-router-dom";


const iframeStyle: React.CSSProperties = {
    width: '100%',
    height: 'calc(100% - 64px)',
    border: 'none',
};

function StrategySuite() {

    useTitle('Strategy Suite');
    const [idToken, setIdToken] = React.useState<string | null>(null);
    const [user] = useAuthState(firebase_auth);
    const { hasStrategySuiteAccess } = useContext(GeneralContext);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [linkId, setLinkId] = React.useState<string | null>(null);

    useEffect(() => {
        if (user) {
            user.getIdToken().then((idToken) => {
                setIdToken(idToken)
            })
        }
    }, [user]);

    useEffect(() => {
        if (!hasStrategySuiteAccess && hasStrategySuiteAccess !== null) {
            return navigate('/dashboard/marketplace?display=membership')
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasStrategySuiteAccess]);

    useEffect(() => {
        const pid = searchParams.get('pid');
        if (pid) {
            // linkId = pid;
            setLinkId(pid);
            searchParams.delete('pid');
            setSearchParams(searchParams);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    return (
        <iframe
            title="Pattern Builder"
            allow="clipboard-write"
            src={`${process.env.REACT_APP_PATTERN_BUILDER_URL}?idToken=${idToken}${linkId ? `&pid=${linkId}` : ''}`}
            style={{ ...iframeStyle, position: 'absolute', top: 64, left: 0, bottom: 0 }}
        />
    )
}

export default StrategySuite
