import MarketplaceDialog from "../../dashboard/components/marketplace/MarketplaceDialog";
import React from "react";
import {useMarket} from "../contexts/MarketContext";
import {useDialog} from "../contexts/DialogContext";
import useUpgradeItem from "../../dashboard/components/marketplace/renderProductPrices/utils/useUpgradeItem";
import useDowngradeItem from "../../dashboard/components/marketplace/renderProductPrices/utils/useDowngradeItem";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../config/firebaseConfig";
import {useNavigate} from "react-router-dom";

export default function Dialog() {
    const navigate = useNavigate();
    const {getProducts, billingDate, downgradeLoading, upgradeLoading, addInvoiceItemsLoading} = useMarket();
    const upgradeItem = useUpgradeItem();
    const downgradeItem = useDowngradeItem();
    const {openDialog,
        setOpenDialog,
        endTrialContent,
        selectedPriceId,
        upgradeDialogContent} = useDialog();

    const [endTrialNow, endTrialNowInProcess] = useHttpsCallable(
        firebase_functions,
        'endTrialNow'
    );

    const dialogs: any = [
        {
            name: 'upgrade',
            title: 'Add a Package',
            confirm: upgradeItem,
            processing: upgradeLoading || addInvoiceItemsLoading,
            content: upgradeDialogContent,
        },
        {
            name: 'downgrade',
            title: 'Downgrade a Package',
            confirm: downgradeItem,
            processing: downgradeLoading,
            content: `Are you sure you would like to downgrade this package? You will see these changes reflected on the next billing cycle. This can be reversed before ${billingDate}`
        },
        {
            name: 'endTrial',
            title: 'Unlock package benefits',
            confirm: async () => {
                await endTrialNow({priceId: selectedPriceId})
                void getProducts();
                navigate('/processing-order', {replace: true});
            },
            processing: endTrialNowInProcess,
            content: endTrialContent,
        },
        {
            name: 'event',
            title: 'Strategy Suite Required',
            confirm: async () => {
                setOpenDialog('')
                navigate('/dashboard/marketplace?display=Strategy%20Suite')
            },
            content: 'Strategy Suite is required for this product, and must be purchased before this Strategy Suite Bootcamp is available. If you already own Strategy Suite and are seeing this, please refresh your page. If this issues persists after refreshing your page contact support at support@greenchart.com.',
            confirmText: 'Take me there!',
        }
    ]

    return (dialogs.map((dialog: any) => {
        const confirmText = dialog.confirmText;
        if(openDialog) {
            return <MarketplaceDialog
                    isOpen={openDialog === dialog.name}
                    onClose={() => setOpenDialog('')}
                    cancelFunction={() => setOpenDialog('')}
                    confirmFunction={dialog.confirm}
                    title={dialog.title}
                    processing={dialog.processing}
                    content={dialog.content}
                    confirmText={confirmText}
            />
        }
        return null;
    }));
}