import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../../config/firebaseConfig";
import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {convertToDate} from "../../../landing/helpers/convertUnixDate";
import ProgressCircle from "../../../common/components/ProgressCircle";

export default function InvoicesDetails() {
    const [getAllPaidInvoices, loading, error] = useHttpsCallable(
        firebase_functions,
        'getAllPaidInvoices'
    );
    const [invoices, setInvoices] = useState<any[]>([]);

    useEffect(( ) => {
        getPaidInvoices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getPaidInvoices() {
        const paidInvoices = await getAllPaidInvoices();
        if (paidInvoices && paidInvoices.data) {
            // @ts-ignore
            setInvoices(paidInvoices?.data?.invoices?.data);
        }
    }

    function displayInvoices() {
        if (!loading && !error && invoices && invoices.length) {
            return (<Box>
                {invoices.filter((invoice: any) => invoice?.amount_paid).map((invoice: any, index: number) => {
                    return <Box sx={index % 2 === 1 ? {background: '#eee'} : {}}>{displayInvoice(invoice)}</Box>;
                })}
            </Box>)
        }
    }

    function displayInvoice(invoice: any) {
        return (<>
            <Grid container direction='row' alignItems={"center"} gap={1} minHeight={36} sx={{display: {xs: "none", sm: "flex"}}}>
                <Typography>
                    {`${convertToDate(invoice.created)}`}
                </Typography>
                <Typography>
                    {stateReasonForInvoice(invoice)}
                    {renderNamesToDisplay(invoice)}
                </Typography>
                <Typography>
                    {formatPrice(invoice.total)}
                </Typography>
                <Button>
                    <a href={invoice.invoice_pdf}>
                        download invoice
                    </a>
                </Button>
            </Grid>
            <a href={invoice.invoice_pdf}>
                <Grid container direction='row' alignItems={"center"} gap={1} minHeight={36} sx={{display: {xs: "flex", sm: "none"}}}>
                        <Typography>
                            {`${convertToDate(invoice.created)}`}
                        </Typography>
                        <Typography>
                            {formatPrice(invoice.total)}
                        </Typography>
                        <Typography>
                            {stateReasonForInvoice(invoice)}
                            {renderNamesToDisplay(invoice)}
                        </Typography>
                </Grid>
            </a>

        </>)
    }

    function stateReasonForInvoice(invoice: any) {
        if (invoice.lines.data.length > 1) {
            const hasInvoiceItem = invoice.lines.data.some((item: any) => item.type === 'invoiceitem');

            if (hasInvoiceItem) {
                return 'Purchased ';
            }

            return 'Upgraded to ';
        } else {
            return 'Purchased ';
        }
    }

    function renderNamesToDisplay(invoice: any): string {
        const productNames: string[] = [];

        // Assuming each item in data has unique product id
        invoice.lines.data.forEach((item: any) => {
            if (item.price && item.price.nickname && !productNames.includes(item.price.nickname)) {
                productNames.push(`${item.productDetails.name} ${item.price.type !== 'one_time' ? `: ${item.price.nickname}` : ''}`);
            }
        });

        // Join the product names with '&' separator
        return productNames.join(' & ');
    }

    function formatPrice(price: number) {
        price = price / 100;
        return '$' + price.toFixed(2);
    }

    if (loading) {
        return (<ProgressCircle/>);
    }

    if (error) {
        return <>There was an error</>
    }

    if (!loading && !error && invoices) {
        return (<>{displayInvoices()}</>);
    }

    return <>{displayInvoices()}</>
}
