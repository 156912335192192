import React from 'react';
import {useSearchParams} from 'react-router-dom';
import { Typography } from "@mui/material";

interface RedirectExplanationProps {
    px: number;
}

const RedirectExplanation: React.FC<RedirectExplanationProps> = ({ px }) => {
    const [searchParams] = useSearchParams();
    const directToUrl = searchParams.get('directTo');

    const textStyle = {
        color: 'black',
        fontSize: '18px',
        textAlign: 'center',
    }

    if (!directToUrl) return null;

    return (
        <Typography px={px} sx={textStyle}>
            You are being directed to {directToUrl}
            <br/>
            Please sign in to continue.
        </Typography>
    );
};

export default RedirectExplanation;
