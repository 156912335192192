import React from "react";
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from "react-router-dom";
import GroupIcon from '@mui/icons-material/Group';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import InsightsIcon from '@mui/icons-material/Insights';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import useUserDetails from "../../common/hooks/useUserDetails";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function MiniDrawer() {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const {userDetails} = useUserDetails();

    const goToPage = (setting: string) => {
        navigate(`${setting.toLowerCase()}`)
    }

    const drawerItems = [
        {
            text: 'Accounts',
            action: () => {goToPage('/admin/accounts')},
            icon: <GroupIcon />,
        },
        {
            text: 'Promos',
            action: () => {goToPage('/admin/promo')},
            icon: <LocalOfferIcon />,
        },
        {
            text: 'Analytics',
            action: () => {goToPage('/admin/analytics')},
            icon: <InsightsIcon />,
        },
        {
            text: 'Data Download',
            action: () => {goToPage('/admin/data')},
            icon: <CandlestickChartIcon />,
        },
        {
            text: 'Subscription Summary Report',
            action: () => {goToPage('/admin/summary')},
            icon: <SummarizeIcon />,
        },
        {
          text: 'Gift Subscription',
          action: () => {goToPage('/admin/gift')},
            icon: <CardGiftcardIcon />,
        },
    ];

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    function AdminProfilePanel() {
        return (
            <>
                <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <Typography fontSize={12}>{userDetails?.email}</Typography>
                    <Typography fontSize={12}>{userDetails?.gcid}</Typography>
                </Box>
            </>
        )
    }

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader sx={{p: 0}}>
                {open ? <AdminProfilePanel/> : ''}
                <IconButton onClick={handleDrawerOpen}>
                    {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
                {drawerItems.map((item) => (
                    <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={() => item.action && item.action()}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    )
}
