import {
    Query,
    getCountFromServer,
} from 'firebase/firestore';
import {useState} from "react";

export function useCollectionCount(query: Query) {
    const [count, setCount] = useState(0);

    getCountFromServer(query).then(snapshot => setCount(snapshot.data().count))
    return count
}
