import Chart from "react-apexcharts";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function TradesSymbolPieAnalytics(props: any) {
    const {tradeHistory, title} = props

    const [symbolNames, setSymbolNames] = useState<any>([])
    const [symbolCount, setSymbolCount] = useState<any>([])

    useEffect(() => {

        if (!tradeHistory) {
            setSymbolNames([])
            setSymbolCount([])
            return
        }

        const symbols: {[symbol: string]: number} = {}

        tradeHistory.forEach((trade: any) => {
            if (symbols[trade.symbol]) {
                symbols[trade.symbol]++
            } else {
                symbols[trade.symbol] = 1
            }
        })


        setSymbolNames(Object.keys(symbols))
        setSymbolCount(Object.values(symbols))

    }, [tradeHistory])

    const chartSettings = {

        series: symbolCount,
        options: {
            labels: symbolNames,
            chart: {
                height: 350,
            },
        },
    };

    function renderChart() {
        if (!symbolCount || !symbolCount.length) {
            return;
        }

        return (
            <Box sx={{width: "fit-content"}}>
                <Typography sx={{mr: 2, ml: 2}} variant={"h6"}>{title}</Typography>
                <Chart
                    options={chartSettings.options}
                    series={chartSettings.series}
                    type="pie"
                    height={350}
                />
            </Box>
        )

    }


    return (
        <>
            {renderChart()}
        </>
    );

}
