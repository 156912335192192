import React, {useEffect, useState} from 'react';
import {TextField, Button, CircularProgress, InputAdornment, Box, Tooltip} from '@mui/material';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import {useMarket} from "../../../../common/contexts/MarketContext";
import {firebase_functions} from "../../../../config/firebaseConfig";
import '../../../../App.css'
import useRefreshMarketplaceItems from "../../../../common/hooks/useRefreshMarketplaceItems";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

interface CouponInputProps {
    onValidCoupon?: (coupon: string) => void;
}

const CouponInput: React.FC<CouponInputProps> = ({ onValidCoupon }) => {
    const [coupon, setCoupon] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { state, dispatch } = useMarket();
    const [validateCoupon] = useHttpsCallable(firebase_functions, 'validateCoupon');

    const refreshMarketplaceItems = useRefreshMarketplaceItems();

    useEffect(() => {
        if (state.coupon) {
            setCoupon(state.coupon.code);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setLoading(true);
        setMessage('');

        try {
            const result = await validateCoupon({ coupon });
            const data = result?.data as any;

            if (data && data.validated) {
                dispatch({ type: 'SET_COUPON', payload: data.coupon });

                if (onValidCoupon) {
                    onValidCoupon(coupon);
                }
                setMessage(`Coupon applied! ${data.coupon.metadata.description || ''}`);
                if (data.coupon.duration) refreshMarketplaceItems();
            } else {
                setMessage(data.message || 'Coupon is not valid.');
            }
        } catch (error) {
            setMessage('Error validating the coupon.');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box pt={2} className="coupon" sx={{maxWidth: '240px'}}>
            <form onSubmit={handleSubmit}>
                <Box display="flex" justifyContent="center" sx={{
                    width: '100%',
                }}>
                    <TextField
                        label="Coupon"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        helperText={message}
                        error={(message.length !== 0) && !message.includes('Coupon applied!')}
                        FormHelperTextProps={{
                            style: {
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                loading && <InputAdornment position="end">
                                    <CircularProgress size={20} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ borderRadius: '4px 0 0 4px' }}
                    />
                    <Tooltip title={"Apply Coupon"}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ textTransform: 'none', height: '55px', borderRadius: '0 4px 4px 0' }}
                        >
                            <LocalOfferIcon/>
                        </Button>
                    </Tooltip>
                </Box>
            </form>
        </Box>
    );
};

export default CouponInput;
