import React, { useState } from "react";
import EmailVerification from "./EmailVerification";
import {CircularProgress, Stack} from "@mui/material";
import SignUpForm from "../forms/SignUpForm";
import LoginForm from "../forms/LoginForm";
import ForgottenPasswordForm from "../forms/ForgottenPasswordForm";
import SetPasswordForm from "../forms/SetPasswordForm";

interface ModalContentProps {
    closeModal?: () => void;
    onSwitchToLoginCallback?: () => void;
    onSwitchToSignUpCallback?: () => void;
    onSwitchToForgotPasswordCallback?: () => void;
    state?: 'signUp' | 'login' | 'emailVerification' | 'forgotPassword' | 'setPassword';
}

const ModalContent: React.FC<ModalContentProps> = ({onSwitchToLoginCallback, onSwitchToSignUpCallback, onSwitchToForgotPasswordCallback, state, closeModal}) => {

    const [contentState, setContentState] = useState<'signUp' | 'login' | 'emailVerification' | 'forgotPassword' | 'setPassword'>(state || 'signUp');
    const [loading, setLoading] = useState<boolean>(false);

    const [signUpError, setSignUpError] = useState<any>(null);

    const switchToLogin = () => {
        setContentState('login');
        if (onSwitchToLoginCallback) onSwitchToLoginCallback();
    }
    const switchToSignUp = () => {
        setContentState('signUp');
        if (onSwitchToSignUpCallback) onSwitchToSignUpCallback();
    }
    const switchToEmailVerification = () => {
        setContentState('emailVerification');
    }
    const switchToForgotPassword = () => {
        setContentState('forgotPassword');
        if (onSwitchToForgotPasswordCallback) onSwitchToForgotPasswordCallback();
    }

    if (loading) {
        return (
            <Stack
                spacing={3}
                sx={{
                    backgroundColor: "#fffff",
                    minWidth: "360px",
                    minHeight: "400px",
                    justifyContent: "center",
                    alignContent: "center",
                }}
            >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress color={"success"} size={"5rem"} />
                </div>
            </Stack>
        );
    }

    return (
        <Stack
            spacing={3}
            sx={{
                backgroundColor: "#fffff",
                minWidth: "360px",
                minHeight: "200px",
                justifyContent: "center",
            }}
        >
            {contentState === 'signUp' && (
                <SignUpForm
                    onSignUpSuccess={switchToEmailVerification}
                    onSwitchToLogin={switchToLogin}
                    setLoading={setLoading}
                    setErrorMessage={setSignUpError}
                    errorMessage={signUpError}
                />
            )}
            {contentState === 'login' && (
                <LoginForm
                    onLoginSuccess={() => {
                        if (closeModal) closeModal()
                    }}
                    onSwitchToSignUp={switchToSignUp}
                    onSwitchToForgotPassword={switchToForgotPassword}
                />
            )}
            {contentState === 'emailVerification' && (
                <EmailVerification
                    onSwitchToLogin={switchToLogin}
                />
            )}
            {contentState === 'forgotPassword' && (
                <ForgottenPasswordForm
                    onSwitchToLogin={switchToLogin}
                />
            )}
            {contentState === 'setPassword' && (
                <SetPasswordForm/>
            )}
        </Stack>
    );
};

export default ModalContent;