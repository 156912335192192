import {Navigate} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import useUserDetails from "../hooks/useUserDetails";
import {GeneralContext} from "../contexts/GeneralContext";

export default function AdminRouteGuard({ children }: any) {
    const {user, loadingUser: loading, userDetails: usermeta, loadingUserDetails: loadingUserMeta} = useUserDetails();
    const {checkAccountStatuses} = useContext(GeneralContext);


    useEffect(() => {
        if (user && !loading && usermeta) {
            checkAccountStatuses(usermeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usermeta])

    if (loading || loadingUserMeta) {
        return <></>
    }
    if (!user) {
        return <Navigate to="/login" replace />;
    }
    if (!usermeta?.isAdmin) {
        return <Navigate to="/dashboard" replace />;
    }

    return children;
};
