import {createContext, useState} from "react";

interface traderAccountContextInterface {
    traderAccount: any | null,
    setTraderAccount: (account: any | null) => void,
}

export const TraderAccountContext = createContext<traderAccountContextInterface>({} as traderAccountContextInterface);

export function TraderAccountHelper({children}: any) {
    const [traderAccount, setTraderAccount] = useState<any | null>(null);

    return (
        <TraderAccountContext.Provider
            value={{
                traderAccount: traderAccount,
                setTraderAccount: setTraderAccount,
            }}>
            {children}
        </TraderAccountContext.Provider>
    )
}