import React, {useState} from 'react';
import {Stack, Button, Box, useTheme, useMediaQuery, Typography} from "@mui/material";
import {useSendPasswordResetEmail} from "react-firebase-hooks/auth";
import {firebase_auth} from "../../../config/firebaseConfig";
import {useNavigate} from "react-router-dom";
import '../../../App.css'
import EnterEmail from "../../components/forgotPassword/EnterEmail";

interface ForgottenPasswordProps {
    onSwitchToLogin?: () => void;
}

const ForgottenPasswordForm: React.FC<ForgottenPasswordProps> = ({ onSwitchToLogin }) => {
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState('');
    const [sendPasswordResetEmail] = useSendPasswordResetEmail(firebase_auth);
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const px = isMobile ? 2 : 12

    const navigateToLogin = () => {
        navigate("/login");
    };

    return (<>
        {!emailSent && <>
            <Box
                style={{textAlign: "center"}}
                px={px}
            >
                <Stack

                    spacing={3}
                    sx={{
                        backgroundColor: '#fffff'
                    }}
                >
                    <EnterEmail
                        email={email}
                        sendPasswordResetEmail={sendPasswordResetEmail}
                        setEmail={setEmail}
                        setEmailSent={setEmailSent}
                    />
                </Stack>
            </Box>
        </>}
        {emailSent && <>
            <Box
                maxWidth="sm"
                style={{textAlign: "center"}}
                px={px}
            >
                <Stack
                    spacing={3}
                    sx={{
                        backgroundColor: '#fffff'
                    }}
                >
                    <Box></Box>
                    <Box>
                        <Typography sx={{alignSelf: 'center', fontSize: '22px', fontWeight: 500}}>Check Your Inbox</Typography>
                        <Typography style={{ color: "gray", fontWeight: 400, fontSize: '18px' }}>
                            Click the link in the email to reset your password
                        </Typography>
                    </Box>

                    <Button
                        onClick={onSwitchToLogin || navigateToLogin}
                        sx={{color: 'white'}}
                        variant="contained"
                    >Go to login</Button>
                </Stack>
            </Box>
        </>}
        <Stack direction="row" sx={{alignSelf: 'center'}} pb={2}>
            <Button onClick={onSwitchToLogin}>
                Back
            </Button>
        </Stack>
    </>);
}

export default ForgottenPasswordForm;
