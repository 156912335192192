import {Box, Button, TextField, Typography} from "@mui/material";
import React from "react";

// Define prop types
type EnterEmailProps = {
    setEmail: (email: string) => void;
    sendPasswordResetEmail: (email: string) => void;
    setEmailSent: (sent: boolean) => void;
    email: string;
};

const EnterEmail: React.FC<EnterEmailProps> = ({
                                                   setEmail,
                                                   sendPasswordResetEmail,
                                                   setEmailSent,
                                                   email,
                                               }) => {
    return (
        <>
            <Box></Box>
            <Box>
                <Typography sx={{alignSelf: 'center', fontSize: '22px', fontWeight: 500}}>Forgotten password</Typography>
                <Typography style={{ color: "gray", fontWeight: 400, fontSize: '18px' }}>
                    Please enter your email address and we'll send you a link to reset it.
                </Typography>
            </Box>

            <Box>
                <TextField
                    id="emailTextField"
                    label="Email"
                    variant="outlined"
                    sx={{width: '100%'}}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            sendPasswordResetEmail(email);
                            setEmailSent(true);
                        }
                    }}
                />
            </Box>

            <Box>
                <Button
                    sx={{width: '100%'}}
                    variant="contained"
                    onClick={() => {
                        sendPasswordResetEmail(email);
                        setEmailSent(true);
                    }}
                >
                    Send email
                </Button>
            </Box>
        </>
    );
};

export default EnterEmail;
