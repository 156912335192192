import useFindProductId from "./useFindProductId";
import useDetermineIfUpgradeOrDowngrade from "./useDetermineIfUpgradeOrDowngrade";
import {checkPriceIsTrial} from "../../../../pages/app/marketplace/helpers/checkItemIsTrial";
import {checkPriceIsAnnual} from "../../../../pages/app/marketplace/helpers/checkItemIsAnnual";
import {AlertProductMap} from "../../../../../common/helpers/AlertProductMap";
import useUserDetails from "../../../../../common/hooks/useUserDetails";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection} from "firebase/firestore";
import {firebase_firestore} from "../../../../../config/firebaseConfig";

export default function useDetermineButtonText() {
    const determinedUpgradeOrDowngrade = useDetermineIfUpgradeOrDowngrade();
    const {userDetails, user} = useUserDetails();
    const [subscriptionsObject] = useCollectionData(collection(firebase_firestore, `usermeta/${user?.uid}/subscriptions`));
    let activeProduct: { metadata: { courseId: any; }; };
    const findProductId = useFindProductId();

    return (price?: {
        metadata: any;
        priceId: any, freeTrialDuration: any}) => {
        if (!userDetails) return 'purchase';
        if(!price) return 'purchase';
        const { priceId, freeTrialDuration } = price;
        let isProductPriceActive;

        if (subscriptionsObject) {
            // @ts-ignore
            const tradingHouse = subscriptionsObject.find((th: { tradingHouse: string; }) => th.tradingHouse === 'greenchart');
            if (tradingHouse) {
                // is this the price activate OR is the free trial active
                isProductPriceActive = !!tradingHouse.products.find((product: any) => priceId === product.priceId) || !!tradingHouse.products.find((product: any) => priceId === product.metadata?.price);
                // are any prices active for this product
                const productId = findProductId(priceId);
                activeProduct = tradingHouse.products.find((product: any) => productId === product.prodId);

                // Check if courseId exists and if it's in the user's account
                if (!isProductPriceActive && activeProduct && userDetails && userDetails.products && userDetails.products.courses) {
                    isProductPriceActive = userDetails.products.courses.some((course: any) => course === activeProduct.metadata.courseId);
                }
            }
        }

        if (!isProductPriceActive) {
            let label = freeTrialDuration ? 'Get Free Trial' : 'purchase';
            // Placeholder product, like on GreenChart direct Starter price
            if (price.metadata.defaultCurrent && !activeProduct) label = 'current plan';
            // @ts-ignore
            if (activeProduct) {
                switch (determinedUpgradeOrDowngrade(priceId)) {
                    case 1:
                        label = 'Upgrade';
                        break;
                    case -1:
                        label = 'Downgrade';
                        break;
                    case -2:
                        break;
                    case 0:
                        break;
                }
            }
            return label;
        } else {
            const itemIsTrial = () => {
                return checkPriceIsTrial(price);
            }
            const annual = () => {
                return checkPriceIsAnnual(price);
            }

            // @ts-ignore
            if (!annual() && itemIsTrial() && AlertProductMap[process.env.REACT_APP_ENVIRONMENT] === price.productId) {
                return 'Need More Credits?';
            }
            return 'current plan';
        }
    }
}