import React, { useContext, useEffect } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { collection } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { SnackbarHelperContext } from "../../../../common/contexts/SnackbarHelperContext";

import MarketPlaceItem from "../MartketPlaceItem";
import ProductFooter from "../../../pages/app/marketplace/ProductFooter";
import MarketPlaceItemPair from "../MarketPlaceItemPair";
import Dialog from "../../../../common/dialogs/Dialog";
import NoCardDialog from "../../../../common/dialogs/NoCardDialog";
import ProductDescription from "./ProductDescription";

import { firebase_firestore } from "../../../../config/firebaseConfig";
import useUserDetails from "../../../../common/hooks/useUserDetails";
import { useMarket } from "../../../../common/contexts/MarketContext";

import { isCurrentProduct } from "../../../pages/app/marketplace/helpers/isCurrentProduct";
import { useDialog } from "../../../../common/contexts/DialogContext";

// Utils Hooks
import useDetermineIfUpgradeOrDowngrade from "./utils/useDetermineIfUpgradeOrDowngrade";
import useGetItemPrice from "./utils/useGetItemPrice";
import useAddItemToCart from "./utils/useAddItemToCart";
import useDetermineButtonText from "./utils/useDetermineButtonText";
import { useSearchParams } from "react-router-dom";
import findSelectedProduct from "../../../pages/app/marketplace/helpers/findSelectedProduct";

export default function ProductsDisplay(props: { prices: [], pricePairs: { monthly: any, annual?: any }[] }) {
    const { user } = useUserDetails();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // Removed isLargeScreen
    const { setSnackbarSettings } = useContext(SnackbarHelperContext);
    const {
        state: { productDetails, annualSelected },
        dispatch,
    } = useMarket();
    const [subscriptionsObject] = useCollectionData(collection(firebase_firestore, `usermeta/${user?.uid}/subscriptions`));

    const {
        setOpenDialog,
        setSelectedPriceId,
        setEndTrialContent
    } = useDialog();
    const setAnnualSelected = (value: boolean) => dispatch({ type: 'SET_ANNUAL_SELECTED', payload: value });

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!props.prices || !props.pricePairs) {
            setSnackbarSettings({ severity: 'error', message: 'Item not found!' });
        }
    }, [props.prices, props.pricePairs, subscriptionsObject, setSnackbarSettings]);

    const addItemToCart = useAddItemToCart();
    const determineIfUpgradeOrDowngrade = useDetermineIfUpgradeOrDowngrade();
    const determineButtonText = useDetermineButtonText();
    const getItemPrice = useGetItemPrice();

    function openEndTrialDialog(price: (() => string) | string, priceId: string) {
        setEndTrialContent(`This will end your free trial, charge you ${price} immediately, and unlock the ability to purchase add-ons and refill your credits and alerts.`);
        setSelectedPriceId(priceId);
        setOpenDialog('endTrial');
    }

    if (props.prices && props.pricePairs.length && subscriptionsObject) {
        const footer: any = () => {
            const currentlySelectedProduct: string = searchParams.get('display') || '';
            const selectedProduct = findSelectedProduct(productDetails, currentlySelectedProduct);

            const product = productDetails.data.find((product: any) => {
                return selectedProduct && product.id === selectedProduct.id;
            });

            return (
                <Box p={1}>
                    <Typography px={2}>
                        <ProductFooter product={product} />
                    </Typography>
                </Box>
            )
        }

        return (
            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{
                        background: 'white',
                        mx: 'auto',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        paddingBottom: '0.5rem',
                        paddingTop: '0.1rem',
                        marginLeft: isMobile ? '0rem' : '2rem',
                        marginRight: isMobile ? '0rem' : '2rem',
                        marginBottom: '1rem',
                        borderRadius: '2px',
                        border: 1,
                        borderColor: 'divider',
                        width: isMobile ? '94%' : '92%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    <ProductDescription />
                </Box>
                <Dialog />
                <NoCardDialog />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'stretch',
                        gap: 2,
                        flexWrap: 'wrap',
                        paddingLeft: '1rem',
                        paddingRight: 0,
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                    key="price-pairs-container"
                >
                    {props.pricePairs
                        .sort((a: any, b: any) => annualSelected && a.annual && b.annual
                            ? a.annual.price - b.annual.price
                            : a.monthly.price - b.monthly.price)
                        .map(pricePair => {
                            const upgradeOrDowngradeAnnual = pricePair.annual
                                ? determineIfUpgradeOrDowngrade(pricePair.annual.priceId)
                                : -2;
                            const upgradeOrDowngradeMonthly = determineIfUpgradeOrDowngrade(pricePair.monthly.priceId);
                            const shouldDisableButtonAnnual = upgradeOrDowngradeAnnual === -2;
                            const shouldDisableButtonMonthly = upgradeOrDowngradeMonthly === -2;
                            const monthlyButtonText = determineButtonText(pricePair.monthly);
                            const annualButtonText = pricePair.annual ? determineButtonText(pricePair.annual) : '';
                            const price = getItemPrice(pricePair.monthly);

                            const buttonAction = isCurrentProduct(monthlyButtonText)
                                ? () => {
                                    const noAction = !pricePair.monthly.freeTrialDuration && !pricePair.monthly.metadata.trial;

                                    if (noAction) {
                                        return;
                                    }

                                    addItemToCart(pricePair.monthly);
                                    openEndTrialDialog(price || 'There was an issue, if this persists please contact support.', pricePair.monthly.priceId);
                                }
                                : () => {
                                    addItemToCart(pricePair.monthly);
                                }

                            const buttonActionAnnual = () => {
                                if (monthlyButtonText !== 'current plan') {
                                    addItemToCart(pricePair.annual);
                                }
                            }

                            const monthlyMarketplaceItem = (
                                <MarketPlaceItem
                                    product={pricePair.monthly}
                                    buttonText={monthlyButtonText}
                                    disableButton={shouldDisableButtonMonthly}
                                    setAnnual={setAnnualSelected}
                                    isAnnual={annualSelected}
                                    userSubscription={subscriptionsObject}
                                    buttonClick={buttonAction}
                                />
                            );

                            const annualMarketplaceItem = pricePair.annual ? (
                                <MarketPlaceItem
                                    product={pricePair.annual}
                                    buttonText={annualButtonText}
                                    disableButton={shouldDisableButtonAnnual}
                                    setAnnual={setAnnualSelected}
                                    isAnnual={annualSelected}
                                    userSubscription={subscriptionsObject}
                                    buttonClick={buttonActionAnnual}
                                />
                            ) : monthlyMarketplaceItem;

                            return (
                                <Box
                                    key={pricePair.monthly.priceId || pricePair.annual?.priceId || `${pricePair.monthly.priceId}-${pricePair.annual?.priceId}`}
                                    sx={{
                                        flex: '1 1 250px',
                                        maxWidth: '350px',
                                        boxSizing: 'border-box',
                                        height: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box sx={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}>
                                        <MarketPlaceItemPair
                                            key={pricePair.monthly.priceId || pricePair.annual?.priceId || `${pricePair.monthly.priceId}-${pricePair.annual?.priceId}`}
                                            showAnnual={annualSelected}
                                            annual={pricePair.annual ? annualMarketplaceItem : monthlyMarketplaceItem}
                                            monthly={monthlyMarketplaceItem}
                                        />
                                    </Box>
                                </Box>
                            );
                        })}
                </Box>
                {footer()}
            </Box>
        );
    }
    return null;
}
