import { useState, useEffect } from "react";
import { Link } from "@mui/material";
import useBuildPriceIds from "./useBuildPriceIds";
import useDirectToCreditCardPage from "./useDirectToCreditCardPage";
import { useMarket } from "../../../../../common/contexts/MarketContext";
import { useSearchParams } from "react-router-dom";
import createPriceArray from "../../../../pages/app/marketplace/helpers/createPriceArray";

export default function useDetermineUpgradeText() {
    const priceIds = useBuildPriceIds();
    const { state, billingDate } = useMarket();
    const [searchParams] = useSearchParams();
    const prices = createPriceArray(state, searchParams);
    const directToCreditCard = useDirectToCreditCardPage();
    const [freeTrial, setFreeTrial] = useState<null | string>(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (priceIds.length > 0) {
                // @ts-ignore
                const trial = prices.find((price: any) => price.priceId === priceIds[0].priceId)?.freeTrialDuration;
                setFreeTrial(trial);
            }
        }, 0);

        return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }, [priceIds, prices]);

    return (price: undefined | string) => {
        if (freeTrial) {
            return (
                <>
                    You have a {freeTrial} on your account. After your trial period ends, you will be charged {price}.
                    Go {<Link onClick={directToCreditCard} style={{ textDecoration: 'underline', cursor: 'pointer' }}>here</Link>} to view/update your credit card on file.
                </>
            );
        } else {
            return (
                <>
                    Adding this will charge the current card on file {price}, which has been prorated to the billing
                    date of {billingDate}. To view or change your card on file click {<Link onClick={directToCreditCard} style={{ textDecoration: 'underline', cursor: 'pointer' }}>here</Link>}.
                </>
            );
        }
    };
}
