import TraderAccountTable from "../components/traderAccounts/TraderAccountTable";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import React, {useContext, useState} from "react";
import TraderMoreInfo from "../components/traderAccounts/TraderMoreInfo";
import Box from "@mui/material/Box";
import {TraderAccountContext} from "../contexts/TraderAccountContext";

export default function TraderAccounts() {

    const [search, setSearch] = useState("")
    const [gtfSelected] = useState(false)
    const [greenchartSelected] = useState(false)
    const [searchType, setSearchType] = useState("email")

    const [trader, setTrader] = useState(null)
    const  { setTraderAccount } = useContext(TraderAccountContext);

    function escapeRegex(s: string) {
        return s
    }

    return (
        <>
            <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
                <FormControl>
                    <FormLabel id="search-type-radio-buttons-group-label">Search Type</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="search-type-radio-buttons-group-label"
                        name="search-type-radio-buttons-group"
                        defaultValue="email"
                    >
                        <FormControlLabel value="email" control={<Radio />} label="Email" onChange={(val: any) => setSearchType(val.target.value)}/>
                        <FormControlLabel value="gcid" control={<Radio />} label="GreenChart ID" onChange={(val: any) => setSearchType(val.target.value)}/>
                        <FormControlLabel value="firstName" control={<Radio />} label="First Name" onChange={(val: any) => setSearchType(val.target.value)}/>
                        <FormControlLabel value="lastName" control={<Radio />} label="Last Name" onChange={(val: any) => setSearchType(val.target.value)}/>
                    </RadioGroup>
                </FormControl>

                <TextField
                    onChange={(val: any) => setSearch(escapeRegex(val.target.value))}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    label={`Search traders by ${searchType}`}
                    id="accountSearch"
                />
                {/*<FormControl>*/}
                {/*    <FormLabel id="tradingHouse-radio-buttons-group-label">Trading House</FormLabel>*/}
                {/*    <FormControlLabel control={<Checkbox />} label="GreenChart" onChange={(val: any) => setGreenChartSelected(val.target.checked)}/>*/}
                {/*    <FormControlLabel control={<Checkbox />} label="GTF" onChange={(val: any) => setGTFSelected(val.target.checked)}/>*/}
                {/*</FormControl>*/}

            </Box>


            <Box sx={{display: "flex"}}>

                <TraderAccountTable searchString={search}
                                    searchField={searchType}
                                    gtfSearchSelected={gtfSelected}
                                    greenchartSearchSelected={greenchartSelected}
                                    trader={trader}
                                    traderUsermetaSelected={(val: any) => {
                                        setTraderAccount(val);
                                        setTrader(val);
                                    }}
                                    style={{flexGrow: 1}}
                />

                {trader ? <TraderMoreInfo trader={trader} style={{padding: "16px", flexBasis: "50%", background: "rgba(218, 165, 32, .2)"}}/> : <></>}
            </Box>



        </>
    )
}
