import {Box, Button, Card} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {useEffect, useState} from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function AddPhaseForm(props: any) {
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [phaseItems, setPhaseItems] = useState<any>(null);
    const {deleteItem, items, saveModifications, setNewStartDate, setNewEndDate, setSelectedPrice, products} = props;

    useEffect(() => {
        const {start, end} = props;
        setStartDate(start * 1000);
        setEndDate(end * 1000);
        setPhaseItems([...items]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    function renderDeleteButton(item: any, index: number) {
        if (items.length <= 1) return;

        return (<Button onClick={() => {
            phaseItems.splice(index, 1);
            setPhaseItems([...phaseItems]); // local reflection
            deleteItem(item.price.id);
        }}><DeleteIcon/></Button>)
    }

    function showPhaseItems() {
        if (!phaseItems) {
            return;
        }

        return (<>
            {phaseItems.map((item: any, index: number) => {
                const name = `${item.price.product.name}:${item.price.nickname}`

                return (<Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}>
                    <Box sx={{
                        verticalAlign: 'middle',
                        margin: 'auto',
                    }}>{name}</Box>
                    <Button onClick={() => {
                        setSelectedPrice(item.price);
                    }}><EditIcon/></Button>
                    {renderDeleteButton(item, index)}
                </Box>);
            })}
        </>);
    }

    function showAddingItemButton() {

        const productsSubset: any = {};
        for (const property in products) {
            const isInItems = items.find((item: any) => item.price.product.id === property)
            if (!isInItems) productsSubset[property] = products[property];
        }

        if (props.addingItem || !Object.keys(productsSubset).length) {
            return;
        }

        return (
            <Button onClick={addItem}>Add Item</Button>
        );

        function addItem() {
            props.setAddingItem(true);
        }
    }

    return (<>
        <Card>
            <Box p={4} sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box mb={2}>
                        <DesktopDatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(value) => {
                                setNewStartDate(value.$d.getTime() / 1000);
                            }}
                        />
                    </Box>
                    <Box mb={2}>
                        <DesktopDatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(value) => {
                                setNewEndDate(value.$d.getTime() / 1000)
                            }}
                        />
                    </Box>
                </LocalizationProvider>
                {showPhaseItems()}
                {showAddingItemButton()}
            </Box>
            <Box
                pr={4}
            >
                <Button
                    sx={{
                        float: 'right',
                    }}
                    onClick={saveModifications}>
                    save
                </Button>
            </Box>
        </Card>
    </>);
}
