import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ListItemIcon} from "@mui/material";
import SubSectionIcons from "./SubSectionIcons";
import React from "react";


function SideNavigationMenuOption({product}: any) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const displayProductName = product.metadata.productType.replace(/([A-Z])/g, ' $1')
        .trim()
        .split(' ')
        .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');

    let isSelected = product.metadata.productType === searchParams.get('display');

    function displayProductPrices() {
        navigate(`/dashboard/marketplace?display=${product.metadata.productType}`);
    }

    return <>
        <ListItemButton
            sx={{ pl: 4 }}
            onClick={displayProductPrices}
            selected={isSelected}
        >
            <ListItemIcon>
                <SubSectionIcons productName={displayProductName}/>
            </ListItemIcon>
            <ListItemText primary={displayProductName} secondary={product.metadata.hook} />
        </ListItemButton>
    </>
}

export default SideNavigationMenuOption;
