import React from 'react';
import logo from "../../assets/gc_logo.png"
import {Box} from "@mui/material";
export default function Footer() {
    return (
        <>
            <div style={{
                color: 'white',
                background: 'linear-gradient(90deg, rgba(100,212,0,0.924829306722689) 15%, rgba(0,0,0,1) 40%)',
                position: 'relative',
                bottom: '0px',
                width: '100%',
                height: '5rem',
                padding: '1rem 0rem',
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Box
                    height={'45px'}
                    component={"img"}
                    src={logo}
                    style={{marginLeft: '15px'}}
                />
                <Box style={{marginRight: '1.2rem'}}>
                    <div style={{textAlign: "end"}}>
                        © 2023 Green Chart LLC
                    </div>
                    <div style={{textAlign: 'end', fontSize: '11px'}}>
                        <div>
                            help@greenchart.com
                        </div>
                        <div>
                            1.801.901.3356
                        </div>
                    </div>
                </Box>
            </div>
        </>
    )

}
