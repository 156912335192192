import useUserDetails from "../../../../../common/hooks/useUserDetails";
import {useMarket} from "../../../../../common/contexts/MarketContext";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import useBuildPriceIds from "./useBuildPriceIds";
import {SnackbarHelperContext} from "../../../../../common/contexts/SnackbarHelperContext";

const useDowngradeItem = () => {
    const navigate = useNavigate();
    const {userDetails} = useUserDetails();
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);
    const {getProducts, downgradeSubscription,} = useMarket();
    const priceIds = useBuildPriceIds();
    // const {setOpenDialog} = useDialog();

  return async () => {
        if(!userDetails){
            console.error('User details not available for downgrade.');
            return;
        }
            try {
                const response = await downgradeSubscription({
                    products: priceIds,
                    tradingHouse: 'greenchart'
                });
                // @ts-ignore
                if (response.data?.status === 204) {
                    setSnackbarSettings({
                        severity: 'success',
                        message: 'Downgrade has been scheduled!',
                        autoHideDuration: 6000
                    });
                } else {
                    setSnackbarSettings({
                        severity: 'error',
                        message: 'Downgrade failed. Please try again later.',
                        autoHideDuration: 6000
                    });
                }
            } catch (error) {
                console.error('Failed to downgrade item.', error);
                setSnackbarSettings({
                    severity: 'error',
                    message: 'Downgrade failed. Please try again later.',
                    autoHideDuration: 6000
                });
            } finally {
                // setOpenDialog('');
                await getProducts();
                navigate('/confirmation', {replace: true});
            }
    };
}


export default useDowngradeItem;