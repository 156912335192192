import {useMarket} from '../../common/contexts/MarketContext';

interface Product {
    id: string;
    metadata: {
        requiredProduct: string;
    }
}

function useFindProductDetails() {
    const { state } = useMarket();

    return (productId: string): Product | null => {
        let productDetails = state.productDetails;
        let product = productDetails.data.find((product: Product) => product.id === productId);

        if (product === undefined) {
            return null;
        }

        return product;
    };
}

export default useFindProductDetails;
