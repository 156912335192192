import React, {useContext, useEffect, useState} from 'react';
import Header from "../../../common/components/Header";
import Footer from "../../../common/components/Footer";
import {Container, Paper, Stack, Typography, Box} from "@mui/material";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {firebase_auth, firebase_firestore} from "../../../config/firebaseConfig";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {useAuthState} from "react-firebase-hooks/auth";
import {doc} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import {SnackbarHelperContext} from "../../../common/contexts/SnackbarHelperContext";

interface UserDetails {
    purchaseInProgress: boolean;
}

const ProcessingOrder: React.FC = () => {
    const [user] = useAuthState(firebase_auth);
    const navigate = useNavigate();
    const [dots, setDots] = useState(0);

    // @ts-ignore
    const [userDetails] = useDocumentData<UserDetails>(doc(firebase_firestore, `usermeta/${user?.uid}`));

    const { setSnackbarSettings } = useContext(SnackbarHelperContext);

    useEffect(() => {
        if (userDetails && !userDetails.purchaseInProgress) {
            setSnackbarSettings({severity: 'success', message: "Purchase complete!", autoHideDuration: 6000})
            navigate(`/confirmation`, { replace: true });
        }
    }, [userDetails, navigate, setSnackbarSettings]);

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((currentDots) => currentDots === 3 ? 0 : currentDots + 1);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const getDots = () => {
        let str = '';
        for(let i=0; i<3; i++) {
            str += i < dots ? '.' : '\u00A0'; // \u00A0 is the non-breaking space character
        }
        return str;
    };

    return (
        <>
            <Header/>

            <Container style={{minHeight: '100vh'}}>
                <Paper elevation={8} sx={{textAlign: "center", mt: 4, p: 4}}>
                    <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
                        <Box sx={{fontSize: "140px", color: "green"}}>
                            <HourglassEmptyIcon fontSize={"inherit"}/>
                        </Box>

                        <Typography variant="h3" mt={0} component="div">
                            <span>Your order is being processed</span><span style={{fontFamily: 'monospace', marginLeft: '0.1em'}}>{getDots()}</span>
                        </Typography>

                        <Typography variant="subtitle1" >
                            Please wait, you will be redirected to the confirmation page once the order processing completes.
                        </Typography>
                    </Stack>
                </Paper>
            </Container>

            <Footer/>
        </>
    );
};

export default ProcessingOrder;
