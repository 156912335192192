import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../config/firebaseConfig";
import React, {useEffect, useState} from "react";
import TableContainer from "@mui/material/TableContainer";
import {Box, Paper, TableCell, TableFooter, TablePagination} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";



export default function SubscriptionSummary() {
    const [getAllSubscriptions, loadingSubscriptions] = useHttpsCallable(
        firebase_functions,
        'getAllSubscriptions'
    );

    const [retrieveMoreUpcomingInvoices, loadingMoreData] = useHttpsCallable(
        firebase_functions,
        'getUpcomingSubscriptionInvoices'
    );

    const [subscriptions, setSubscriptions] = useState<any>(null);
    const [rows, setRows] = useState<any[]>([]);
    const [page, setPage] = useState(0);
    const [pagesLoaded, setPagesLoaded] = useState(1);
    const [queryPageMoveForward, setQueryPageMoveForward] = useState(false);
    const rowsPerPage = 10;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        if (!subscriptions) return;

        if (pagesLoaded < newPage) {
            setQueryPageMoveForward(true);
        }

        if (pagesLoaded === newPage) {
            loadNewPage(newPage + 1);
        }

        if(pagesLoaded >= newPage) {
            setPage(newPage);
            return;
        }
    };

    function loadNewPage(newPage: number) {
        retrieveMoreUpcomingInvoices({subscriptions: subscriptions.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage)}).then((res: any) => {
            const rowsCopy = [...rows];

            const newRowsData = res.data.map((subscription: any) => buildCustomerRows(subscription));

            rowsCopy.splice(newPage * rowsPerPage, rowsPerPage, ...newRowsData);
            setRows(rowsCopy);
            setPagesLoaded(pagesLoaded + 1);
        })
    }

    useEffect(() => {
        getAllSubscriptions({initialRequestSize: 20}).then((res: any) => {
            setSubscriptions(res.data);
            const customers = res.data.map((subscription: any) => buildCustomerRows(subscription));

            setRows(customers);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!queryPageMoveForward) return;

        if (page + 1 <= pagesLoaded) {
            setPage(page + 1);
            loadNewPage(page + 2);
            setQueryPageMoveForward(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagesLoaded])

    function buildCustomerRows (subscription: any) {
        // const alertsPackage = subscription.items.data.find((item: any) => item.price.product === productMapper?.alertsPackage);
        let packagesOnAccount = '';
        subscription.items.data.forEach((item: any) => {
            packagesOnAccount += `${item.price.nickname},`;
        })
        if (packagesOnAccount.length) packagesOnAccount.substring(0, packagesOnAccount.length - 1);

        return {
            name: subscription.customer.name || '',
            email: subscription.customer.email,
            package: packagesOnAccount,
            amountDue: subscription.upcoming_invoice ? '$' + (subscription.upcoming_invoice.amount_due/100).toFixed(2) : 'Cancellation Pending',
            end: subscription.current_period_end,
        };
    }

    function renderRows()  {
        return (rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
        ).map((row) => (
            <TableRow
                key={row.email + row.end}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.package}</TableCell>
                <TableCell align="right">{row.amountDue}</TableCell>
                <TableCell align="right">{(new Date(row.end * 1000)).toLocaleDateString()}</TableCell>
            </TableRow>
        ))
    }

    function renderLoadingMoreData() {
        if (!loadingMoreData) return;

        return <>Loading more data...</>;
    }

    if (loadingSubscriptions) {
        return <>Loading subscriptions...</>;
    }

    return (<>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Email</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Subscription Items</TableCell>
                        <TableCell align="right">Amount Due</TableCell>
                        <TableCell align="right">Next Invoice Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderRows()}
                </TableBody>
            </Table>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[rowsPerPage]}
                        colSpan={3}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page',
                            },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                    />
                </TableRow>
            </TableFooter>
    </TableContainer>

        <Box pt={2}>
            {renderLoadingMoreData()}
        </Box>
    </>)
}