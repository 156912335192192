import {useDocument} from "react-firebase-hooks/firestore";
import {doc} from "firebase/firestore";
import {useAuthState} from "react-firebase-hooks/auth";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {firebase_auth, firebase_firestore} from "../../../../config/firebaseConfig";
import {StatusesMapper} from "../../../helpers/statusesMapper";

export default function Welcome() {
    const [user, loading] = useAuthState(firebase_auth);
    const [userAccountData, loadingUserAccountData] = useDocument(doc(firebase_firestore, `usermeta/${user?.uid}`));
    const navigate = useNavigate();

    useEffect(() => {
        const statuses = [];

        if (userAccountData && userAccountData.data()) {
            for (const house in userAccountData?.data()?.tradingHouses) {
                if (userAccountData?.data()?.tradingHouses[house].hasOwnProperty('currentStatus')) {
                    statuses.push(userAccountData?.data()?.tradingHouses[house].currentStatus.status);
                }
            }

            if (statuses.some(status => StatusesMapper.subscriptionStatuses.includes(status))) { // This could be adjusted
                navigate('/dashboard');
            }
        }
    }, [navigate, userAccountData])

    if (loading || loadingUserAccountData) {
        return <>LOADING</>
    }

    return (<>
        <h1>
           Welcome to GreenChart, you will be redirected shortly, your payment is being processed...
        </h1>
    </>)
}