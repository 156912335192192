import React from "react";
import {Box, Button, Typography} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface EmailVerificationProps {
    onSwitchToLogin: () => void;
}

const EmailVerification: React.FC<EmailVerificationProps> = ({ onSwitchToLogin }) => {
    return (
        <>
            <Typography sx={{fontSize: '22px', color: 'gray', textAlign: 'center'}}>
                Enrollment Complete! Check your email to set your password, then return and click LOGIN below.
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <CheckCircleOutlineIcon sx={{color: 'green', fontSize: '100px'}}/>
            </Box>
            <Button sx={{fontSize: '26px'}} onClick={onSwitchToLogin}>
                Login
            </Button>
        </>
    );
};

export default EmailVerification;