
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React from "react";
import SideNavigationMenuOption from "./SideNavigationMenuOption";
import {useNavigate} from "react-router-dom";
import {ListItemIcon} from "@mui/material";
import SectionIcons from "./SectionIcons";

//eslint-disable-next-line
type MenuOptions = {
    groupId: string;
    displayName: string;
    products: any[];
}

function SideNavigationMenuGroup({menuOptions}: any) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(true);
    const hasManyProducts = menuOptions.products.length > 1
    const sorted = menuOptions.products.sort((a: any, b: any) => b.metadata.productType.localeCompare(a.metadata.productType));

    if(!menuOptions.products[0].metadata.productType){
        menuOptions.products[0].metadata.productType = 'other'
    }
    const handleClick = () => { hasManyProducts ? setOpen(!open) : navigate(`/dashboard/marketplace?display=${menuOptions.products[0].metadata.productType}`) };
    let isSelected = menuOptions.products.some((product: any) => product.metadata.productType === window.location.search.split("=")[1]);
    const sortedMenuOptions: any = sorted.map((product: any, index: any) => <SideNavigationMenuOption key={product.id || index} product={product}/>)

    return <>
        <ListItemButton
            onClick={handleClick}
            selected={!!isSelected}
        >
            <ListItemIcon>
                <SectionIcons productName={menuOptions.displayName?.toLowerCase()}/>
            </ListItemIcon>
            <ListItemText
                primary={menuOptions.displayName}
            />
            {hasManyProducts ? (open ? <ExpandLess /> : <ExpandMore />) : null}
        </ListItemButton>
        {
            hasManyProducts ? <Collapse in={!open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {sortedMenuOptions}
                </List>
            </Collapse> : <></>
        }
    </>
}

export default SideNavigationMenuGroup;
