import findSelectedProduct from "./findSelectedProduct";
import { useContext } from "react";
import { GeneralContext } from "../../../../../common/contexts/GeneralContext";

export default function CreatePriceArray(state: any, searchParams: any) {
    const prices: any = [];
    const { productDetails, products } = state;
    const itemQuery = searchParams.get('display');
    const { trialDays } = useContext(GeneralContext);

    // @ts-ignore
    Object.entries(products || {}).forEach(([productId, productPrices]) => {
        const productType = itemQuery ? findSelectedProduct(productDetails, itemQuery) : null;
        if (!itemQuery || (productType && productType.id === productId)) {
            // @ts-ignore
            Object.entries(productPrices).forEach(([priceId, priceDetails]: [string, { name: string }]) => {
                if (trialDays && priceDetails.name === 'Starter') {
                    return;
                }
                // @ts-ignore
                const priceWithProductId = { ...priceDetails, productId, productType, priceId };
                prices.push(priceWithProductId);
            });
        }
    });

    return prices;
}
