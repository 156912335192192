import {useCallback, useContext} from 'react';
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../config/firebaseConfig";
import useFindOneTimePaymentItems from "./useFindOneTimePaymentItems";
import {GeneralContext} from "../../common/contexts/GeneralContext";
import {useMarket} from "../../common/contexts/MarketContext";

function useProcessOneTimePurchaseItems(setStripeClientSecret: any, setOneTimePurchaseItems: any) {
    const findOneTimePaymentItems = useFindOneTimePaymentItems();

    const { repId } = useContext(GeneralContext);
    const { state } = useMarket();
    const { coupon } = state;

    const [addInvoiceItems] = useHttpsCallable(
        firebase_functions,
        'addInvoiceItems'
    );

    const processOneTimePurchaseItems = useCallback(async (pids: any[]) => {
        let localOtpPurchase = false;

        const {oneTimePayments, recurringPayments} = splitItems(pids);

        // todo should paynow be passed in?
        if (oneTimePayments.length > 0) {
            const response: any = await addInvoiceItems({
                prices: oneTimePayments,
                returnInvoice: !recurringPayments.length,
                coupon,
                repId,
            });

            // One time purchase is being made
            if (response && !response?.data?.status) {
                localOtpPurchase = true;
                setStripeClientSecret(response.data.paymentIntent.client_secret);
                setOneTimePurchaseItems(findOneTimePaymentItems(response.data.invoice.lines.data))
            }
        }

        return {recurringPayments, localOtpPurchase};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { processOneTimePurchaseItems };
}

// Splits items into one-time and recurring payments
function splitItems(pids: any[]) {
    const oneTimePayments = [];
    const recurringPayments: any[] = [];

    for (let pid of pids) {
        if (pid.type === 'one_time') {
            oneTimePayments.push(pid);
        } else if (pid.type === 'recurring') {
            recurringPayments.push(pid);
        }
    }

    return {oneTimePayments, recurringPayments};
}

export default useProcessOneTimePurchaseItems;
