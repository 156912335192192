import TollIcon from '@mui/icons-material/Toll';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import WaterfallChartIcon from "@mui/icons-material/WaterfallChart";
import SportsIcon from '@mui/icons-material/Sports';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import React from "react";

function SubSectionIcons( productName: any) {
        if (productName.productName === "Backtest Credits") {
            return (<TollIcon/>)
        }
        if (productName.productName === "Extra Alerts") {
            return (<AddAlertIcon/>)
        }
        if (productName.productName === "Notifications") {
            return (<NotificationsActiveIcon/>)
        }
        if (productName.productName === "Strategy  Suite") {
            return (<WaterfallChartIcon/>)
        }
        if (productName.productName === "Personal  Coaching") {
            return (<SportsIcon/>)
        }
        if (productName.productName === "Learn") {
            return (<LocalLibraryIcon/>)
        }
        else{
            return null;
        }
}

export default SubSectionIcons;