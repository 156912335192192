import {TextField} from "@mui/material";

type PasswordFieldProps = {
    setPassword: (n: string) => void;
    required?: boolean;
    onSubmit?: () => void;
};

export default function PasswordField(props: PasswordFieldProps) {
    const { setPassword, required, onSubmit } = props;

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && onSubmit) {
            onSubmit();
        }
    };

    return (
        <TextField
            required={required}
            label="Password"
            variant="outlined"
            type="password"
            onChange={(e) => {
                setPassword(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            sx={{
                width: '100%'
            }}
            inputProps={{
                style: {
                    padding: "16.5px 14px",
                },
            }}
        />
    );
}