import {CardElement, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Box, Button, Typography} from "@mui/material";
import {useEffect, useState} from "react";

export default function CheckoutForm(
    props: {
        type: string | undefined,
        clientSecret?: string,
        callback: any,
        setSubmit: any,
        submit: boolean,
        submitOtherInformation?: () => Promise<void>,
        oneTimePurchaseIncluded: boolean,
        informationFilledOut?: boolean, // for user details form, handled elsewhere
        setError?: any
    }) {
    const {type, clientSecret, callback, setSubmit, submit, setError, oneTimePurchaseIncluded, informationFilledOut, submitOtherInformation} = props;
    const [cardError, setCardError] = useState<string>('');
    const [focusingOnCardField, setFocusingOnCardField] = useState<boolean>(false);
    const [informationFinished, setInformationFinished] = useState<boolean>(false);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (elements && type === 'card') {
            const cardElement = elements.getElement(CardElement);

            cardElement?.on('focus', () => {
                setFocusingOnCardField(true);
            })

            cardElement?.on('blur', () => {
                setFocusingOnCardField(false);
            })
        }
    }, [elements, type])



    const handleSubmit = async (event:any) => {
        event.preventDefault();

        // Prevent multiple submissions
        if (submit) {
            return;
        }

        if (submitOtherInformation) await submitOtherInformation(); // right now this is just user details (fn, ln, phone)

        setSubmit(true);

        if (!stripe || !elements) {
            setSubmit(false);
            return;
        }

        if (type === 'card' && clientSecret) {
            const cardElement = elements.getElement(CardElement);
            if (cardElement) {
                try {
                    const res = await stripe.confirmCardSetup(clientSecret, {
                        payment_method: {
                            card: cardElement,
                        }
                    });

                    if (res.error) {
                        setError(res.error.message);
                        setSubmit(false);
                    } else {
                        callback(res.setupIntent?.payment_method);
                    }
                } catch (error) {
                    console.error("Error in confirmCardSetup", error);
                    // @ts-ignore
                    setError(error.message);
                    setSubmit(false);
                }
            }
        }

        if (type !== 'card') {
            // grab the current url host then /dashboard
            // pass in a variable status
            await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/processing-order`,
                },
            }).then((res: any) => {
                if (res.error) {
                    setError(res.error.message);
                    setSubmit(false);
                }
            });
        }
    }


    function handleCardChange(event: any) {
        setInformationFinished(event.complete);

        if (event.error) {
            setCardError(event.error.message);
        } else {
            setCardError('');
        }
    }

    function renderSubmitButton() {
        return (
            <Button
                type={'submit'}
                disabled={!informationFinished || submit || !informationFilledOut}
                sx={{
                    color: 'white',
                    maxWidth: '100px',
                    fontWeight: 700,
                    background: '#008000',
                    '&:hover': {
                        backgroundColor: '#07af54',
                        color: 'white',
                    },
                    '&:disabled': {
                        backgroundColor: '#dbdbdb',
                        color: 'white'
                    }
                }}>Submit</Button>
        )
    }

    function renderCardBorder() {
        if (cardError) return '1px solid red'
        else if (focusingOnCardField) return '1px solid hsla(210, 96%, 45%, 50%)'
        else return '1px solid rgb(196 196 196 / 50%)';
    }

    function renderExplanation() {
        if (oneTimePurchaseIncluded) {
            return;
        }

        return <Typography sx={{color: 'gray', fontSize: '18px', display: 'inline'}}>(required for future subscription)</Typography>
    }

    if (type === 'card') {
        // const style = {
        //     base: {
        //         iconColor: '#c4f0ff',
        //             color: '#fff',
        //             fontWeight: '500',
        //             fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        //             fontSize: '16px',
        //             fontSmoothing: 'antialiased',
        //             ':-webkit-autofill': {
        //             color: '#fce883',
        //         },
        //         '::placeholder': {
        //             color: '#87BBFD',
        //         },
        //     },
        //     invalid: {
        //         iconColor: '#FFC7EE',
        //             color: '#FFC7EE',
        //     },
        // }

        return (<form onSubmit={handleSubmit}>
            <Typography sx={{fontSize: '16px'}}>Enter Payment Info {renderExplanation()}</Typography>
            <Box
                sx={{
                    border: `${renderCardBorder()}`,
                    borderRadius: '4px',
                    padding: '8px',
            }}>
            <CardElement id={'cardElement'} options={ // TODO: adjust this styling
                {
                    style: {
                        base: {
                            // lineHeight: '40px',
                            iconColor: '#000',
                            color: '#000',
                            fontWeight: '500',
                            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                            fontSize: '16px',
                            fontSmoothing: 'antialiased',
                            fontVariant: 'normal',

                            ':-webkit-autofill': {
                                color: '#fce883',
                            },
                            // '::placeholder': {
                            //     color: '#87BBFD',
                            // },
                        },
                        // invalid: {
                        //     iconColor: '#FFC7EE',
                        //     color: '#FFC7EE',
                        // },
                    },
                }
            }
                         onChange={
                             (e) => handleCardChange(e)
                         }
            />
            </Box>
            <Box
                pt={1}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    sx={{color: 'red'}}
                >
                    {cardError}
                </Typography>
                {renderSubmitButton()}
            </Box>
        </form>)
    } else {
        return (<form onSubmit={handleSubmit}>
            <PaymentElement
                options={
                    {
                        fields: {
                            billingDetails: {
                               name: 'auto',
                               email: 'auto',
                            }
                        },
                    }
                }
                onChange={
                    (e) => handleCardChange(e)
                }
            />
            <Box
                pt={1}
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                {renderSubmitButton()}
            </Box>
        </form>)
    }
};
