import {Box, Tab, Tabs} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import TraderAccountSchedule from "../schedule/TraderAccountSchedule";
import {TraderAccountContext} from "../../contexts/TraderAccountContext";
import {useNavigate} from "react-router-dom";

export default function TraderAccountTabs(props: {setComponent: any}) {
    const accountMenus: string[] = ['Alerts', 'Analytics', 'Schedule'];
    const [value, setValue] = useState(0);
    const { setComponent } = props;
    const  { traderAccount } = useContext(TraderAccountContext);
    const navigate = useNavigate()

    useEffect(() => {
        if (!traderAccount) navigate('/admin/accounts');
        setTab(accountMenus[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMenuChange = (
        event: React.SyntheticEvent,
        newMenu: number,
    ) => {
        setValue(newMenu);
        setTab(accountMenus[newMenu]);
    };

    function setTab(tab: string) {
        switch (tab) {
            case 'Alerts':
                setComponent(<>Alerts</>); // TODO
                break;
            case 'Analytics':
                setComponent(<>Analytics</>); // TODO
                break;
            case 'Schedule':
                setComponent(<TraderAccountSchedule/>); // TODO
                break;
        }
    }

    return (<>

        <Box>
            <Tabs
                value={value}
                onChange={handleMenuChange}
            >
                {accountMenus.map((menu:string) => {
                    return (<Tab key={menu} label={menu}/>)
                })}
            </Tabs>
        </Box>


    </>)
}