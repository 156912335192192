import useGenerateCurrentProductsList from "./useGenerateCurrentProductsList";
import {useMarket} from "../../../../../common/contexts/MarketContext";
import createPriceArray from "../../../../pages/app/marketplace/helpers/createPriceArray";
import {useSearchParams} from "react-router-dom";
import useFindProductId from "./useFindProductId";

export default function useDetermineIfUpgradeOrDowngrade() {
    const {state} = useMarket();
    const [searchParams] = useSearchParams();
    const prices = createPriceArray(state, searchParams);
    const generateCurrentProducts = useGenerateCurrentProductsList();
    const currentProducts = generateCurrentProducts(prices);
    const findProductId = useFindProductId();

    return (priceId: string) => {
        const productId = findProductId(priceId);
        const currentProductPriceId = currentProducts[productId];

        if (!currentProductPriceId) {
            return 1;
        }

        if (!priceId) {
            return 0;
        }

        const currentProductIsAnnual = !!state.products[productId][currentProductPriceId]?.isAnnual;
        const currentProductPrice = parseInt(state.products[productId][currentProductPriceId].price);
        const priceIdPrice = parseInt(state.products[productId][priceId].price);


        if (currentProductPrice > priceIdPrice && !currentProductIsAnnual) {
            return -1;
        } else if (currentProductPrice > priceIdPrice && currentProductIsAnnual) {
            return -2;
        } else if (currentProductPrice < priceIdPrice) {
            return 1;
        } else {
            return 0;
        }
    }
}