import { useSearchParams } from "react-router-dom";

const useActionManager = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const getAction = () => {
        return searchParams.get('action');
    };

    const clearAction = () => {
        searchParams.delete('action');
        setSearchParams(searchParams);
    };

    return {
        getAction,
        clearAction
    };
};

export default useActionManager;
