import { useCallback } from 'react';

interface Item {
    interval?: string;
    // Include other properties of the item that might be used
}

function useFindSubscriptionItems() {
    const findSubscriptionItems = useCallback((items: Item[]) => {
        const subItems: Item[] = [];

        for (const item of items) {
            if (item.interval === 'month' || item.interval === 'year') {
                subItems.push(item);
            }
        }

        return subItems;
    }, []);

    return findSubscriptionItems;
}

export default useFindSubscriptionItems;
