import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Button, CircularProgress, MenuItem, Select, TablePagination} from "@mui/material";
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import {collection, query, limit, startAfter, orderBy, where, getDoc, doc} from "firebase/firestore";
import {firebase_firestore, firebase_functions} from "../../../config/firebaseConfig";
import {useContext, useEffect, useRef, useState} from "react";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {useCollectionCount} from "../hooks/useCollectionCount";
import Divider from "@mui/material/Divider";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {SnackbarHelperContext} from "../../../common/contexts/SnackbarHelperContext";

async function getTraderSubscriptionDetails(traderUsermeta: any,) {
    const subscription = (await getDoc(doc(firebase_firestore, `usermeta/${traderUsermeta.gcid}/subscriptions/greenchart`))).data();

    if (subscription) {
        const packagesOnSubscription = subscription.products.map((product: any) => product.name);
        const alertsPackage = subscription.products.find((product: any) => product.productName === "Alerts Package");

        let trialPeriodEnding;
        if (alertsPackage && 'promoDuration' in alertsPackage.metadata) {
            const packageCreated = new Date(alertsPackage.created * 1000);
            trialPeriodEnding = addDaysToDate(packageCreated, +alertsPackage.metadata.promoDuration);
        }

        traderUsermeta.subscription = {
            renewalDate: subscription.renewalDate,
            trialPeriod: trialPeriodEnding ? Math.floor(trialPeriodEnding.getTime() / 1000) : undefined,
            packages: packagesOnSubscription,
        }
    }

    function addDaysToDate(date: Date, days: number) {
        const newDate = new Date(date.valueOf());
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    }
}

function TraderRow(props: any) {
    const { traderUsermeta, traderUsermetaSelected, isSelected, style, refresh} = props;
    const [newStatus, setNewStatus] = useState<{ [tradingHouse: string]: string }>({"greenchart": "", "gtf": ""})
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);
    const [adminUpdateTraderMembership, adminUpdatingMembership] = useHttpsCallable(
        firebase_functions,
        'adminUpdateTraderMembership'
    );

    function formattedDate(epoch: number) {
        return new Date(epoch).toLocaleString()
    }

    async function updateStatus(tradingHouse: string) {
        if (!newStatus[tradingHouse]) {
            setSnackbarSettings({severity: 'warning', message: `Select a membership.`, autoHideDuration: 6000})
            return;
        }

        try {
            await adminUpdateTraderMembership({
                gcid: traderUsermeta.gcid,
                tradingHouse: tradingHouse,
                membership: newStatus[tradingHouse],
            })

            setSnackbarSettings({severity: 'success', message: 'Membership changed!', autoHideDuration: 6000})
            refresh()
        } catch (err: any) {
            setSnackbarSettings({severity: 'error', message: `Error (${err.status}) changing membership.`, autoHideDuration: 6000})
        }
    }

    function renderStatusHistory(trader: any, tradingHouse: "greenchart" | "gtf") {
        let labelName = {
            "greenchart": "GreenChart",
            "gtf": "Day Trading University",
        }

        if (!trader.tradingHouses[tradingHouse] || !trader.tradingHouses[tradingHouse].statusHistory) {
            return
        }

        return <>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Typography variant="h6" gutterBottom component="div">
                        {labelName[tradingHouse]} Status History
                    </Typography>
                    <Table size="small" aria-label="purchases">
                        <TableHead>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {trader.tradingHouses[tradingHouse].statusHistory.map((historyRow: any, index: number) => (
                                <>
                                    <TableRow key={historyRow.timestamp.seconds*1000} hover={true}>
                                        <TableCell>{historyRow.status}</TableCell>
                                        <TableCell>{formattedDate(historyRow.timestamp.seconds*1000)}</TableCell>
                                    </TableRow>
                                    <TableRow hover={true} sx={{ display: index === trader.tradingHouses[tradingHouse].statusHistory.length-1 ? "table-row" : "none", cursor: "pointer"}}>

                                        <TableCell>
                                            <Select
                                                size="small"
                                                displayEmpty
                                                value={newStatus[tradingHouse]}
                                                onChange={(e) => setNewStatus({...newStatus, [tradingHouse]: e.target.value})}
                                                renderValue={(selected: any) => {
                                                    if (!selected || selected.length === 0) {
                                                        return <em>New Status</em>;
                                                    }
                                                    return selected[0].toUpperCase()+selected.slice(1);
                                                }}
                                            >
                                                <MenuItem value={"member"} disabled={ trader.tradingHouses[tradingHouse].currentStatus.status === "member"}>Member</MenuItem>
                                                <MenuItem value={"trial"} disabled={ trader.tradingHouses[tradingHouse].currentStatus.status === "trial"}>Trial</MenuItem>
                                                <MenuItem value={"canceled"} disabled={ trader.tradingHouses[tradingHouse].currentStatus.status === "canceled"}>Canceled</MenuItem>
                                                <MenuItem value={"hold"} disabled={ trader.tradingHouses[tradingHouse].currentStatus.status === "hold"}>Hold</MenuItem>
                                                <MenuItem value={"free"} disabled={ trader.tradingHouses[tradingHouse].currentStatus.status === "free"}>Free</MenuItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            {
                                                !adminUpdatingMembership ?
                                                    <Button onClick={() => updateStatus(tradingHouse)}>
                                                        <AddCircleOutlineIcon/>
                                                    </Button>
                                                    : <CircularProgress />
                                            }
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </Box>

            </>

    }

    return (
        <React.Fragment>
            <TableRow style={style} sx={{ '& > *': { borderBottom: 'unset' }, cursor: "pointer" }} onClick={async () => {
                await getTraderSubscriptionDetails(traderUsermeta);
                traderUsermetaSelected(traderUsermeta);
            }} hover={true}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    >
                        {isSelected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{traderUsermeta.email}</TableCell>
                <TableCell>{traderUsermeta.firstName}</TableCell>
                <TableCell>{traderUsermeta.lastName}</TableCell>
                <TableCell>{(traderUsermeta.phoneNumber && traderUsermeta.phoneNumber.phoneNumber) || (traderUsermeta.alertPhoneNumber && traderUsermeta.alertPhoneNumber.phoneNumber)}</TableCell>
                <TableCell>{traderUsermeta.gcid}</TableCell>
                <TableCell>
                    <IconButton
                        size="small"
                    >
                        {!isSelected ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow sx={{background: "#eee", width: "100%"}}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={isSelected} timeout="auto" unmountOnExit>
                        <Box sx={{display: "flex", gap: 3, margin: 2 }}>
                            {renderStatusHistory(traderUsermeta, "gtf")}
                            <Divider orientation="vertical" flexItem/>
                            {renderStatusHistory(traderUsermeta, "greenchart")}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function TraderAccountTable(props: any) {
    const {searchString, searchField, gtfSearchSelected, greenchartSearchSelected, trader, traderUsermetaSelected, style} = props
    const [page, setPage] = React.useState(0);
    const prevPageRef = useRef(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [usermetaQuery, setUsermetaQuery] = React.useState(query(collection(firebase_firestore, `usermeta`), orderBy("email"), limit(rowsPerPage)))

    const [nextTraders, tradersLoading, , tradersSnapshot, reloadCollectionData] = useCollectionDataOnce(usermetaQuery);
    const [tradersVisible, setTradersVisible] = React.useState<any>([])

    const allTraderUsermetaQuery = query(collection(firebase_firestore, `usermeta`), orderBy("email"))
    const traderUsermetaCount = useCollectionCount(allTraderUsermetaQuery)

    const [queriedTraders, setQueriedTrader] = React.useState<any>([]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {

        // if (!trader && tradersVisible && !tradersVisible.find((visibleTrader: any) => visibleTrader.gcid === trader.gcid)) {
        //     traderUsermetaSelected(tradersVisible[0])
        // }

        setQueriedTrader([]);
        setPage(0)
        setUsermetaQuery(query(collection(firebase_firestore, `usermeta`),
            orderBy(searchField),
            limit(rowsPerPage),
            where(searchField, ">=", searchString),
            ))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString, gtfSearchSelected, greenchartSearchSelected])


    useEffect(() => {

        if (!nextTraders || tradersLoading) {
            return;
        }

        setQueriedTrader(queriedTraders.concat(nextTraders));
        setTradersVisible(nextTraders);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextTraders])

    useEffect(() => {
        if (tradersVisible[0] && (!trader || tradersVisible[0].gcid !== trader.gcid)) {
            getTraderSubscriptionDetails(tradersVisible[0]).then(() => {
                traderUsermetaSelected(tradersVisible[0]);
            });
            traderUsermetaSelected(tradersVisible[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tradersVisible])

    useEffect(() => {
        if (prevPageRef.current < page && queriedTraders.length <= page * rowsPerPage) {
            if (!tradersSnapshot || !tradersSnapshot.docs.length) {
                return;
            }

            setUsermetaQuery(query(usermetaQuery, startAfter(tradersSnapshot.docs[tradersSnapshot.docs.length-1])))
        } else {
            setTradersVisible(queriedTraders.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage))
        }

        prevPageRef.current = page;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, page])

    return (
        <>
            <Box style={style}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={traderUsermetaCount || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Email</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>GCID</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tradersVisible?.map((traderRow: any) => (
                                    <TraderRow key={traderRow.email}
                                               traderUsermeta={traderRow}
                                               isSelected={trader && trader.gcid === traderRow.gcid}
                                               style={trader && trader.gcid === traderRow.gcid ? {background:  "rgba(218, 165, 32, .2)"} : {} }
                                               traderUsermetaSelected={(selectedTrader: any) => {
                                                   traderUsermetaSelected(selectedTrader)
                                               }}
                                               refresh={() => reloadCollectionData()}
                                    />
                                ))}
                        </TableBody>

                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={traderUsermetaCount || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>

        </>
    );
}
