import React from 'react';
import {Box, Link, Typography} from '@mui/material';
import {agreementText} from "../documents/GNU_Agreement";

function splitParagraphs(document: string): string[] {
        return document.split('\n\n').filter((paragraph) => paragraph.trim() !== '');
}

const AIAgreement: React.FC = () => {
        const paragraphs = splitParagraphs(agreementText);

        return (
            <Box px={8} py={4} style={{textAlign: 'left'}}>
                <Box pb={2}>
                    <Link
                        href={'/ai'}
                        sx={{fontWeight: 500, fontSize: 18}}
                        underline={'none'}
                    >
                        Back
                    </Link>
                </Box>
                    {paragraphs.map((paragraph, index) => (
                        <Typography key={index} paragraph>
                                {paragraph}
                        </Typography>
                    ))}
                <Box>
                    <Link
                        sx={{fontWeight: 500, fontSize: 18}}
                        underline={'none'}
                        href={'https://github.com/generaltrader/tradergpt'}
                    >
                        https://github.com/generaltrader/tradergpt
                    </Link>
                </Box>
            </Box>
        );
};

export default AIAgreement;