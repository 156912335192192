import * as React from "react";
import {collection, query, where, getDocs, getCountFromServer} from "firebase/firestore";
import {firebase_firestore} from "../../config/firebaseConfig";
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useRef, useState} from "react";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { CSVLink } from "react-csv";

export default function DataViewer() {

    const [symbol, setSymbol] = useState("EURUSD")
    const [resolution, setResolution] = useState("1M")
    const [feed, setFeed] = useState("coinexx");

    const [toDate, setToDate] = useState<Dayjs | null>(dayjs())
    const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs())

    const [csvData, setcsvData] = useState<any>([[]])
    const csvLinkEl = useRef<any>(null);

    // const [symbolData, setSymbolData] = useState<any>([]);

    const [dataLength, setDataLength] = useState<number>(0)

    const resolutions = ["tick", "1M", "30M", "1D", "1W", "1Month"];
    const [symbols] = useCollectionDataOnce(query(collection(firebase_firestore, `symbols`), where("active", "==", true)))
    // const [symbolData, symbolDataLoading] = useCollectionDataOnce(query(collection(firebase_firestore, `symbols/${symbol}/resolutions/${resolution}/${resolution === "tick" ? 'coinexx' : 'historical'}`), where(resolution === "tick" ? 'time' : 'bar_start_datetime', ">=", fromDate?.toDate()), where(resolution === "tick" ? 'time' : 'bar_start_datetime', "<=", toDate?.toDate()), limit(100)));

    useEffect(() => {

        let feedToUse = feed === "coinexx" ? 'historical' : 'finnhub'
        let timeFieldName = 'bar_start_datetime'
        let fromDateToUse: any = fromDate?.toDate()
        let toDateToUse: any = toDate?.toDate()

        if (resolution === "tick") {
            feedToUse = feedToUse === "historical" ? "coinexx" : "finnhub";
            timeFieldName = "time";
            fromDateToUse = fromDateToUse?.toISOString()
            toDateToUse = toDateToUse?.toISOString()
        }

        getCountFromServer(query(
            collection(firebase_firestore, `symbols/${symbol}/resolutions/${resolution}/${feedToUse}`),
            where(timeFieldName, ">=", fromDateToUse),
            where(timeFieldName, "<=", toDateToUse))
        ).then((aggQuerySnapshot) => setDataLength(aggQuerySnapshot.data().count))

    }, [fromDate, toDate, symbol, resolution, feed])

    async function searchData() {

        let feedToUse = feed === "coinexx" ? 'historical' : 'finnhub'
        let timeFieldName = 'bar_start_datetime'
        let fromDateToUse: any = fromDate?.toDate()
        let toDateToUse: any = toDate?.toDate()

        if (resolution === "tick") {
            feedToUse = feedToUse === "historical" ? "coinexx" : "finnhub";
            timeFieldName = "time";
            fromDateToUse = fromDateToUse?.toISOString()
            toDateToUse = toDateToUse?.toISOString()
        }

        const dataSnapshotQuery = await getDocs(query(
            collection(firebase_firestore, `symbols/${symbol}/resolutions/${resolution}/${feedToUse}`),
            where(timeFieldName, ">=", fromDateToUse),
            where(timeFieldName, "<=", toDateToUse))
        )

        const data = dataSnapshotQuery.docs.map(data => ({...data.data(), bar_start_datetime: resolution === "tick" ? data.data().time : data.data().bar_start_datetime.seconds*1000}))


        let headers = ["time", "open", "high", "low", "close"]
        let dataCSV;
        if (resolution === "tick") {
            headers = ["time", "bid"]
            dataCSV = data.map((candle: any) => ([new Date(candle.bar_start_datetime).toLocaleString(), candle.bid]))
        } else {
            dataCSV = data.map((candle: any) => ([new Date(candle.bar_start_datetime).toLocaleString(), candle.open, candle.high, candle.low, candle.close]))
        }


        setcsvData(
            [
                headers,
                ...dataCSV
            ]
        )

        setTimeout(() => {
            csvLinkEl.current.link.click();
            
        })
    }

    return (<>
        <Grid container flexDirection={"column"} gap={4}>

            <Grid container flexDirection={"row"} gap={2} alignItems={"center"}>
                <Box>
                    <FormControl>
                        <InputLabel>Symbol</InputLabel>
                        <Select
                            value={symbol}
                            label="Symbol"
                            onChange={(e) => setSymbol(e.target.value)}
                        >
                            {symbols?.map((symbol) => <MenuItem value={symbol.name}>{symbol.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl sx={{width: "100px"}}>
                        <InputLabel>Resolution</InputLabel>
                        <Select
                            value={resolution}
                            label="Resolution"
                            onChange={(e) => setResolution(e.target.value)}
                        >
                            {resolutions?.map((resolution) => <MenuItem value={resolution}>{resolution}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl>
                        <InputLabel>Feed</InputLabel>
                        <Select
                            value={feed}
                            label="Feed"
                            onChange={(e) => setFeed(e.target.value)}
                        >
                            {["coinexx", "finnhub"]?.map((feed) => <MenuItem value={feed}>{feed}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>

            <Grid container flexDirection={"row"} gap={4} alignItems={"center"}>
                <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="From datetime"
                            value={fromDate}
                            onChange={(newValue: any) => {
                                if (!isNaN(newValue.toDate().getTime())) {
                                    setFromDate(newValue);

                                }
                            }}
                        />
                    </LocalizationProvider>
                </Box>
                <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="To datetime"
                            value={toDate}
                            onChange={(newValue: any) => {
                                if (!isNaN(newValue.toDate().getTime())) {
                                    setToDate(newValue);
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Box>
            </Grid>

            <Grid container flexDirection={"row"} gap={4} alignItems={"center"}>
                <Box>
                    <Button onClick={searchData}>Download {dataLength} {resolution === "tick" ? "ticks" : "candles"}</Button>
                    <CSVLink   filename={`${symbol}_${resolution}_${feed}_${fromDate?.toDate().toLocaleString()}_${toDate?.toDate().toLocaleString()}.csv`}
                               data={csvData}
                               style={{visibility: "hidden"}}
                               asyncOnClick={true}
                               ref={csvLinkEl}>Download {dataLength} {resolution === "tick" ? "ticks" : "candles"}</CSVLink>
                </Box>

            </Grid>
        </Grid>
    </>)

}
