// useGetUsermeta.ts
import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import { firebase_auth, firebase_firestore } from "../../config/firebaseConfig";

const useGetUsermeta = () => {
    // Explicitly specify the types for state variables
    const [user, loading, error] = useAuthState(firebase_auth);
    const [usermeta, usermetaLoading, usermetaError] = useDocumentData<any>(doc(firebase_firestore, `usermeta/${user?.uid}`));
    const [cachedUser, setCachedUser] = useState<any>(null);
    const [cachedUsermeta, setCachedUsermeta] = useState<any>(null);

    useEffect(() => {
        if (user && user !== cachedUser) {
            setCachedUser(user);
        }
        if (usermeta && usermeta !== cachedUsermeta) {
            setCachedUsermeta(usermeta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, usermeta]);

    return {
        user: cachedUser,
        usermeta: cachedUsermeta,
        loading: loading || usermetaLoading,
        error: error || usermetaError
    };
};

export default useGetUsermeta;
