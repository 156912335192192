import {
    Box,
    Button,
    TextField,
    ThemeProvider,
    Typography
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {firebase_functions} from "../../../config/firebaseConfig";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {GeneralContext} from "../../../common/contexts/GeneralContext";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'
import {SnackbarHelperContext} from "../../../common/contexts/SnackbarHelperContext";
import ProgressCircle from "../../../common/components/ProgressCircle";
import useUserDetails from "../../../common/hooks/useUserDetails";

export default function ProfileDetails() {
    const [displayFields, setDisplayFields] = useState('values');
    const [savingFields, setSavingFields] = useState(false);

    const [firstNameFieldValue, setFirstNameFieldValue] = useState('');
    const [lastNameFieldValue, setLastNameFieldValue] = useState('');
    const [phoneNumberFieldValue, setPhoneNumberFieldValue] = useState('');

    const [newFirstNameFieldValue, setNewFirstNameFieldValue] = useState('');
    const [newLastNameFieldValue, setNewLastNameFieldValue] = useState('');
    const [newPhoneNumberFieldValue, setNewPhoneNumberFieldValue] = useState('');

    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    const {generalTheme} = useContext(GeneralContext);

    const {loadingUser, errorLoadingUser, userDetails} = useUserDetails();

    const [updateUserDetails] = useHttpsCallable(firebase_functions, 'updateTraderAccountDetails');

    const [createPortalSession, executingCreatePortalSession] = useHttpsCallable(
        firebase_functions,
        'createPortalSession'
    );

    function saveFields() {
        setSavingFields(true);

        let updateObj: {
            firstName?: string,
            lastName?: string,
            phoneNumber?: {number: string, verified: false},
        } = {}

        if (newFirstNameFieldValue && newFirstNameFieldValue.trim() !== "") {
            updateObj.firstName = newFirstNameFieldValue
        }

        if (newLastNameFieldValue && newLastNameFieldValue.trim() !== "") {
            updateObj.lastName = newLastNameFieldValue
        }

        if (newPhoneNumberFieldValue && newPhoneNumberFieldValue.trim() !== "") {
            updateObj.phoneNumber = {number: newPhoneNumberFieldValue, verified: false}
        }

        updateUserDetails(updateObj)
            .then((res: any) => {
                setSavingFields(false);
                if (res?.data?.status === 204) {
                    setSnackbarSettings({severity: 'success', message: 'Account details have been updated!', autoHideDuration: 6000})
                } else {
                    setSnackbarSettings({severity: 'error', message: 'There was a problem updating your account details.', autoHideDuration: 6000})
                }
            }).catch(() => {
                setSnackbarSettings({severity: 'error', message: 'There was a problem updating your account details.', autoHideDuration: 6000})
            })
    }

    function cancel() {
        setDisplayFields('values');
    }

    function renderField(text: string, fieldPlaceholder: any, onChangeFunction: any) {
        if (displayFields === 'values' || !onChangeFunction || !fieldPlaceholder) {
            return <Box sx={{display: 'flex', alignItems: 'center', height: '48px'}}>
                    <Box sx={{paddingLeft: '14px', paddingBottom: '7px'}}>
                        <Typography variant={'h6'}>{text}</Typography>
                    </Box>
                </Box>
        } else {
            return <Box mb={1} sx={{
                display: 'grid'
            }}>
                {renderTypeOfField(fieldPlaceholder)}
                {/*<TextField*/}
                {/*    defaultValue={text}*/}
                {/*    size="small"*/}
                {/*    variant='outlined'*/}
                {/*    placeholder={fieldPlaceholder}*/}
                {/*    onChange={e => {onChangeFunction(e.target.value)}}*/}
                {/*/>*/}
            </Box>
        }

        function renderTypeOfField(type: string) {
            switch(type) {
                case 'Phone Number':
                    return (<PhoneInput
                        value={text}
                        limitMaxLength={true}
                        defaultCountry={'US'}
                        placeholder={fieldPlaceholder}
                        onChange={number => {onChangeFunction(number)}}
                    />)
                default:
                    return (<TextField
                        style={{
                                height: '40px',
                            }}
                        defaultValue={text}
                        size="small"
                        variant='outlined'
                        placeholder={fieldPlaceholder}
                        onChange={e => {onChangeFunction(e.target.value)}}
                    />)
            }
        }
    }

    function renderButtons() {
        if (displayFields === 'values') {
            return <Button sx={{
                fontWeight: 700,
                background: '#008000',
                '&:hover': {
                    backgroundColor: '#008000',
                    color: 'white',
                },
            }} variant="contained" onClick={() => setDisplayFields('fields')}>Edit profile</Button>
        } else {
            return <>
                <Button variant="contained" onClick={() => {
                saveFields();
                setDisplayFields('values');
            }}>Save values</Button>
            <Box pl={2} sx={{display:'inline', background: ''}}>
                <Button variant='outlined' onClick={() => cancel()}>Cancel</Button>
            </Box>
                </>
        }
    }

    async function directToCustomerPortal() {
        const portalSession = await createPortalSession({stripeId: userDetails?.stripeCustomerId, redirectUrl: window.location.href});
        // @ts-ignore
        window.location.replace(portalSession?.data.url)
    }

    useEffect(() => {
        setFirstNameFieldValue(userDetails?.firstName);
        setLastNameFieldValue(userDetails?.lastName);
        if (userDetails?.phoneNumber) {
            setPhoneNumberFieldValue(userDetails?.phoneNumber?.phoneNumber ? userDetails?.phoneNumber.phoneNumber : '+1' + userDetails?.phoneNumber);
        }
    }, [userDetails?.firstName, userDetails?.lastName, userDetails?.phoneNumber])

    if (errorLoadingUser) {
        return <>{errorLoadingUser}</>
    }
    // executingCreatePortalSession deserve its own welcome loading page...
    if (loadingUser || executingCreatePortalSession || savingFields) {
        return (
            <Box
                pt={2}
                pr={4}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <ProgressCircle/>
            </Box>
        )
    }

    function showModifyPaymentMethod() {
        if (userDetails && userDetails.stripeCustomerId) {
            return (<Box>
                <Button onClick={() => directToCustomerPortal()}>Modify Payment Method</Button>
            </Box>)
        } else return <></>;
    }

    return (
        <ThemeProvider theme={generalTheme}>


            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                <Typography variant={'h5'} sx={{color: displayFields === 'values' ? '' : "#1976d2"}}>First Name</Typography>
                <Box>
                    {renderField(firstNameFieldValue, 'First Name', setNewFirstNameFieldValue)}
                </Box>
                <Typography variant={'h5'} sx={{color: displayFields === 'values' ? '' : "#1976d2"}}>Last Name</Typography>
                <Box>
                    {renderField(lastNameFieldValue, 'Last Name', setNewLastNameFieldValue)}
                </Box>
                <Typography variant={'h5'} sx={{color: displayFields === 'values' ? '' : "#1976d2"}}>Phone</Typography>
                {renderField(phoneNumberFieldValue, 'Phone Number', setNewPhoneNumberFieldValue)}
                <Typography variant={'h5'} sx={{color: displayFields === 'values' ? '' : "#1976d2"}}>Email</Typography>
                {renderField(userDetails?.email, null, null)}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>
                        {showModifyPaymentMethod()}
                        {/*<Box>*/}
                        {/*    <Button onClick={() => navigate('/dashboard/changePassword')}>Change Password</Button>*/}
                        {/*</Box>*/}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {renderButtons()}
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    )
}
