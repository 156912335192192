import React, {createContext, useContext, ReactNode, useState, ReactElement} from 'react';
interface DialogContextType {
    openDialog: '' | 'downgrade' | 'upgrade' | 'endTrial' | 'event';
    setOpenDialog: (dialog: '' | 'downgrade' | 'upgrade' | 'endTrial' | 'event') => void;
    openUpgradeDialog: (price: string | undefined, element: JSX.Element) => void;
    setSelectedPriceId: (priceId: string) => void;
    setEndTrialContent: (content: ReactElement | string | null) => void;
    endTrialContent: ReactElement | string | null;
    upgradeDialogContent: ReactElement | null;
    selectedPriceId: string | null;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

interface DialogProviderProps {
    children: ReactNode;
}
export const DialogProvider: React.FC<DialogProviderProps> = ({children}) => {
    const [upgradeDialogContent, setUpgradeDialogContent] = useState<ReactElement | null>(null);
    const [selectedPriceId, setSelectedPriceId] = useState<string | null>(null);
    const [endTrialContent, setEndTrialContent] = useState<ReactElement | string | null>(null);
    const [openDialog, setOpenDialog] = useState<'' | 'downgrade' | 'upgrade' | 'endTrial' | 'event'>('');

    const openUpgradeDialog = (price: string | undefined, determineUpgradeText: any) => {
        setUpgradeDialogContent(determineUpgradeText);
        setOpenDialog('upgrade');
    };

    // @ts-ignore
    return (
        <DialogContext.Provider value={{
            openDialog,
            endTrialContent,
            setSelectedPriceId,
            upgradeDialogContent,
            setOpenDialog,
            openUpgradeDialog,
            setEndTrialContent,
            selectedPriceId
        }}>
            {children}
        </DialogContext.Provider>
    );
};

export const useDialog = () => {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error('useDialog must be used within a DialogProvider');
    }

    return context as DialogContextType;
};