// hooks/useExternalRedirect.ts

import { useNavigate } from "react-router-dom";
import {buildQueryParams} from "../helpers/buildQueryParams";

const newWindowPaths = ["/dashboard/builder"];

// Hook determines if we go to the external redirect page
export function useExternalRedirect() {
    const navigate = useNavigate();

    return (externalLink: string, currentPath?: string) => {
        const route = '/externalRedirect';
        const stateData = { link: externalLink };

        if (currentPath && newWindowPaths.includes(currentPath)) {
            const queryParams = buildQueryParams(stateData);
            const url = `${window.location.origin}${route}?${queryParams}`;
            window.open(url, "_blank");
        } else {
            setTimeout(() => {
                navigate(route, { state: stateData });
            }, 0);
        }
    };
}