import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { Button, Dialog} from "@mui/material";
import React from "react";

export default function NoCreditCardDialog(props: {isOpen: boolean, onClose: any, cancelFunction: any, title: string, children?: any}) {
    const {isOpen, onClose, cancelFunction, title, children} = props;

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={cancelFunction} >Back</Button>
            </DialogActions>
        </Dialog>
    )
}
