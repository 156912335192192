import React, {useContext, useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate, useSearchParams} from "react-router-dom";
import MembershipPortal from "./dashboard/pages/app/MembershipPortal";
import NotFound from "./common/pages/NotFound"
import ExternalRedirect from "./common/pages/ExternalRedirect"
import UnauthenticatedRouteGuard from "./common/guards/UnauthenticatedRouteGuard";
import GreenChartSubscriptionRouteGuard from "./common/guards/GreenChartSubscriptionRouteGuard";
import GreenChartSubscription from "./landing/pages/payments/GreenChartSubscription";
import AuthenticatedRouteGuard from "./common/guards/AuthenticatedRouteGuard";
import {GeneralContext, GeneralProvider} from "./common/contexts/GeneralContext";
import {BreadCrumbHelper} from "./common/contexts/BreadCrumbsHelperContext";
import {SnackbarHelper} from "./common/contexts/SnackbarHelperContext";
import PurchaseConfirmation from "./landing/pages/payments/PurchaseConfirmation";
import SnackbarMessage from "./common/components/SnackbarMessage";
import AdminRouteGuard from "./common/guards/AdminRouteGuard";
import AdminPanel from "./admin/pages/AdminPanel";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ForgottenPassword from "./landing/components/auth/app/ForgotPassword";
import SignUp from "./landing/components/auth/app/SignUp";
import TraderAI from "./landing/components/auth/app/TraderAI";
import Welcome from "./landing/components/auth/app/Welcome";
import Login from "./landing/components/auth/app/Login";
import SetPassword from "./landing/components/auth/app/SetPassword";
import AIAgreement from "./common/pages/AIAgreement";
import OpenRouteGuard from "./common/guards/OpenRouteGuard";
import {MarketProvider} from "./common/contexts/MarketContext";
import {DialogProvider} from "./common/contexts/DialogContext";
import ProcessingOrder from "./landing/pages/payments/ProcessingOrder";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "./config/firebaseConfig";


function DefaultPath() {
    const {preserveUrlParams} = useContext(GeneralContext);
    const [searchParams] = useSearchParams();

    return (
        <>
            <Navigate to={`/dashboard${preserveUrlParams(searchParams)}`} replace/>
        </>
    )
}


function App() {
    // log that the app has been accessed
    const [updateLastLogin] = useHttpsCallable(
        firebase_functions,
        'updateLastLogin'
    );

    useEffect(() => {
      void updateLastLogin({location: 'member center'})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <>
        <GeneralProvider>
            <MarketProvider>
                <BreadCrumbHelper>
                    <SnackbarHelper>
                        <Router>
                            <DialogProvider>
                              <Routes>
                                  <Route path='/' element={<DefaultPath/>}/>
                                  <Route path="login" element={
                                      <UnauthenticatedRouteGuard>
                                        <Login/>
                                      </UnauthenticatedRouteGuard>
                                  }/>
                                  <Route path="signup" element={
                                      <UnauthenticatedRouteGuard>
                                        <SignUp/>
                                      </UnauthenticatedRouteGuard>
                                  }/>
                                  <Route path="forgot-password" element={
                                      <UnauthenticatedRouteGuard>
                                        <ForgottenPassword/>
                                      </UnauthenticatedRouteGuard>
                                      }/>
                                  <Route path="ai" element={
                                      <OpenRouteGuard>
                                          <TraderAI/>
                                      </OpenRouteGuard>
                                  }/>
                                  <Route path="dashboard/*" element={
                                      <GreenChartSubscriptionRouteGuard>
                                        <MembershipPortal/>
                                      </GreenChartSubscriptionRouteGuard>
                                  }/>
                                  <Route path="confirmation" element={
                                      <AuthenticatedRouteGuard>
                                          <GreenChartSubscriptionRouteGuard>
                                            <PurchaseConfirmation/>
                                          </GreenChartSubscriptionRouteGuard>
                                      </AuthenticatedRouteGuard>
                                  }/>
                                  <Route path="processing-order" element={
                                      <AuthenticatedRouteGuard>
                                          <GreenChartSubscriptionRouteGuard>
                                              <ProcessingOrder/>
                                          </GreenChartSubscriptionRouteGuard>
                                      </AuthenticatedRouteGuard>
                                  }/>
                                  <Route path="subscription" element={
                                      <AuthenticatedRouteGuard>
                                        <GreenChartSubscription/>
                                      </AuthenticatedRouteGuard>
                                  }/>
                                  <Route path="welcome" element={
                                      <AuthenticatedRouteGuard>
                                          <Welcome/>
                                      </AuthenticatedRouteGuard>
                                  }/>
                                  <Route path="set-password" element={
                                        <SetPassword/>
                                  }/>
                                  <Route
                                      path="/externalRedirect"
                                      element={<ExternalRedirect/>}
                                  />
                                  <Route
                                      path="/ai-agreement"
                                      element={<AIAgreement/>}
                                  />
                                  <Route
                                      path="/admin/*"
                                      element={
                                      <AdminRouteGuard>
                                        <AdminPanel/>
                                      </AdminRouteGuard>
                                  }/>

                                  <Route path="*" element={<NotFound/>} />
                              </Routes>
                            </DialogProvider>
                        </Router>
                        <SnackbarMessage/>
                    </SnackbarHelper>
                </BreadCrumbHelper>
            </MarketProvider>
      </GeneralProvider>
      </>
    );
}

export default App;
