import {useContext} from 'react';
import {GeneralContext} from "../../common/contexts/GeneralContext";

function useItemsFromCart() {
    const { cart } = useContext(GeneralContext);

    return () => {
        const priceIds = [];
        if (Object.keys(cart).length) {
            for (const [key] of Object.entries(cart)) {
                if (typeof cart[key].quantity) {
                    const {productId, quantity, freeTrial, type} = cart[key];
                    priceIds.push({priceId: key, productId, quantity, freeTrial, type});
                }
            }
        }
        return priceIds;
    };
}

export default useItemsFromCart;