import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import EventIcon from '@mui/icons-material/Event';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import React from "react";

function SectionIcons({ productName }: { productName: string }) {
    const iconMapping: { [key: string]: JSX.Element | null } = {
        membership: <BadgeIcon />,
        education: <SchoolIcon />,
        event: <EventIcon />,
        "Strategy Suite": <WaterfallChartIcon />,
    };

    return iconMapping[productName] || null;
}

export default SectionIcons;