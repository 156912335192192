import React, {useEffect, useState, createRef} from "react";
import {Box, Button, Checkbox, Link, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import FirstNameField from "../fields/FirstNameField";
import LastNameField from "../fields/LastNameField";
import EmailTextField from "../../../common/components/EmailTextField";
import PhoneField from "../fields/PhoneField";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_auth, firebase_functions} from "../../../config/firebaseConfig";
import {SignUpLocationMap} from "../../../common/helpers/SignUpLocationMap";
import {useLocation} from "react-router-dom";
import {signInWithCustomToken} from "firebase/auth";
import { isValidPhoneNumber } from 'react-phone-number-input';
import ReCAPTCHA from 'react-google-recaptcha';

interface SignUpFormProps {
    onSignUpSuccess: () => void;
    onSwitchToLogin: () => void;
    setLoading: (arg0: boolean) => void;
    setErrorMessage: (arg0: any) => void;
    errorMessage: any;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ onSignUpSuccess, onSwitchToLogin, setLoading, setErrorMessage, errorMessage }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const recaptchaRef = createRef<ReCAPTCHA>();
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY
    const location = useLocation(); // get location from useLocation hook

    //State variables
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [agreed, setAgreed] = useState<boolean>(false);
    const [shouldProceed, setShouldProceed] = useState(false);
    const [welcomeText, setWelcomeText] = useState('Sign up to get started');
    const [register, executingRegistration] = useHttpsCallable(
        firebase_functions,
        'inAppRegistration'
    );

    const [validateRecaptcha] = useHttpsCallable(firebase_functions, 'recaptcha');
    const px = isMobile ? 2 : 12

    // add state variables for UTM parameters
    const [utmSource, setUtmSource] = useState<string | null>(null);
    const [utmMedium, setUtmMedium] = useState<string | null>(null);
    const [utmCampaign, setUtmCampaign] = useState<string | null>(null);
    const [utmTerm, setUtmTerm] = useState<string | null>(null);
    const [utmContent, setUtmContent] = useState<string | null>(null);

    // function to parse UTM parameters from the URL
    function getUtmParams() {
        if (!location) return;

        const params = new URLSearchParams(location.search);

        const baseSource = window.location.hostname;
        const sourceFromParams = params.get('utm_source');
        setUtmSource(sourceFromParams || baseSource);

        setUtmMedium(params.get('utm_medium'));
        setUtmCampaign(params.get('utm_campaign'));
        setUtmTerm(params.get('utm_term'));
        setUtmContent(params.get('utm_content'));

        const marketplacePage = params.get('display');
        if (!marketplacePage) return;

        if (marketplacePage === 'membership') {
            setWelcomeText(`Sign up to get started with your free trial!`);
        } else if (marketplacePage === 'learn') {
            setWelcomeText(`Sign up to get started with Currency Trading Unleashed!`);
        }
    }

    // call getUtmParams when the component mounts
    useEffect(() => {
        getUtmParams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        setLoading(executingRegistration);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executingRegistration])

    useEffect(() => {
            setShouldProceed(isEmailValid && agreed && firstName.length > 0 && lastName.length > 0 && isValidPhoneNumber(phoneNumber));
    }, [isEmailValid, agreed, firstName, lastName, phoneNumber]);

    async function registerUser() {
        setErrorMessage(null);

        const token = await recaptchaRef.current!.executeAsync();
        const response = await validateRecaptcha({
            "event": {
                "token": token,
                "expectedAction": "USER_ACTION",
                "siteKey": recaptchaKey,
            }
        })
        if(response && response.data){
            const page = SignUpLocationMap[getCurrentSubdirectory()];
            // include UTM parameters when calling register function
            await register({
                email,
                firstName,
                lastName,
                phoneNumber,
                page,
                utmSource,
                utmMedium,
                utmCampaign,
                utmTerm,
                utmContent,
                tokenRequested: true,
            }).then(async (response) => {
                await checkRegisterData(response);
            });
       }
    }

    async function checkRegisterData(response: any) {
        if (response && response.data && response.data.status === 204) {

            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('display')) {
                await signInWithCustomToken(firebase_auth, response.data.token);
                setLoading(false)
            } else {
                setLoading(false)
                onSignUpSuccess();
            }

        } else if (response && response.data && response.data.status === 409) {
            setErrorMessage(noAccount());
            setAgreed(false);
        } else {
            setErrorMessage('Something went wrong, please contact GreenChart support if this issue continues to persist.');
        }

        setLoading(false);
    }

    function noAccount() {
        return (<>
            An account with the email {email} is already in use. If this is you, login {
                <Link sx={{userSelect: 'none', cursor: 'pointer'}}
                    onClick={() => {
                        onSwitchToLogin();
                    }} underline={'none'}>here</Link>}
        </>);
    }

    function getCurrentSubdirectory(): string {
        const path = window.location.pathname;
        const pathParts = path.split('/');

        const nonEmptyPathParts = pathParts.filter((part) => part);

        return nonEmptyPathParts.length > 0 ? nonEmptyPathParts[0] : '';
    }

    return (<>
        {/*ERRORS*/}
        <Box px={px} sx={{color:'#C62828'}}>{errorMessage}</Box>
        <Box px={px} sx={{textAlign: 'center'}}>
            <Typography sx={{alignSelf: 'center', fontSize: '22px', fontWeight: 500}}>{welcomeText}</Typography>
        </Box>

        <Box px={px}>
            <EmailTextField
                setIsEmailValid={setIsEmailValid}
                onChange={setEmail}
                required
            />
        </Box>
        <Box px={px}>
            <FirstNameField
                setFirstName={setFirstName}
                required
            />
        </Box>
        <Box px={px}>
            <LastNameField
                setLastName={setLastName}
                required
            />
        </Box>
        <Box px={px}>
            <PhoneField
                setPhoneNumber={setPhoneNumber}
                phoneNumber={phoneNumber}
            />
        </Box>
        <Box px={px}>
            <Typography sx={{
                fontSize: '14px',
                color: 'rgb(167, 167, 167)',
                transition: 'color 0.3s ease-in-out',
                '&:hover': {
                    color: 'black',
                },
            }}>
                By signing up with GreenChart I will receive email newsletters, be enrolled in webinar trainings, and receive email and SMS (text) notifications concerning my account and special offers. I have also read and agree to the <Link target="_blank" underline={"none"} href={'https://www.greenchart.com/terms-of-use'} rel="noopener noreferrer">Terms of Use</Link> of this website.
            </Typography>
            <Box pt={1} pb={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Checkbox
                    sx={{padding: '0px', paddingRight: '9px' }}
                    onChange={() => setAgreed(!agreed)}
                />
                I agree
            </Box>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={recaptchaKey? recaptchaKey : ''}
                grecaptcha={{enterprise: true}}
            />
        </Box>
        <Box px={px}>
            <Button
                sx={{width: '100%'}}
                variant="contained"
                onClick={() => registerUser()}
                disabled={!shouldProceed}>
                Sign up
            </Button>
        </Box>
        <Stack direction="row" sx={{alignSelf: 'center'}} pb={2}>
            <Button onClick={onSwitchToLogin}>
                Already have an account?
            </Button>
        </Stack>
    </>);
};

export default SignUpForm;