import { useCallback } from 'react';

interface Item {
    interval?: string;
    price?: {
        type?: string;
    };
}

function useFindOneTimePaymentItems() {
    const findOneTimePaymentItems = useCallback((items: Item[]) => {
        const otpItems: Item[] = [];

        for (const item of items) {
            if (item?.interval === 'one_time' || item?.price?.type === 'one_time') {
                otpItems.push(item);
            }
        }

        return otpItems;
    }, []);

    return findOneTimePaymentItems;
}

export default useFindOneTimePaymentItems;
