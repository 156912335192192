import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, startAfter, endBefore, limit, getDocs } from 'firebase/firestore';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Grid } from '@mui/material';
import {firebase_firestore} from "../../../../config/firebaseConfig";
import {useCollectionCount} from "../../hooks/useCollectionCount";

function BacktestUsermetaUsage() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<any>(5);
    const [firstDoc, setFirstDoc] = useState<any>(null);
    const [lastDoc, setLastDoc] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<any>([]);

    const usermetaBacktestersCount = useCollectionCount(query(
        collection(firebase_firestore, 'usermeta'),
        orderBy('products.backtest.accruedCost', 'desc'))
    );


    // Function to fetch data for a page
    async function fetchPage(newPage: number) {
        try {
            let queryRef = query(
                collection(firebase_firestore, 'usermeta'),
                orderBy('products.backtest.accruedCost', 'desc'),
                limit(rowsPerPage)
            );

            if (newPage > 0 && lastDoc) {
                queryRef = query(queryRef, startAfter(lastDoc));
            } else if (newPage < page && firstDoc) {
                queryRef = query(queryRef, endBefore(firstDoc));
            }

            const snapshot = await getDocs(queryRef);
            const docs = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

            setData(docs);
            if (docs.length > 0) {
                setFirstDoc(snapshot.docs[0]);
                setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
            }
        } catch (e) {
            setError(e);
        }
    };

    // Effect to fetch the initial page
    useEffect(() => {
        fetchPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle page change
    const handleChangePage = (event: any, newPage: any) => {
        fetchPage(newPage);
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setFirstDoc(null);
        setLastDoc(null);
        fetchPage(0);
    };

    return (
        <Grid>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={usermetaBacktestersCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Accrued Cost</TableCell>
                            <TableCell>Max Cost</TableCell>
                            <TableCell>Recurring Cost</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {error && <TableRow><TableCell colSpan={4}>Error: {error.message}</TableCell></TableRow>}
                        {data.map((row: any, index: number) => (
                            <TableRow key={index}>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.firstName} {row.lastName}</TableCell>
                                <TableCell>{row.products.backtest.accruedCost?.toFixed(4)}</TableCell>
                                <TableCell>{row.products.backtest.maxCost}</TableCell>
                                <TableCell>{row.products.backtest.recurringCost}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={usermetaBacktestersCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Grid>
    );
};

export default BacktestUsermetaUsage;
