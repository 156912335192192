import React, {useContext, useEffect} from 'react';
import {Box, Typography, useMediaQuery, useTheme,} from "@mui/material";
import {Navigate, useSearchParams} from "react-router-dom";
import {BreadCrumbsHelperContext} from "../../../common/contexts/BreadCrumbsHelperContext";
import {useMarket} from "../../../common/contexts/MarketContext";
import useUserDetails from "../../../common/hooks/useUserDetails";
import BreadCrumbsNavigation from "../../../common/components/BreadCrumbsNavigation";
import ProgressCircle from "../../../common/components/ProgressCircle";
import CouponInput from "../../components/marketplace/inputs/CouponInput";
import Footer from "../../../common/components/Footer";
import SideNavigationMenu from "../../components/marketplace/sideNavigation/SideNavigationMenu";
import ProductsDisplay from "../../components/marketplace/renderProductPrices/ProductsDisplay"
import AnnualToggle from "../../components/marketplace/AnnualToggle";
import createPriceArray from "./marketplace/helpers/createPriceArray";

export default function Marketplace() {
    const {userDetails} = useUserDetails();

    const {pushCrumbs, breadCrumbs} = useContext(BreadCrumbsHelperContext);
    const [searchParams] = useSearchParams();
    const { state, getProducts, executingGetActiveProducts } = useMarket();
    const { productDetails, products } = state
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const prices = createPriceArray(state, searchParams);
    const pricePairs = organizePricesIntoPricePairs(prices);

    // // Pushing crumbs
    useEffect(() => {
        const productType = searchParams.get('display');
        const crumbs: {name: string, location: string}[] = [];

        if (productType && checkCurrentCrumbEqualTo(productType)) {
            return;
        }

        crumbs.push({name: 'marketplace', location: '/dashboard/marketplace?display=membership'});

        if (productType && productType.length) {
            const link = `/dashboard/marketplace?display=${productType}`
            crumbs.push({name: productType, location: link});
        }

        if (!crumbs.length) {
            return;
        }

        pushCrumbs(crumbs, true);

        function checkCurrentCrumbEqualTo(name: string): boolean {
            return !!breadCrumbs?.[breadCrumbs.length - 1]?.name && breadCrumbs[breadCrumbs.length - 1].name === name;
        }
    })

    useEffect(  () => {
        if (!productDetails || !products) void getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails, productDetails, products]);

    function renderSubscriptionRequired() {
        const subscriptionRequired = searchParams.get('subReq')// TODO: check if subscription required from the parameters
        return (subscriptionRequired && subscriptionRequired === 'greenchart' && <Box>
            <Typography>
                A GreenChart membership is required to view this content, to view content purchase one of the subscriptions below.
            </Typography>
        </Box>)
    }

    function organizePricesIntoPricePairs(prices: any) {
        const pricePairs = [];
        const addedPrices: string[] = [];

        for (const price of prices) {
            const priceMatch = prices.find((potentialPriceMatch:any) => (price.metadata.monthlyEquivalent ? price.metadata.monthlyEquivalent : price.metadata.annualEquivalent) === potentialPriceMatch.priceId);
            const priceNotAddedYet = !addedPrices.includes(price.priceId) && !addedPrices.includes(priceMatch?.priceId);

            if (priceNotAddedYet && !priceMatch) {
                pricePairs.push({
                    monthly: price
                })
            } else if (priceMatch && priceNotAddedYet) {
                addedPrices.push(price.priceId);
                addedPrices.push(priceMatch.priceId);
                pricePairs.push({
                    annual: price.metadata.monthlyEquivalent ? price : priceMatch,
                    monthly: price.metadata.annualEquivalent ? price : priceMatch,
                })
            }
        }
        return pricePairs;
    }

    // TODO convert to dialog manager function

    if (!userDetails || userDetails.loadingUserDetails || userDetails.loadingUser || executingGetActiveProducts) {
        return <ProgressCircle/>;
    }

    const queryParams = new URLSearchParams(window.location.search);
    if(!queryParams.has('display')){
        return <Navigate to={`/dashboard/marketplace?display=membership`} />
    }

    return (
        <>
        <Box p={2} minHeight={"85vh"}>
            {/*Breadcrumbs*/}
            <Box
                pl={isMobile ? 1 : 6}
                mb={3}
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: isMobile ? 'flex-start' : 'space-between',
                }}>
                <Box sx={{ marginBottom: isMobile ? '12px' : '0' }}> {/* added this Box */}
                    <BreadCrumbsNavigation/>
                </Box>
            </Box>
            {/*Wall*/}
            {renderSubscriptionRequired()}
            {/*Cards*/}
            <Box
                sx={{
                    paddingBottom: '24px',
                    display:'flex',
                    justifyContent: 'start',
                    flexWrap: isMobile ? 'wrap': null,
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexBasis: '240px',
                    flexGrow: isMobile ? 1 : 0,
                    minWidth: '240px'
                }}>
                    <SideNavigationMenu/>
                    <CouponInput onValidCoupon={(coupon) => console.log(`Coupon ${coupon} is valid`)} />
                    <AnnualToggle products={pricePairs}/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexGrow: 1,
                }}>
                    {/*@ts-ignore*/}
                    {executingGetActiveProducts ? <ProgressCircle/> : <ProductsDisplay prices={prices} pricePairs={pricePairs}/>}
                </Box>
            </Box>
        </Box>
        <Footer/>
    </>
    )
}