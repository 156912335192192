import {useMarket} from "../../../../../common/contexts/MarketContext";

export default function useFindProductId() {
    const {state} = useMarket();
    return (priceId?: any) => {
        if (!state.products) return '';
        for (const [productId, prices] of Object.entries(state.products)) {
            // @ts-ignore
            if (prices.hasOwnProperty(priceId)) {
                return productId;
            }
        }
        return '';
    }
}