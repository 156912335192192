import React from 'react';
import {Box, Dialog, DialogContent, useMediaQuery, useTheme} from '@mui/material';
import logo from "../../../../assets/stacked_logo.png";
import ModalContent from "../ModalContent";

interface Props {
    continueUrl?: string;
}

const SetPassword: React.FC<Props> = ({ continueUrl }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (<>
        <Box
            sx={{
                backgroundColor: "#02111b",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Dialog
                open={true}
                hideBackdrop={true}
                sx={{backgroundColor: '#02111b'}}
            >
                <Box
                    component="img"
                    sx={{
                        width: "200px",
                        alignSelf: "center",
                        cursor: "pointer",
                    }}
                    src={logo}
                    mt={3}
                    onClick={() => window.open("https://www.greenchart.com", "_blank")}
                />
                <DialogContent
                    sx={{
                        padding: 0,
                        minWidth: isMobile ? "auto" : "600px",
                    }}
                >
                    <ModalContent
                        state={'setPassword'}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    </>)
};

export default SetPassword;
