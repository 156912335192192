import {Navigate, useSearchParams} from "react-router-dom";
import React, {useContext} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {firebase_auth} from "../../config/firebaseConfig";
import {GeneralContext} from "../contexts/GeneralContext";
import ProgressCircle from "../components/ProgressCircle";

// You have to be authenticated to proceed
export default function AuthenticatedRouteGuard({ children }: any) {
    const [user, loading] = useAuthState(firebase_auth);
    const [searchParams] = useSearchParams();
    const {preserveUrlParams} = useContext(GeneralContext);

    if (loading) {
       return <ProgressCircle/>;
    }

    if (!user && !loading) {
        return <Navigate to={"/login" + preserveUrlParams(searchParams)} replace />;
    }

    return children;
};
