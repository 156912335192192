import {Typography} from "@mui/material";
import React from "react";
import {useApplyCouponToPrice} from "../../../../../common/hooks/useApplyCouponToPrice";
import calculateNextPurchaseDate from "./util/calculateNextPurchaseDate";
import formatYearlyPrice from "./util/formatYearPrice";
import getPrice from "./util/getPrice";

type SubscriptionTitleProps = {
    upcomingInvoiceItem: any;
    upcomingInvoice: any;
};

export default function RenewalDate(props: SubscriptionTitleProps) {
    const applyCouponToPrice = useApplyCouponToPrice();
    const { upcomingInvoiceItem, upcomingInvoice } = props;

    const monthlyRenewalString = () => {
        return `Subscription renews on ${new Date(upcomingInvoice.period_end*1000).toLocaleDateString()} for ${getPrice(upcomingInvoice, upcomingInvoiceItem, applyCouponToPrice)}`;
    }

    const yearlyRenewalString = () => {
        return `Subscription renews on ${calculateNextPurchaseDate(upcomingInvoiceItem.subscription_item.created)} for ${formatYearlyPrice(upcomingInvoiceItem.price.metadata.annualPrice)}`;
    }

    const isMonthly = (upcomingInvoiceItem: any) => !upcomingInvoiceItem.price.metadata?.annualPrice;

    function generateSubscriptionRenewalString() {
        if (isMonthly(upcomingInvoiceItem)) {
            return monthlyRenewalString();
        } else {
            return yearlyRenewalString();
        }
    }

    if (!upcomingInvoiceItem) return <></>;

    return (
        <Typography>
            {generateSubscriptionRenewalString()}
        </Typography>
    );
}