import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import {Box, Grid} from "@mui/material";
// import Typography from "@mui/material/Typography";
// const isProd = process.env.NODE_ENV === 'production';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <>
        <App />
        {
            // !isProd ?
            //     <Box sx={{position: "absolute", bottom: 0, width: "calc(100% - 16px)", height: "20px", background: "red", color: "#eee", p: 1}}>
            //         <Grid container alignItems={"center"} gap={1}>
            //             <Typography>Resolution:</Typography>
            //             <Box display={{xs: "none", xl: "block"}}>XL</Box>
            //             <Box display={{xs: "none", lg: "block", xl: "none"}}>LG</Box>
            //             <Box display={{xs: "none", md: "block", lg: "none"}}>MD</Box>
            //             <Box display={{xs: "none", sm: "block", md: "none"}}>SM</Box>
            //             <Box display={{xs: "block", sm: "none"}}>XS</Box>
            //         </Grid>
            //     </Box>
            //     : <></>
        }
    </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
