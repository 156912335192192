// hooks/useConditionalNavigation.ts
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { GeneralContext } from "../../contexts/GeneralContext";
import useUserDetails from "../../hooks/useUserDetails";
import ProgressCircle from "../../components/ProgressCircle";
import React from "react";

interface ConditionalNavigationResult {
    component: React.ReactElement | null;
}

const useConditionalNavigation = (): ConditionalNavigationResult => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const { preserveUrlParams, checkAccountStatuses, setRepId } = useContext(GeneralContext);
    const { user, loadingUser, loadingUserDetails, userDetails } = useUserDetails();

    useEffect(() => {
        if (user && !loadingUser && userDetails) {
            checkAccountStatuses(userDetails);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails]);

    if (loadingUser || loadingUserDetails) {
        // Return the loading component
        return { component: <ProgressCircle /> };
    }

    if (user && !loadingUser && userDetails) {
        if (location) {
            const params = new URLSearchParams(location.search);
            setRepId(params.get('repId'));
        }

        localStorage.setItem('isAuthenticated', 'true');

        const { hasChartingAccess, hasGreenChartTradingHouse, hasPaidSS } = checkAccountStatuses(userDetails);

        if (searchParams.get('display')) {
            return { component: <Navigate to={`/dashboard/marketplace${preserveUrlParams(searchParams)}`} replace /> };
        } else if (hasPaidSS) {
            return { component: <Navigate to={`/dashboard/strategy-suite`} /> };
        } else if (hasGreenChartTradingHouse && !hasChartingAccess) {
            return { component: <Navigate to={`/dashboard/marketplace?display=membership`} /> };
        } else {
            return { component: <Navigate to={`/dashboard/account${preserveUrlParams(searchParams)}`} replace /> };
        }
    }

    localStorage.setItem('isAuthenticated', 'false');
    return { component: null };
};

export default useConditionalNavigation;
