import React from 'react';
import {Box} from "@mui/material";
import NestedList from "../../../components/NestedList";

function gtfStarterPlusFeatures() {
    const featureList = [
        {
            feature: 'Strategy Suite',
            listItems: [
                'Pattern Builder: 5 patterns',
                'Automated Backtester: 5 backtest credits* per month',
                'Pattern Alerts: 5 custom alerts',
                'Alert Notifications (via SMS**, email): 100 per month',
                'Strategy Showcase - Set up an Alert to follow another user’s Pattern',
            ]
        },
        {
            feature: 'More Training',
            listItems: [
                'Strategy Suite Training Videos',
            ]
        },
    ];

    return (
        <Box sx={{
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            lineHeight: "1.5",
            letterSpacing: "0.00938em",
            fontSize: "16px",
            alignItems: "left",
            margin: "0px"
        }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <NestedList items={featureList} />
        </Box>
    );
}

export default gtfStarterPlusFeatures;
