import { useMarket } from '../contexts/MarketContext';

export const useApplyCouponToPrice = () => {
    const { state } = useMarket();
    let { coupon } = state;

    const applyCouponToPrice = (priceStr: string, couponApplied?: any): string => {
        if (couponApplied) {
            coupon = couponApplied;
        }

        let price = parseInt(priceStr);

        if (!coupon || !coupon.coupon?.valid) { //!coupon.active
            // The coupon is not valid or not active
            return priceStr;
        }

        if (coupon.coupon.percent_off != null) {
            // The coupon provides a percentage discount
            price = price * (1 - (coupon.coupon.percent_off / 100));
        } else if (coupon.coupon.amount_off != null) {
            // The coupon provides a fixed amount discount
            price = price - coupon.coupon.amount_off;
            price = price > 0 ? price : 0; // Don't allow the price to go negative
        }

        return price.toString();
    }

    return applyCouponToPrice;
}
