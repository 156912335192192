
import {firebase_functions} from "../../../config/firebaseConfig";
import {Box, Button, createTheme, Grid, ThemeProvider, Typography} from "@mui/material";
import React, { useContext, useEffect, useState} from "react";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {priceMapper} from "../../../landing/helpers/priceMapper";
import {convertToDate} from "../../../landing/helpers/convertUnixDate";
import {useNavigate} from "react-router-dom";
import {SnackbarHelperContext} from "../../../common/contexts/SnackbarHelperContext";
import ProgressCircle from "../../../common/components/ProgressCircle";
import SubscriptionTitle from "./components/SubscriptionTitle";
import RenewalDate from "./components/renewalDate/RenewalDate";

const theme = createTheme({
    components: {
        MuiTypography: {
            variants: [
                {
                    props: {
                        variant: "h6"
                    },
                    style: {
                        fontSize: 16,
                        fontWeight: 300
                    }
                },
                {
                    props: {
                        variant: "h5"
                    },
                    style: {
                        fontSize: 16,
                        fontWeight: 500
                    }
                },
                {
                    props: {
                        variant: "h4"
                    },
                    style: {
                        fontSize: 15,
                        fontWeight: 300
                    }
                },
                {
                    props: {
                        variant: "h1"
                    },
                    style: {
                        fontSize: 16,
                        fontWeight: 900,
                        color: '#008000',
                    }
                },
            ]
        }
    }
})

const oneTimePurchaseItems = ['learn'];

export default function SubscriptionDetails() {
    const [priceStatuses, setPriceStatuses] = useState<any>();
    const [upcomingInvoice, setUpcomingInvoice] = useState<any>();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    const [deactivateSubscriptionProduct, deactivateProductLoading] = useHttpsCallable(
        firebase_functions,
        'deactivateSubscriptionProduct'
    );

    const [cancelScheduleItem, cancelScheduleItemLoading] = useHttpsCallable(
        firebase_functions,
        'cancelScheduleItem'
    );

    const [cancelSubscriptionDeactivation, cancelDeactivationLoading] = useHttpsCallable(
        firebase_functions,
        'cancelSubscriptionDeactivation'
    );

    const [getPriceStatuses, getPriceStatusesExecuting, getPriceStatusesError] = useHttpsCallable(
        firebase_functions,
        'getPriceStatuses'
    );

    useEffect(() => {
        _getPriceStatuses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLoading(deactivateProductLoading || cancelDeactivationLoading || cancelScheduleItemLoading)
    }, [deactivateProductLoading, cancelDeactivationLoading, cancelScheduleItemLoading])

    async function _getPriceStatuses() {
        const _priceStatuses = await getPriceStatuses({tradingHouse: 'greenchart'}); //Object.keys(subscriptionsObject?.tradingHouses)[0]
        if (_priceStatuses) {
            // @ts-ignore
            setPriceStatuses(_priceStatuses.data.priceStatuses);
            // @ts-ignore
            setUpcomingInvoice(_priceStatuses.data.upcomingInvoice);
        }
    }

    function determineButtonToDisplay(status: string, priceId: string, isSubItem: boolean) {
        if (deactivateProductLoading && cancelScheduleItemLoading && cancelDeactivationLoading) {
            return (<ProgressCircle/>);
        }

        else if (status === 'subscriptionCanceled' && priceId === priceMapper.GCSub && isSubItem) {
            return <Button onClick={async () => {
                await cancelSubscriptionDeactivation({ priceId: priceId, tradingHouse: 'greenchart'} );
                await _getPriceStatuses();
            }}>Reactive Subscription</Button>;
        }

        else if (status === 'active' && isSubItem) {

            return <Button onClick={async () => {
                await deactivateSubscriptionProduct({ priceId: priceId, tradingHouse: 'greenchart'}).then((res: any) => {
                    showMessage(res, 'Cancellation scheduled!', 'There was a problem canceling subscription item.');
                });
                await _getPriceStatuses();
            }}>Cancel Item</Button>;
        }

        else if (status === 'deactivatedWithParent' || !isSubItem) {
            return <></>;
        }

        else if (status === 'downgraded') {
            return cancelScheduleItemButton(status, priceId, 'cancel Downgrade');
        }

        else if (status === 'deactivated') {
            return cancelScheduleItemButton(status, priceId, 'reactivate');
        }
    }

    function cancelScheduleItemButton(status: string, priceId: string, statusText: string) {
        return <Button onClick={async () => {
            await cancelScheduleItem({ priceId,  tradingHouse: 'greenchart'}).then((res: any) => {
                showMessage(res, 'Subscription item schedule canceled!', 'There was a problem.');
            });
            await _getPriceStatuses();
        }}>{statusText}</Button>
    }

    function showMessage(response: any, successMessage: string, errorMessage: string) {
        if (response.data.status === 200) {
            setSnackbarSettings({severity: 'success', message: successMessage, autoHideDuration: 6000});
        } else {
            setSnackbarSettings({severity: 'error', message: errorMessage});
        }
    }

    function displayUpgradeButton(status: string, productType: string) {
        if (status === 'active' && !oneTimePurchaseItems.includes(productType)) return (<Button onClick={() => navigate(`/dashboard/marketplace?display=${productType}`)}>Upgrade</Button>);
    }

    function displayAdditionalInformation(status: any) {
        if (status.downgradingTo) {
            return displayDowngradeInformation(status.cancellationDate, status.downgradingTo);
        }
        return displayCancellationDate(status.cancellationDate, status.status);
    }

    function displayCancellationDate(cancellationDate: number, status: string) {
        const displayCancellationDateStatuses = ['downgraded', 'subscriptionCanceled', 'deactivated', 'deactivatedWithParent'] // todo: move to a map
        if (cancellationDate && displayCancellationDateStatuses.includes(status)) {
            return `Scheduled to cancel on ${(convertToDate(cancellationDate))}`;
        }
    }

    function displayDowngradeInformation(cancellationDate: number, price: any) {
        if (price) {
            return `Downgrading to ${price.nickname}. Effective ${(convertToDate(cancellationDate))}`;
        }
    }

    function separateSections(priceStatuses: any[]) {
        const sections: {subscriptions: any[], addOns: any[], courses: any[]} = {
            subscriptions: [],
            addOns: [],
            courses: [],
        }

        priceStatuses.forEach((priceStatus: any) => {
            const {subscriptionType} = priceStatus.item.price.product.metadata;
            if (subscriptionType === 'membership' || subscriptionType === 'subscription') {
                sections.subscriptions.push(priceStatus);
            } else if (subscriptionType === 'course') {
                sections.courses.push(priceStatus);
            } else {
                sections.addOns.push(priceStatus);
            }
        })

        return sections;
    }

    function promoDurationText(promoDuration: number) {
        if (promoDuration) return ` (Trial period: ${promoDuration} days)`
        else return '';
    }

    function showSubscriptionObject(status: any) {
        let upcomingInvoiceItem = upcomingInvoice ? upcomingInvoice.lines.data.find((invoiceItem: any) => {
            return invoiceItem.price.product === status.item.price.product.id
        }) : null

        return (
            <Grid pb={2} sx={{ display: 'flex', justifyContent: 'space-between'}}>
                <Box pl={2} pr={8}>
                    <Typography variant='h5'>
                        <SubscriptionTitle status={status}/>
                        {promoDurationText(status.item.metadata?.promoDuration)}
                    </Typography>
                    <RenewalDate
                        upcomingInvoice={upcomingInvoice}
                        upcomingInvoiceItem={upcomingInvoiceItem}
                    />
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '260px'}}>
                        <Typography variant='h4'>
                            {displayAdditionalInformation(status)}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                        <Typography variant='h6' align='right'>
                            {`Purchased on ${convertToDate(status.item.created)}`}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {displayUpgradeButton(status.status, status.item.price.product.metadata.productType)}
                        {determineButtonToDisplay(status.status, status.item.price.id, checkIfSubItem(status.item.id))}
                    </Box>
                </Box>
            </Grid>
        )
    }

    function checkIfSubItem(id: string): boolean {
        return id.includes("si");
    }

    function showSubscriptions(statuses: any) {
         return (<>
             {statuses.map((_:any, index:number) => {
                    return (
                        <div key={index}>
                            <>{showSubscriptionObject(statuses[index])}</>
                </div>)
             })}
        </>)
    }

    function showSection(sectionContent: any, title: string) {
        if (sectionContent.length) {
            return (<>
                <Box pb={1}>
                    <Typography variant={'h1'}>{title}</Typography>
                </Box>
                {showSubscriptions(sectionContent)}
            </>)
        }
    }

    // function showSubscriptionsSection(subscriptions: any) {
    //     if (subscriptions.length) {
    //         return (<>
    //                 <Box pb={1}>
    //                     <Typography variant={'h1'}>Subscriptions</Typography>
    //                 </Box>
    //                 {showSubscriptions(subscriptions)}
    //             </>)
    //     }
    // }
    //
    // function showAddOnSection(addOns: any) {
    //     if (addOns.length) {
    //         return (<>
    //             <Box pb={1}>
    //                 <Typography variant={'h1'}>Add Ons</Typography>
    //             </Box>
    //             {showSubscriptions(addOns)}
    //         </>)
    //     }
    // }


    function showDetails() {
        if (!priceStatuses && getPriceStatusesExecuting) {
            return (<ProgressCircle/>);
        } else {
            const sections = separateSections(priceStatuses);
            return (
                <>
                    <Box>
                        {showSection(sections.subscriptions, 'Subscriptions')}
                        {showSection(sections.addOns, 'Add Ons')}
                        {showSection(sections.courses, 'Courses')}
                    </Box>
                </>
            )

        }
    }

    if (getPriceStatusesExecuting || loading) {
        return (<ProgressCircle/>);
    }

    if (getPriceStatusesError) {
        return <>There was an error</>
    }

    if (!getPriceStatusesExecuting && !getPriceStatusesError && priceStatuses) {
        return (

            <ThemeProvider theme={theme}>
                {showDetails()}
            </ThemeProvider>

        )
    }

    return (

        <></>

    )
}
