import {useCallback} from 'react';

const useChangeURLWithoutRedirect = (): ((path: string) => void) => {
    return useCallback((path: string) => {
        const urlParams = new URLSearchParams(window.location.search);
        const queryString = urlParams.toString();

        const newUrl = `${path}?${queryString}`;

        window.history.pushState(null, '', newUrl);
    }, []);
};

export default useChangeURLWithoutRedirect;