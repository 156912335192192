import useUserDetails from "../../../../../common/hooks/useUserDetails";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {collection} from "firebase/firestore";
import {firebase_firestore} from "../../../../../config/firebaseConfig";
import useFindProductId from "./useFindProductId";

export default function useGenerateCurrentProductsList() {
    const {userDetails, user} = useUserDetails();
    const [subscriptionsObject] = useCollectionData(collection(firebase_firestore, `usermeta/${user?.uid}/subscriptions`));
    const activeProducts: any = {};
    const findProductId = useFindProductId();

    return (prices: any) => {
        if (subscriptionsObject) {
            const tradeHouse = subscriptionsObject?.find((th: any) => {
                return th.tradingHouse === 'greenchart'
            });

            prices.forEach((price: { priceId: string; }) => {
                const isSubscribed = tradeHouse?.products?.some((product: { priceId: any; metadata: { price: any; }; }) =>
                    product.priceId === price.priceId || price.priceId === product.metadata?.price
                );
                const isCourseOwned = userDetails?.products?.courses?.some((course: { priceId: any; }) =>
                    course.priceId === price.priceId
                );

                if (isSubscribed || isCourseOwned) {
                    const productId = findProductId(price.priceId);
                    if (productId) {
                        activeProducts[productId] = price.priceId;
                    }
                }

            });
        }
        return activeProducts;
    }
}