import React, { useState } from 'react';
import { Button, TextField, Grid, Typography } from '@mui/material';
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { firebase_functions } from "../../config/firebaseConfig";

type GiftSubscriptionFormProps = {
    promoPriceId: string;
};

const GiftSubscriptionForm: React.FC<GiftSubscriptionFormProps> = (props: { promoPriceId: string }) => {
    const [email, setEmail] = useState<string>('');
    const [months, setMonths] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const { promoPriceId } = props;

    const [giftSubscriptionItem] = useHttpsCallable(
        firebase_functions,
        'giftSubscriptionItem'
    );

    const giftSubscription = async () => {
        setLoading(true);

        try {
            const result: any = await giftSubscriptionItem({ email, months, promoPriceId });
            setMessage(result.data.message);
        } catch (error) {
            console.error('Error gifting subscription:', error);
            setMessage('Failed to gift subscription.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
                <Typography variant="h6">Gift a Subscription (SS Pro)</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Recipient's Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Duration (Months)"
                    type="number"
                    variant="outlined"
                    value={months}
                    onChange={(e) => setMonths(parseInt(e.target.value) || 1)}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={giftSubscription}
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Gift Subscription'}
                </Button>
            </Grid>
            {message && (
                <Grid item xs={12}>
                    <Typography variant="body1">{message}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default GiftSubscriptionForm;
