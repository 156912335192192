import React from 'react';
import {Box} from "@mui/material";
import NestedList from "../../../components/NestedList";

function gtfStarterFeatures() {
    const featureList = [
        {
            feature: 'Charting Platform',
            listItems: [
                '4800+ live market symbols (stocks, crypto, FOREX, CFDs) to analyze',
                'Simple Drawing and Measuring tools',
                'Replay historical price movements',
                'Use Trade Simulator to practice trading in the live market - no broker account needed!',
                'DayTradeAI - AI Coaching Tool - ask any question, anytime',
            ]
        },
    ];

    return (
        <Box sx={{
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            lineHeight: "1.5",
            letterSpacing: "0.00938em",
            fontSize: "16px",
            alignItems: "left",
            margin: "0px"
        }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <NestedList items={featureList} />
        </Box>
    );
}

export default gtfStarterFeatures;