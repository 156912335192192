import {useState, useCallback, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../config/firebaseConfig";
import {useMarket} from "../../common/contexts/MarketContext";
import {GeneralContext} from "../../common/contexts/GeneralContext";

function useCreateStripeSubscription(processOneTimePurchaseItems: any) {
    const [snackbarSettings, setSnackbarSettings] = useState({ severity: '', message: '' });
    const navigate = useNavigate();
    const { dispatch } = useMarket(); // Assuming useMarket is a custom hook for your context
    const { trialDays } = useContext(GeneralContext);

    const [createIntent] = useHttpsCallable(
        firebase_functions,
        'createStripeGreenChartSubscriptionIntent'
    );

    const createStripeSubscriptionWithPaymentMethod = useCallback(async (paymentMethod: string, priceIds: any[], coupon?: string) => {
        let recurringPrices;

        if (paymentMethod) {
            let clearInvoices = true;

            if (priceIds && priceIds.some((priceId: any) => priceId.type === 'one_time')) {
                clearInvoices = false;
                recurringPrices = await processOneTimePurchaseItems(priceIds);
            }

            await createIntent({
                products: recurringPrices?.recurringPayments || priceIds,
                tradingHouse: 'greenchart',
                paymentMethod: paymentMethod,
                coupon,
                clearItems: clearInvoices,
                trialDays,
            });

            // clearCoupon(); // Uncomment if you have logic to clear the coupon
            setSnackbarSettings({ severity: 'info', message: "Processing purchase..." });

            dispatch({ type: 'SET_PRODUCT_DETAILS', payload: null });
            dispatch({ type: 'SET_PRODUCTS', payload: null });
            navigate(`/processing-order`, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, dispatch]);

    // ...other logic and state

    return { createStripeSubscriptionWithPaymentMethod, snackbarSettings };
}

export default useCreateStripeSubscription;
