
export default function MarketPlaceItemPair(props: {annual: any, monthly: any, showAnnual: boolean}) {
    const {annual, monthly, showAnnual} = props;

    if (showAnnual) {
        return (<>
            {annual ? annual : ''}
        </>)
    } else {
        return (<>
            {monthly}
        </>)
    }
}