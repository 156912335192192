import changeToPriceString from "./changeToPriceString";
import {useMarket} from "../../../../../common/contexts/MarketContext";
import createPriceArray from "../../../../pages/app/marketplace/helpers/createPriceArray";
import {useSearchParams} from "react-router-dom";


export default function useGetItemPrice( ) {
    const { state } = useMarket();
    const [searchParams] = useSearchParams();
    const prices = createPriceArray(state, searchParams);
    const { state: { coupon, products }, applyCouponToPrice, checkCouponAppliesTo } = useMarket();

    return (product: any) => {
        if (!prices || !product) return;
        const productPriceInfo = products[product.productId]?.[product.priceId];
        if (!productPriceInfo) return;

        let price = productPriceInfo.proratedPrice || productPriceInfo.price;
        if (coupon && checkCouponAppliesTo(prices[0].productId)) {
            price = applyCouponToPrice(price);
        }
        return changeToPriceString(price);
    };
}