import {useLocation} from 'react-router-dom'; // Import useLocation if you're using React Router
import {priceMapper} from "../helpers/priceMapper";

function useItemsFromUrl() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    return () => {
        let priceIds: { priceId: string; quantity: number; }[] = [];
        searchParams.get('items'); // This line seems to do nothing in your original function. You might want to revise it.
        searchParams.forEach(item => {
            if (priceMapper[item]) {
                priceIds.push({priceId: priceMapper[item], quantity: 1});
            }
        });
        return priceIds;
    };
}

export default useItemsFromUrl;
