import {useState} from "react";
import {Box, Button, TextField} from "@mui/material";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {firebase_functions} from "../../config/firebaseConfig";

export default function PromoHelper() {
    const [emailList, setEmailList] = useState('');
    const [priceId, setPriceId] = useState('');
    const [duration, setDuration] = useState(30);

    const [giveAccountsPromo] = useHttpsCallable(
        firebase_functions,
        'giveAccountsPromo'
    );

    function createPromoOnAccounts() {
        void giveAccountsPromo({
            emailList: emailList.split(' '),
            priceId: priceId,
            duration: duration,
        })
    }

    return (<Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '400px',
    }}
    >
        <TextField
            id='emailListField'
            label='List of Emails'
            variant='outlined'
            onChange={e => setEmailList(e.target.value)}
        />
        <TextField
            id='emailListField'
            label='Product ID for promo'
            variant='outlined'
            onChange={e => setPriceId(e.target.value)}
        />
        <TextField
            id='emailListField'
            label='Duration in days of promo'
            variant='outlined'
            type='number'
            onChange={e => setDuration(Number(e.target.value))}
        />
        <Button
            onClick={() => createPromoOnAccounts()}
        >Submit</Button>
    </Box>)
}
