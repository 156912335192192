import React, {useEffect, useState} from "react";
import {Grid, Box, Typography, Divider, createTheme, ThemeProvider} from "@mui/material";
import {useApplyCouponToPrice} from "../../common/hooks/useApplyCouponToPrice";
import {useMarket} from "../../common/contexts/MarketContext";

const theme = createTheme({
    components: {
        MuiTypography: {
            variants: [
                {
                    props: {
                        variant: "h5"
                    },
                    style: {
                        fontSize: 16,
                    }
                },
            ]
        }
    }
})

export default function CheckoutItems(props: {itemsData: any, oneTimePurchaseItems: any}) {
    const {itemsData, oneTimePurchaseItems} = props;
    const [accumulatedValue, setAccumulatedValue] = useState(0);

    const applyCouponToPrice = useApplyCouponToPrice();

    const { state } = useMarket();
    const coupon = state.coupon;

    useEffect(() => {
        calculateTotalAccumulatedPrice();
    })

    function calculateTotalAccumulatedPrice() {
        let totalPrice = 0;

        const data = oneTimePurchaseItems ? oneTimePurchaseItems.concat(itemsData) : itemsData;

        data.forEach((item:any) => {
            if (!item.metadata.promoDuration) {
                if (item.amount) {
                    totalPrice += item.metadata.couponApplied ? item.amount/100 : (+applyCouponToPrice(item.amount)/100) * item.quantity;
                } else if (item.price) {
                    // @ts-ignore
                    totalPrice += coupon ? applyCouponToPrice(item.price)/100 * item.quantity : item.price/100 * item.quantity;
                }
            }
        })
        return setAccumulatedValue(totalPrice);
    }

    function calculateAmountOfItems() {
        let amountOfItems = 0;

        itemsData.forEach((item:any) => {
            amountOfItems += +item.quantity
        })

        if (oneTimePurchaseItems?.length) {
            oneTimePurchaseItems.forEach((item:any) => {
                amountOfItems += +item.quantity
            })
        }

        return amountOfItems;
    }

    function displayFreeTrial(item: any) {
        if (item.metadata.promoDuration) {
            return (
                <Grid>
                    <Box>
                        <Typography
                            sx={{
                                color: '#00bc13',
                            }}
                        >
                            A {item.metadata.promoDuration} day free trial has been applied to this order!
                        </Typography>
                    </Box>
                </Grid>
            );
        }
    }

    function displayCouponApplied() {
        if (!coupon) return (<></>);

        return (<Typography
            sx={{
                color: '#00bc13',
            }}
        >A coupon has been applied to this order!</Typography>)
    }

    function renderFreeTrialEndDate(item: any) {
        if (item.metadata.promoDuration) {
            const days = item.metadata.promoDuration

            const trialDate = getStartDatetime(days);

            return(
                <Box>
                  <Typography sx={{fontWeight: 300}}>
                      <>Your free trial is scheduled to end on {trialDate}</>
                  </Typography>
                </Box>
            );
        }

        function getStartDatetime(daysToAdd: number) {
            const days = daysToAdd*24*60*60*1000;
            // const days = 2*60*1000 // TODO: testing the change in 2 minutes for annual

            const today = new Date();
            return (new Date(Math.floor((today.getTime()+days) / 1000) * 1000)).toLocaleDateString();
        }
    }

    function determineItemPlurality() {
        return calculateAmountOfItems() > 1 ? 'items' : 'item'
    }

    // TODO breakout into other component
    function displayRecurringPrice(item: any) {
        function couponAppliesToProduct(item: any) {
            if (!coupon || !coupon.coupon || !coupon.coupon.applies_to) return false;
            return coupon.coupon.applies_to.products.some((product: string) => product === item.product.id)
        }

        function discountApplied(price: number) {
            if (!coupon) return price;

            if (coupon.coupon.percent_off) {
                return price * ((100 - coupon.coupon.percent_off)/100);
            } else if (coupon.coupon.amount_off) {
                return price - coupon.coupon.amount_off;
            }

            return price;
        }

        function getTimeCouponAppliedFor() {
            if (coupon && coupon.coupon.duration === 'repeating') {
                return `for ${coupon.coupon.duration_in_months} months`;
            }

            return '';
        }

        if (coupon && couponAppliesToProduct(item)) {
            const oldPrice = (item.price / 100).toFixed(2);
            const newPrice = (discountApplied(item.price) / 100).toFixed(2);
            const amountOfTime = getTimeCouponAppliedFor();

            return (
                <Box display="flex" justifyContent="flex-start">
                    <Typography variant={'h5'} sx={{ paddingRight: '8px', color: '#808080' }}>
                        <del>${oldPrice} / {item.interval}</del>
                    </Typography>
                    <Typography variant={'h5'}>
                        ${newPrice} / {item.interval}
                    </Typography>
                    {/*// @ts-ignore*/}
                    <Typography variant={'h5'} pl={.6}>
                        {amountOfTime}
                    </Typography>
                </Box>
            )
        }

        return <Typography variant={'h5'}>${(item.price / 100).toFixed(2)} / {item.interval}</Typography>
    }

    // function displayItemPrice() {
    //
    //     return
    // }

    // function displayPrice(item: any) {
    //     if (item.metadata.promoDuration) {
    //         return (<Typography variant={'h5'}>${0}</Typography>);
    //     } else {
    //         return (<Typography variant={'h5'}>${item.price / 100 * item.quantity}</Typography>);
    //     }
    // }

    if ((itemsData && itemsData.length) || (oneTimePurchaseItems && oneTimePurchaseItems.length)) {
        return (<ThemeProvider theme={theme}>

            {itemsData.map((item: any) => {
                return (<Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                >
                    <Box key={item.name}

                    >
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'row',
                                }}
                            >
                                <Typography variant={'h5'}>{`${item.name || item.description} ${item.product.name}`}</Typography>
                                <Box sx={{float: 'right'}}>
                                    <Typography variant={'h5'}>Qty: {item.quantity}</Typography>
                                </Box>
                            </Box>
                            {displayRecurringPrice(item)}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            {displayCouponApplied()}
                            {displayFreeTrial(item)}
                        </Box>
                        {renderFreeTrialEndDate(item)}
                    </Box>
                </Box>);
            })}

            {oneTimePurchaseItems && oneTimePurchaseItems.map((item: any) => {
                // old price is dynamic based on if there was an invoice created that already applied the coupon
                const oldPrice = item.metadata.couponApplied ? item.metadata.oldPrice : String(item.amount);
                const newPrice = item.metadata.couponApplied ? item.amount : applyCouponToPrice(item.amount);
                return (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box key={item.id}>
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Typography variant={'h5'}>{`${item.description || item.price.product.name}`}</Typography>
                                    <Box sx={{float: 'right'}}>
                                        <Typography variant={'h5'}>Qty: {item.quantity}</Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="flex-start">
                                    <Typography variant={'h5'} sx={{ paddingRight: '8px', color: '#808080' }}>
                                        {coupon ? <del>${(oldPrice / 100).toFixed(2)}</del> : null}
                                    </Typography>
                                    <Typography variant={'h5'}>
                                        ${(+newPrice / 100).toFixed(2)}
                                    </Typography>
                                </Box>

                                {displayCouponApplied()}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {displayFreeTrial(item)}
                            </Box>
                            {renderFreeTrialEndDate(item)}
                        </Box>
                    </Box>
                );
            })}

            <Divider/>

            <Grid>
                <Box sx={{height: 'inherit', float: 'right'}}>
                    <Typography variant={'h5'}>Subtotal
                        ({calculateAmountOfItems()} {determineItemPlurality()}): <b>${accumulatedValue.toFixed(2)}</b>
                    </Typography>
                </Box>
            </Grid>

        </ThemeProvider>);
    } else return <></>;
};
