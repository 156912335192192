import {useContext, useEffect, useState} from "react";
import {TraderAccountContext} from "../../contexts/TraderAccountContext";
import {firebase_functions} from "../../../config/firebaseConfig";
import {Box, Button, Card, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddPhaseForm from "./AddPhaseForm";
import { useHttpsCallable } from "react-firebase-hooks/functions";

export default function TraderAccountSchedule() {
    const {traderAccount} = useContext(TraderAccountContext);

    const [schedule, setSchedule] = useState<any>(null);
    const [statusMessage, setStatusMessage] = useState<any>(null);
    const [scheduleModificationData, setScheduleModificationData] = useState<any>(null);
    const [products, setProducts] = useState<any>(null);
    const [selectedPrice, setSelectedPrice] = useState<any>(null);
// const [currentPhaseSelected, setCurrentPhaseSelected] = useState<any>(null); // the current phase being worked on
    const [saveInstructions, setSaveInstructions] = useState<{ index: number, type: 'add' | 'replace' }>({
        index: 0,
        type: 'add'
    }); // the current phase being worked on
    const [warning, setWarning] = useState<string | null>(null);
    const [addingItem, setAddingItem] = useState<string | null>(null); // the current phase being worked on

    const scheduleBehaviors: string[] = ['release', 'cancel'];

    const [getTraderAccountSchedule, loadingSchedule] = useHttpsCallable(
        firebase_functions,
        'getTraderAccountSchedule'
    );
    const [setTraderSchedule, sendingSchedule] = useHttpsCallable(
        firebase_functions,
        'setTraderSchedule'
    );

    useEffect(() => {
        getTraderAccountSchedule({stripeCustomerId: traderAccount.stripeCustomerId}).then((res: any) => {
            if (res?.data.message) {
                setStatusMessage(statusMessage);
            }

            if (res?.data.schedule && res?.data.subscription) {
                setSchedule(res.data.schedule);

                const productsSubset: any = {};
                for (const property in res.data.products) {
                    const isInPhase0 = res.data.schedule.phases[0].items.find((item: any) => item.price.product.id === property)
                    if (isInPhase0) productsSubset[property] = res.data.products[property];
                }
                setProducts(productsSubset);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function changeProductsPrice(price: any) {
        const items = [...scheduleModificationData.items];

        items.splice(items.findIndex((item: any) => item.price.id === price.id), 1, {price: price});
        setScheduleModificationData({...scheduleModificationData, items: items});
    }

    function deleteFromPhase(priceId: string) {
        const items = [...scheduleModificationData.items];
        const priceIdIndex = items.findIndex((item: any) => item.price.id === priceId);
        items.splice(priceIdIndex, 1);
        setAddingItem(null);
        setSelectedPrice(null);

        setScheduleModificationData({...scheduleModificationData, items: items});
    }

    function setNewEndDate(value: number) {
        setScheduleModificationData({...scheduleModificationData, end: value});
    }

    function setNewStartDate(value: number) {
        setScheduleModificationData({...scheduleModificationData, start: value});
    }

    function savePhase() {
        setWarning(null);
        setSelectedPrice(null);
        setAddingItem(null);

        if (scheduleModificationData.start === scheduleModificationData.end) {
            setWarning('schedule phase must be at least one day')
            return;
        }

        const scheduleModification = {...schedule};

        if (saveInstructions.type === 'add') {
            const phase = {
                end_date: (scheduleModificationData.end),
                start_date: (scheduleModificationData.start),
                items: scheduleModificationData.items
            };

            // change the dates of the surrounding phases
            if (scheduleModification.phases.length > saveInstructions.index + 1) {
                scheduleModification.phases.splice(saveInstructions.index + 1, 1, {
                    ...scheduleModification.phases[saveInstructions.index + 1],
                    start_date: scheduleModificationData.end
                });
            }

            scheduleModification.phases.splice(saveInstructions.index, 1, {
                ...scheduleModification.phases[saveInstructions.index],
                end_date: scheduleModificationData.start
            });

            // insert the new phase
            if (scheduleModification.phases.length <= saveInstructions.index + 1) {
                scheduleModification.phases.push(phase);
            } else {
                scheduleModification.phases.splice(saveInstructions.index + 1, 0, phase);
            }
        } else {
            scheduleModification.phases[saveInstructions.index].end_date = (scheduleModificationData.end);
            scheduleModification.phases[saveInstructions.index].start_date = (scheduleModificationData.start);
            scheduleModification.phases[saveInstructions.index].items = scheduleModificationData.items;
        }
        setSchedule(scheduleModification);
        setScheduleModificationData(null);
    }

    function renderEditButton(index: number, phase: any, startDate: any, endDate: any) {
        if (!index) return;

        return (<Button onClick={() => {
            setSaveInstructions({index: index, type: 'replace'});
            // setCurrentPhaseSelected({...phase});
            setScheduleModificationData({
                start: startDate.getTime() / 1000,
                end: endDate.getTime() / 1000,
                items: [...phase.items]
            });
        }}>Edit</Button>);
    }

    function showSchedulePhases(phase: any, index: number) {
        const startDate: Date = new Date(+phase.start_date * 1000);
        const endDate: Date = new Date(+phase.end_date * 1000);

        const upcomingStartDate = schedule.phases.find((schedulePhase: any) => {
            return schedulePhase.start_date === phase.end_date;
        });

        const nextPhaseStartDate = upcomingStartDate ? +upcomingStartDate.start_date : endDate.getTime() / 1000;

        return (
            <Box p={2} sx={{
                maxWidth: '300px',
                textAlign: 'center',
            }}>
                <Card variant="outlined" sx={{padding: '16px'}}>
                    <Box pb={1}>
                        {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                    </Box>
                    {phase.items.map((phase: any) => {
                        return showScheduleItemDetails(phase);
                    })}
                    {renderEditButton(index, phase, startDate, endDate)}
                    {showDeletePhaseButton(index)}
                </Card>

                <Box pt={4}>
                    <Button onClick={() => {
                        setSaveInstructions({index: index, type: 'add'});
                        // setCurrentPhaseSelected({...phase});
                        setScheduleModificationData({
                            start: endDate.getTime() / 1000,
                            end: nextPhaseStartDate,
                            items: [...phase.items]
                        });
                    }}>
                        <AddCircleIcon/>
                    </Button>
                </Box>
            </Box>
        );
    }

    function showDeletePhaseButton(index: number) {
        if (!index) return;

        return (<Button onClick={() => deletePhase(index)}>Delete Phase</Button>);
    }

    function deletePhase(index: number) {
        const phases = [...schedule.phases];

        setAddingItem(null);
        setSelectedPrice(null);
        setScheduleModificationData(null);

        phases.splice(index, 1);
        setSchedule({...schedule, phases});
    }

    function showScheduleItemDetails(phaseItem: any) {
        const name = `${phaseItem.price.product.name}: ${phaseItem.price.nickname}`

        return (
            <div>
                {name}
            </div>)
    }

    function showAddPhaseForm() {
        if (!scheduleModificationData) {
            return;
        }

        return (<AddPhaseForm
            start={scheduleModificationData.start}
            end={scheduleModificationData.end}
            items={scheduleModificationData.items}
            deleteItem={deleteFromPhase}
            saveModifications={savePhase}
            setNewEndDate={setNewEndDate}
            setNewStartDate={setNewStartDate}
            setSelectedPrice={setSelectedPrice}
            setAddingItem={setAddingItem}
            products={products}
        />);
    }

    function showProductList() {
        if (!addingItem) {
            return;
        }

        let productList = Object.keys(products);

        if (scheduleModificationData.items && scheduleModificationData.items.length) {
            // loop through the list of all values in the current phase
            for (const item of scheduleModificationData.items) {
                productList = productList.filter((product: string) => product !== item.price.product.id);
            }
        }

        return (<Box sx={{display: 'flex', flexDirection: 'column'}}>
            {productList.map((productId: string) => {
                return (<Button onClick={() => setSelectedPrice(products[productId][0])}>
                    {products[productId][0].product.name}
                </Button>)
            })}
        </Box>)
    }

    function showPriceList() {
        if (!selectedPrice) {
            return;
        }

        return (<Box sx={{display: 'flex', flexDirection: 'column'}}>
            {products[selectedPrice.product.id].map((price: any) => {
                return (<Button onClick={() => {
                    setSelectedPrice(null);
                    if (addingItem) {
                        const items = [...scheduleModificationData.items];
                        items.push({price: price});

                        setScheduleModificationData({...scheduleModificationData, items: items})
                        setAddingItem(null);
                    } else {
                        changeProductsPrice(price);
                    }
                }}>
                    {price.nickname} {annualText(price)}
                </Button>);
            })}
        </Box>)

        function annualText(price: any) {
            return price.metadata.monthlyEquivalent ? '(Annual)' : '';
        }
    }

    function showWarningMessage() {
        if (!warning) return;

        return <Typography
            sx={{
                color: 'red',
            }}
        >{warning}</Typography>
    }

    if (sendingSchedule) {
        return <Typography>Saving schedule...</Typography>
    }

    if (!schedule && loadingSchedule) {
        return <>loading...</>
    }

    if (!schedule && statusMessage) {
        return <>{statusMessage}</>
    }

    if (!schedule && !statusMessage) {
        return <>There was an issue.</>
    }
    return (<>
        <>{showWarningMessage()}</>
        <Box
            py={2}
        >
            <Button
                variant="contained"
                onClick={() => setTraderSchedule({
                    schedule: schedule,
                    stripeCustomerId: traderAccount.stripeCustomerId
                })}>Save Changes</Button>
        </Box>
        <Box mb={3} pt={2}>
            <FormControl fullWidth>
                <InputLabel id="behaviorLabel">End Behavior</InputLabel>
                <Select
                    label={'End Behavior'}
                    onChange={(event) => {
                        setSchedule({...schedule, end_behavior: event.target.value as string})
                    }}
                    value={schedule.end_behavior as unknown}
                >
                    {scheduleBehaviors.map((behavior: string) => {
                        return <MenuItem value={behavior}>{behavior}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Box>
        <Box sx={{
            display: 'flex',
            direction: 'row',
        }}>
            <Box sx={{marginBottom: '16px'}}>
                <div>
                    {schedule.phases.map((phase: any, index: number,) => {
                        return showSchedulePhases(phase, index);
                    })}
                </div>
            </Box>
            {showAddPhaseForm()}
            {showProductList()}
            {showPriceList()}
        </Box>
    </>);
}