import React, { useEffect, useRef } from "react";
import useTitle from "../../../common/hooks/useTitle";

function EconomicCalendar() {
    useTitle('Econ Calendar');
    const calendarRef = useRef<HTMLDivElement | null>(null);


    useEffect(() => {
        // Set background to black
        const rootEl = document.getElementById('root');
        if (rootEl) {
            rootEl.classList.add('black-bg');
        }

        if (calendarRef.current) {
            const script1 = document.createElement("script");
            script1.src = "https://www.cashbackforex.com/Content/remote/remote-calendar-widget.js";
            script1.onload = () => {
                if (calendarRef.current) {
                    const script2 = document.createElement("script");
                    script2.type = "text/javascript";
                    script2.innerText = `
                        RemoteCalendar({
                            "Lang":"en",
                            "DefaultTime": "today",
                            "DefaultTheme": "dark",
                            "Url":"https://www.cashbackforex.com",
                            "SubPath":"economic-calendar",
                            "IsShowEmbedButton":true,
                            "DefaultCountries":"us,eu,gb,au,ch,jp,ca",
                            "DefaultImpacts":"HIGH,MEDIUM",
                            "ContainerId":"economic-calendar-624636"
                        });
                    `;
                    calendarRef.current.appendChild(script2);
                }
            };
            calendarRef.current.appendChild(script1);
        }

        // Cleanup: Remove the scripts and reset the background.
        return () => {
            if (calendarRef.current) {
                while (calendarRef.current.firstChild) {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    calendarRef.current.removeChild(calendarRef.current.firstChild);
                }
            }

            // Reset the background
            if (rootEl) {
                rootEl.classList.remove('black-bg');
            }
        }
    }, []);

    return (
        <div id="economic-calendar-624636" ref={calendarRef}/>
    );
}

export default EconomicCalendar;
