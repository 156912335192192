import {TextField} from "@mui/material";

export default function FirstNameField(props: {setFirstName: (n: string) => void, required?: boolean}) {
    const {setFirstName, required} = props;

    return (
        <TextField
            required={required}
            label="First Name"
            variant="outlined"
            onChange={e => {setFirstName(e.target.value)}}
            inputProps={{
                style: {
                    padding: '16.5px 14px',
                }
            }}
            sx={{
                width: '100%'
            }}
        />
    );
}