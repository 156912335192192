import AnalyticCountCard from "../AnalyticCountCard";
import Box from "@mui/material/Box";
import * as React from "react";
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import {collectionGroup, orderBy, query} from "firebase/firestore";
import {firebase_firestore} from "../../../../config/firebaseConfig";
import BacktestEstimatorLineGraph from "./BacktestEstimatorLineGraph";

function BacktestDashboard() {
    const [backtests] = useCollectionDataOnce(query(collectionGroup(firebase_firestore, `backtests`), orderBy("system.credits")));

    if (!backtests) {
        return <div>Loading...</div>
    }

    return (
        <Box>


            <BacktestEstimatorLineGraph backtests={backtests}/>
            <Box display={"flex"}>

                <AnalyticCountCard  title={"Backtests ran"}
                                    listTotal={`${(backtests || []).length}`}
                />
                <AnalyticCountCard  title={"Used Credits"}
                                    listTotal={`${(backtests || []).map((backtest: any) => (backtest.system ? backtest.system.credits || 0 : 0)).reduce((a: any, b: any) => a + b, 0).toFixed(0)}`}
                />
                <AnalyticCountCard  title={"Total Costs"}
                                    listTotal={`$${(backtests || []).map((backtest: any) => (backtest.system ? backtest.system.cost || 0 : 0)).reduce((a: any, b: any) => a + b, 0).toFixed(2)}`}
                />
                <AnalyticCountCard  title={"Total compute"}
                                    listTotal={`${(backtests || []).map((backtest: any) => (backtest.system ? backtest.system.computeTime || 0 : 0)).reduce((a: any, b: any) => a + b, 0).toFixed(2)}`}
                />

            </Box>
        </Box>
    )
}

export default BacktestDashboard
