import {debounce, TextField} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import * as EmailValidator from "email-validator";

type emailTextFieldProps = {
    onChange: (email: string) => void,
    setIsEmailValid?: (isValid: boolean) => void,
    required?: boolean,
    errorMessaging?: boolean
}

export default function EmailTextField(props: emailTextFieldProps) {
    const {onChange, setIsEmailValid, required, errorMessaging = true} = props;

    const [email, setEmail] = useState('');
    const [isEmailValid, setEmailValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string>()

    const verifyEmail = useCallback((emailToVerify: string) => {
        (debounce((emailToVerify: string) => {
            setEmailValid(EmailValidator.validate(emailToVerify))
        }, 1200))(emailToVerify)}, []
    );

    useEffect(() => {
        if (email.trim() !== '') {
            verifyEmail(email)
        }
        onChange(email);
    }, [email, onChange, verifyEmail])

    useEffect(() => {
        if (!errorMessaging) return;

        if (isEmailValid) {
            setErrorMessage('')
        } else {
            setErrorMessage('Invalid email')
        }
        if (setIsEmailValid) setIsEmailValid(isEmailValid)
    }, [isEmailValid, setIsEmailValid, errorMessaging])

    return  <TextField id="emailTextField"
                       variant="outlined"
                       label='Email'
                       required={required}
                       error={!isEmailValid && errorMessaging}
                       helperText={errorMessage}
                       onChange={e => setEmail(e.target.value)}
                       sx={{
                           width: '100%'
                       }}
    />
}
