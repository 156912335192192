import React, {useState} from "react";
import TraderAccountTabs from "../components/traderAccounts/TraderAccountTabs";

export default function AccountDetails() {
    const [selectedComponent, setSelectedComponent] = useState(<></>);

    return (<>
        <TraderAccountTabs
            setComponent={setSelectedComponent}
        />
        { selectedComponent }
    </>)
}