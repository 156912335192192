import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Button, Card, CardActions, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";


export default function AnalyticCountCard(props: any) {
    const {title, listTotal, listCount, exportData} = props

    return <>
        <Box sx={{width: 275}}>
            <Card variant="outlined">
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {
                            listCount === undefined
                                ? <>{title}</>
                                : <>{parseFloat(((listCount/listTotal)*100).toFixed(1))}% of {title}</>
                        }
                    </Typography>
                        {
                            listCount === undefined
                                ? <>{listTotal}</>
                                : <Grid container gap={2} alignItems={"center"}>
                                    <Typography variant="h5" component="div">

                                        {listCount} / {listTotal}
                                    </Typography>
                                </Grid>
                        }
                </CardContent>
                <CardActions>
                    {
                        exportData ? <Button size="small" onClick={exportData}>Export List</Button> : <></>
                    }
                </CardActions>
            </Card>
        </Box>

    </>

}
