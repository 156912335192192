import {useRef, useState} from "react";
import {Button} from "@mui/material";
import {CSVLink} from "react-csv";
import * as React from "react";
import {AlertProductMap} from "../../common/helpers/AlertProductMap";

//{ children }: any,
export default function ExportDataButton(props: {filename: string, onclick: any}) {
    const {filename, onclick} = props;

    const [csvData, setcsvData] = useState<any>([[]]);
    const csvLinkEl = useRef<any>(null);

    function subtractDaysFrom(date: number, days: string): any {
        const dateObj = new Date(date * 1000);
        dateObj.setDate(dateObj.getDate() - (+days));
        return (dateObj.getTime() / 1000);
    }

    async function createCSVFile() {
        const data = await onclick();
        const headers = [
            'Email',
            'Status',
            'Package',
            'Trial Period',
            'Trial Start Date', // Start date for alerts package promo
            'Trial End Date', // End date for alerts package promo
            'Alerts Phone Activated',
            'Active Alerts Amount',
            'Alert Notifications Sent'
        ];

        const dataCSV = data.map((user: any) => {

            const alertProduct = user.subscriptions ? user.subscriptions.products.find((product: any) => {
                // @ts-ignore
                return product.prodId === AlertProductMap[process.env.REACT_APP_ENVIRONMENT];
            }) : undefined;

            return [
                user.email,
                user.tradingHouses.gtf ? user.tradingHouses?.gtf?.currentStatus.status : 'N/A',
                user.products ? user?.products.alerts.name : null,
                alertProduct ? alertProduct.metadata.promoDuration : null,
                alertProduct && alertProduct.metadata.promoDuration ? new Date(subtractDaysFrom(user.subscriptions.renewalDate, alertProduct.metadata.promoDuration) * 1000).toLocaleDateString() : null,
                user.subscriptions && alertProduct && alertProduct.metadata.promoDuration ? new Date(user.subscriptions.renewalDate * 1000).toLocaleDateString() : null,
                user.alertPhoneNumber ? (user.alertPhoneNumber.verified ? 'Y' : 'N') : 'N',
                user.activeAlerts ? user.activeAlerts : null,
                user.products ? user?.products.alerts.notifications - user?.products.alerts.notificationsRemaining : null,
            ];
        });

        setcsvData([
            headers,
            ...dataCSV
        ]);

        setTimeout(() => {
            csvLinkEl.current.link.click();
        })
    }

    //{children}
    return(<>
        <Button onClick={createCSVFile}>Export Member Data</Button>
        <CSVLink   filename={`${filename}.csv`}
                   data={csvData}
                   style={{visibility: "hidden"}}
                   asyncOnClick={true}
                   ref={csvLinkEl}/>
    </>)
}