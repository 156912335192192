import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { Button, Dialog} from "@mui/material";
import React from "react";
import ProgressCircle from "../../../common/components/ProgressCircle";

export default function MarketplaceDialog(props: {
    isOpen: boolean,
    onClose: any,
    cancelFunction: any,
    confirmFunction: any,
    title: string,
    processing: boolean,
    content?: any,
    confirmText?: string,
}) {
    const {isOpen, onClose, cancelFunction, confirmFunction, title, processing, content, confirmText} = props;
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={cancelFunction} >Back</Button>
                {
                    processing
                        ? <ProgressCircle paddingTop={'0%'} size={2} center={false}/>
                        : <Button onClick={() => confirmFunction()} autoFocus>
                            {confirmText ?? 'Confirm'}
                        </Button>
                }

            </DialogActions>
        </Dialog>
    )
}
