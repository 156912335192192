import React from 'react';
import { Dialog, Box, DialogContent, useMediaQuery, useTheme } from "@mui/material";
import ModalContent from "../ModalContent";
import logo from "../../../../assets/stacked_logo.png";
import useChangeURLWithoutRedirect from "../../../../common/hooks/useChangeURLWithoutRedirect";

interface ForgottenPasswordProps {
    onSwitchToLogin?: () => void;
}

const ForgottenPassword: React.FC<ForgottenPasswordProps> = ({ onSwitchToLogin }) => {
    const changeURLWithoutRedirect = useChangeURLWithoutRedirect();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box
            sx={{
                backgroundColor: "#02111b",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Dialog
                open={true}
                hideBackdrop={true}
                fullScreen={isMobile}
            >
                <Box
                    component="img"
                    sx={{
                        width: "200px",
                        alignSelf: "center",
                        cursor: "pointer",
                    }}
                    src={logo}
                    mt={3}
                    onClick={() => window.open("https://www.greenchart.com", "_blank")}
                />
                <DialogContent
                    sx={{
                        padding: 0,
                        minWidth: isMobile ? "auto" : "600px",
                    }}
                >
                    <ModalContent
                        state={'forgotPassword'}
                        onSwitchToLoginCallback={() => changeURLWithoutRedirect('/login')}
                        onSwitchToSignUpCallback={() => changeURLWithoutRedirect('/signup')}
                        onSwitchToForgotPasswordCallback={() => changeURLWithoutRedirect('/forgot-password')}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default ForgottenPassword;
