import { Box, Typography, Paper } from "@mui/material";
import { useEffect, useState } from "react";

export default function ProductFooter(props: { product: any }) {
    const { product } = props;
    const [footers, setFooters] = useState<string[]>([]);

    useEffect(() => {
        if (!product) return;
        extractFooters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product]);

    function extractFooters() {

        if (product && product.metadata) {
            const footerKeys = Object.keys(product.metadata).filter(key => key.startsWith('footer'));
            setFooters(footerKeys.map(key => product.metadata[key]));
        }
    }

    return (
        <Box sx={{ width: '100%', mt: 2 }}>
            {footers.map((footer, index) => (
                <Paper
                    elevation={0} // Set elevation to 0 to remove shadows
                    key={index}
                    sx={{
                        p: 2,
                        mb: 1,
                        backgroundColor: "rgb(255, 255, 255)",
                        color: "rgba(0, 0, 0, 0.87)",
                        borderRadius: "4px",
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        overflow: "hidden",
                        textAlign: "left"
                    }}
                >
                    <Typography variant="body1">
                        {footer}
                    </Typography>
                </Paper>
            ))}
        </Box>
    );
}
