import { useEffect, useContext } from "react";
import useUserDetails from "./useUserDetails";
import { GeneralContext } from "../contexts/GeneralContext";
import {StatusesMapper} from "../../landing/helpers/statusesMapper";

const useHasGTF = () => {
    const { userDetails } = useUserDetails();
    const { hasGTF, setHasGTF } = useContext(GeneralContext);

    useEffect(() => {
        if (userDetails && userDetails.tradingHouses) {
            const hasGTFValue = !!userDetails.tradingHouses.gtf && StatusesMapper.gtfProductStatuses.includes(userDetails.tradingHouses.gtf.currentStatus.status)
            setHasGTF(hasGTFValue);
        }
    }, [userDetails, setHasGTF]);

    return hasGTF;
}

export default useHasGTF;

export const useHasFreeGTF = () => {
    const { userDetails } = useUserDetails();
    const { hasFreeGTF, setHasFreeGTF } = useContext(GeneralContext);

    useEffect(() => {
        if (userDetails && userDetails.tradingHouses) {
            const isFree = userDetails.tradingHouses.gtf && userDetails.tradingHouses.gtf.currentStatus.status === "free";
            setHasFreeGTF(isFree);
        }
    }, [userDetails, setHasFreeGTF]);

    return hasFreeGTF;
}
