import { useAuthState } from "react-firebase-hooks/auth";
import { firebase_auth, firebase_firestore } from "../../config/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { useContext, useEffect } from "react";
import {GeneralContext} from "../contexts/GeneralContext";

const useUserDetails = () => {
    const [user, loadingUser, errorLoadingUser] = useAuthState(firebase_auth);

    const {
        userDetails,
        setUserDetails,
        userDetailsLoading,
        setUserDetailsLoading,
        userDetailsError,
        setUserDetailsError
    } = useContext(GeneralContext);

    useEffect(() => {
        const fetchUserDetails = async () => {
            if (user && !userDetails && !userDetailsLoading) {
                setUserDetailsLoading(true);
                try {
                    const userDoc = await getDoc(doc(firebase_firestore, `usermeta/${user.uid}`));
                    if (userDoc.exists()) {
                        setUserDetails(userDoc.data());
                    } else {
                        setUserDetailsError(new Error("User details not found"));
                    }
                } catch (error) {
                    setUserDetailsError(error);
                } finally {
                    setUserDetailsLoading(false);
                }
            }
        };

        void fetchUserDetails();
    }, [user, userDetails, userDetailsLoading, setUserDetails, setUserDetailsLoading, setUserDetailsError]);

    return { user, loadingUser, errorLoadingUser, userDetails, loadingUserDetails: userDetailsLoading, userDetailsError };
};

export default useUserDetails;
