import {Box, Tab, Tabs} from "@mui/material";
import React, {useState} from "react";
import SubscriptionDetails from "./SubscriptionsDetails";
import InvoicesDetails from "./InvoicesDetails";
import ProfileDetails from "./ProfileDetails";

export default function ProfileMenus(props: any) {

    const profileMenus: string[] = ['Services', 'Invoices'].concat(props && props.additionalProfileMenus ? props.additionalProfileMenus : []);
    const [menu, setMenu] = useState<string | null>(profileMenus[0])
    const [value, setValue] = useState(0);

    const handleMenuChange = (
        event: React.SyntheticEvent,
        newMenu: number,
    ) => {
        setValue(newMenu);
        setMenu(profileMenus[newMenu]);
    };

    function displayContents() {
        if (menu === 'Services') {
            return <SubscriptionDetails/>
        } else if (menu === 'Invoices') {
            return <InvoicesDetails/>
        } else if (menu === "Profile") {
            return <ProfileDetails/>
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', minWidth: 'auto', overflowX: 'auto' }}>
            <Box>
                <Tabs
                    value={value}
                    onChange={handleMenuChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {profileMenus.map((menu:string) => {
                        return (<Tab key={menu} label={menu}/>)
                    })}
                </Tabs>
            </Box>
            <Box my={3}>
                {displayContents()}
            </Box>
        </Box>
    )
}


// {/*<ToggleButtonGroup*/}
// {/*    value={menu}*/}
// {/*    onChange={handleMenuChange}*/}
// {/*    exclusive*/}
// {/*>*/}
// {/*    {profileMenus.map((menu:string) => {*/}
// {/*        return (*/}
// {/*            <ToggleButton key={menu} value={menu}>*/}
// {/*                {menu}*/}
// {/*            </ToggleButton>*/}
// {/*        )*/}
// {/*    })}*/}
// {/*</ToggleButtonGroup>*/}