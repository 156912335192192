import { connect } from 'gc_utils/dist/firebase/FirebaseConnection';
import { getAuth } from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getDatabase } from "firebase/database";
// import { getAnalytics } from "firebase/analytics";
// import { getStorage } from "firebase/storage";

let firebaseInstance = process.env.REACT_APP_ENVIRONMENT === 'production' ? connect('PROD') : connect('DEV');

const firebase_auth = getAuth(firebaseInstance)
const firebase_firestore = getFirestore(firebaseInstance)
const firebase_functions = getFunctions(firebaseInstance)
const realtime_db = getDatabase(firebaseInstance)
// const firebase_analytics = getAnalytics(firebaseInstance)
// const firebase_storage = getStorage(firebaseInstance)

export { firebase_firestore, firebase_auth, firebase_functions, realtime_db};
