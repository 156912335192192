import {Breadcrumbs, Button, Typography} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React, {useContext} from "react";
import {BreadCrumbsHelperContext} from "../contexts/BreadCrumbsHelperContext";
import {useNavigate} from "react-router-dom";

export default function BreadCrumbsNavigation() {
    const {breadCrumbs, removeCrumbsUpTo} = useContext(BreadCrumbsHelperContext);
    const navigate = useNavigate();

    function buildBreadCrumbElements() {
        if (!breadCrumbs) return;

        return breadCrumbs.map((breadcrumb, index) => {
            const {name, location} = breadcrumb;

            if (index === breadCrumbs.length-1) {
                return (<Typography
                    sx={{
                        fontWeight: '500',
                        fontSize: 22,
                        height: "35px",
                        padding: 0,
                        display: "flex",
                        alignItems: "center",
                    }}
                    key={index}
                >
                    {capitalizeName(name)}
                </Typography>)
            } else {
                return <Button
                    onClick={() => navigateToPage(name, location)}
                    sx={{
                        padding: 0,
                        color: 'rgba(0, 0, 0, 0.6)',
                        textTransform: 'none',
                        fontWeight: '300',
                        fontSize: 18,
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                    }}
                    key={index}
                >
                    {capitalizeName(name)}
                </Button>
            }
        })
    }

    function navigateToPage(name: string, location: string) {
        removeCrumbsUpTo(name);
        navigate(location);
    }

    function capitalizeName(name: string) {
        // Adds a space between each capital letter
        const nameWithSpaces = name.replace(/([A-Z])/g, ' $1').trim()
        return nameWithSpaces.charAt(0).toUpperCase() + nameWithSpaces.slice(1);
    }

    return (
        <>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="large" sx={{ color: '#008000' }} />}
                aria-label="breadcrumb"
                sx={{height: "35px"}}
            >
                {buildBreadCrumbElements()}
            </Breadcrumbs>
        </>
    )
}
