import {useMarket} from "../../../../../common/contexts/MarketContext";
import {useNavigate} from "react-router-dom";
import {useCallback, useContext} from "react";
import {GeneralContext} from "../../../../../common/contexts/GeneralContext";
import useBuildPriceIds from "./useBuildPriceIds";
import {SnackbarHelperContext} from "../../../../../common/contexts/SnackbarHelperContext";
import {useDialog} from "../../../../../common/contexts/DialogContext";
import useFindProductId from "./useFindProductId";

export default function useUpgradeItem() {
    const navigate = useNavigate();
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);
    const { repId, trialDays } = useContext(GeneralContext);
    const priceIds = useBuildPriceIds();
    const findProductId = useFindProductId();
    const {
        state: {coupon, products, prorationDate},
        getProducts,
        createStripeGreenChartSubscriptionIntent,
        addInvoiceItems,
    } = useMarket();
    const {setOpenDialog} = useDialog();

    const splitIntoItemGroups = useCallback((priceIds: any[]) => {
        const oneTimePayments: any[] = [];
        const recurringPayments: any[] = [];

        priceIds.forEach((priceId: any) => {
            const productId = findProductId(priceId.priceId);
            const product = products[productId][priceId.priceId];

            if (product) {
                if (product?.type === 'one_time') {
                    oneTimePayments.push(product);
                } else if (product?.type === 'recurring') {
                    recurringPayments.push(product);
                }
            }
        })

        return {oneTimePayments, recurringPayments};
    }, [products, findProductId]);

    return async () => {
        try {
            const {oneTimePayments, recurringPayments} = splitIntoItemGroups(priceIds);

            if (oneTimePayments.length > 0) {
                await addInvoiceItems({
                    prices: oneTimePayments.map(({priceId}) => ({priceId, quantity: 1})),
                    payNow: true,
                    coupon,
                    repId
                });
            }

            if (recurringPayments.length === 0 && oneTimePayments.length > 0) {
                await getProducts();
                navigate('/processing-order');
                return;
            }


            if (recurringPayments.length > 0) {
                const response = await createStripeGreenChartSubscriptionIntent({
                    products: recurringPayments,
                    tradingHouse: 'greenchart',
                    prorationDate: prorationDate,
                    coupon,
                    trialDays
                });

                if (response?.data?.subscription?.stripeSubscriptionObject?.pending_update) {
                    setSnackbarSettings({
                        severity: 'error',
                        message: 'Purchase failed! There was a problem with your card!'
                    });
                } else {
                    setSnackbarSettings({
                        severity: 'success',
                        message: 'Item has been added to your account!',
                        autoHideDuration: 6000
                    });
                    navigate('/confirmation');
                }
            }
        } catch (error) {
            console.error('Error upgrading item:', error);
            setSnackbarSettings({
                severity: 'error',
                message: 'Upgrade failed. Please try again.',
                autoHideDuration: 6000
            });
        } finally {
             setOpenDialog('');
        }
    }
};
