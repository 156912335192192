import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import * as React from "react";
import {firebase_firestore, firebase_functions, realtime_db} from "../../../config/firebaseConfig";
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import {collection, query, where, deleteDoc} from "firebase/firestore";
import {ref, remove} from "firebase/database"
import TradesOutcomeDurationScatterAnalytics from "../analytics/usermeta/TradesOutcomeDurationScatterAnalytics";
import TradesSymbolPieAnalytics from "../analytics/usermeta/TradesSymbolPieAnalytics";
import {useObject, useObjectVal} from "react-firebase-hooks/database";
import {Button, Grid, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import {useContext, useState} from "react";
import {SnackbarHelperContext} from "../../../common/contexts/SnackbarHelperContext";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {isChartAccessStatus} from "../../../landing/helpers/statusesMapper";

export default function TraderMoreInfo(props: any) {
    const { trader, style } = props
    const navigate = useNavigate();

    const [tradingPlans, , , tradingPlansSnapshot, reloadTradingPlans] = useCollectionDataOnce(query(collection(firebase_firestore, `tradingPlans`), where("user.gcid", "==", trader.gcid)));
    const [liveTradeHistory] = useCollectionDataOnce(query(collection(firebase_firestore, `usermeta/${trader.gcid}/trading/account/tradeHistory`)));
    const [replayTradeHistory] = useCollectionDataOnce(query(collection(firebase_firestore, `usermeta/${trader.gcid}/trading/account/replayTradeHistory`)));
    const [gtfLastLogin] = useObjectVal(ref(realtime_db, `usermeta/${trader.gcid}/gtf/lastLogin`));
    const [greenchartLastLogin] = useObjectVal(ref(realtime_db, `usermeta/${trader.gcid}/greenchart/lastLogin`));
    const [patternAlertAcceptedSnapshot] = useObject(ref(realtime_db, `usermeta/${trader.gcid}/patternAlertAccepted`));
    const {setSnackbarSettings} = useContext(SnackbarHelperContext);

    const [authToken, setAuthToken] = useState<string>()
    const [showAuthToken, setShowAuthToken] = useState<boolean>()

    const [createToken, loadingCreateToken] = useHttpsCallable(
        firebase_functions,
        'createTokenCallable'
    );

    async function removeAlert(alertRef: any) {
        try {
            await deleteDoc(alertRef)
            setSnackbarSettings({severity: 'success', message: 'Alert deleted!', autoHideDuration: 6000})
            reloadTradingPlans()
        } catch (err: any) {
            console.log(err)
            setSnackbarSettings({severity: 'error', message: `Error deleting alert`, autoHideDuration: 6000})
        }
    }

    function renderTradingPlans() {
        if (!tradingPlans || !tradingPlans.length) {
            return
        }

        return <>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Typography variant="h6" gutterBottom component="div">
                    Trading Plans:
                </Typography>
                <Table size="small" aria-label="purchases">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Turned On</TableCell>
                            <TableCell>Delivery Type</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {tradingPlans.map((tradingPlan: any, index) => (
                            <TableRow key={tradingPlan.id}>
                                <TableCell>{tradingPlan.name }</TableCell>
                                <TableCell>{tradingPlan.active ? "Yes" : "No"}</TableCell>
                                <TableCell>{tradingPlan.alertTypes.toString()}</TableCell>
                                <TableCell>
                                    <Button onClick={() => removeAlert(tradingPlansSnapshot?.docs[index].ref)}>
                                        <ClearIcon/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </>
    }

    function renderAlertAccountInfo() {

        if (!trader.products || !trader.products.alerts) {
            return
        }

        return <>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Typography variant={"h6"}>Alert Package Info:</Typography>
                <Box sx={{pl: 1}}>
                    <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                        <Typography>{tradingPlans?.length || 0} / {trader.products.alerts.alertCount}</Typography>
                        <Typography variant={"subtitle1"}>Alerts</Typography>
                    </Box>
                    <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                        <Typography>{trader.products.alerts.notificationsRemaining} / {trader.products.alerts.notifications}</Typography>
                        <Typography variant={"subtitle1"}>Notifications Remaining</Typography>
                    </Box>
                </Box>

            </Box>
        </>

    }

    function renderBacktestInfo() {

        if (!trader.products || !trader.products.backtest) {
            return
        }
        
        return <>
            <Box sx={{display: "flex", flexDirection: "column"}}>
                <Typography variant={"h6"}>Backtest Package Info:</Typography>
                <Box sx={{pl: 1}}>
                    <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                        <Typography>{trader.products.backtest.accruedCost || 0} / {trader.products.backtest.maxCost}</Typography>
                        <Typography variant={"subtitle1"}>Credits Used</Typography>
                    </Box>
                    <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                        <Typography>{trader.products.backtest.recurringCost}</Typography>
                        <Typography variant={"subtitle1"}>Recurring Credits</Typography>
                    </Box>
                </Box>

            </Box>
        </>

    }

    function renderSubscriptionDetails() {
        if (!trader.subscription) {
            return;
        }

        return <>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant={"h6"}>Subscription Info:</Typography>
                <Box sx={{pl: 1, display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: '16px'}}>
                    {renderAlertsTrialEnds()}
                    <Typography> Renewal date:</Typography>
                    <Typography sx={{alignItems: 'left'}}>
                        { (new Date(trader.subscription.renewalDate * 1000)).toLocaleDateString() }
                    </Typography>

                    <Typography>Packages:</Typography>
                    <Typography sx={{alignItems: 'left'}}>
                        { trader.subscription.packages.join(',') }
                    </Typography>
                </Box>
            </Box>
        </>;

        function renderAlertsTrialEnds() {
            if (!trader.subscription.trialPeriod) return;

            return (<>
                <Typography>Alerts trial ends:</Typography>
                <Typography sx={{alignItems: 'left'}}>
                    { (new Date(trader.subscription.trialPeriod * 1000)).toLocaleDateString() }
                </Typography>
            </>)
        }
    }


    function renderLastLogin(label: string, datetime: any) {
        if (!datetime) {
            return
        }
        return <Typography variant={"body2"}>{label} last login: {new Date(datetime).toLocaleString()}</Typography>
    }

    async function removePatternAlertAccepted() {
        try {
            if (patternAlertAcceptedSnapshot?.ref) {
                await remove(patternAlertAcceptedSnapshot?.ref)
                setSnackbarSettings({severity: 'success', message: 'Pattern alert accepted deleted!', autoHideDuration: 6000})
            }
        } catch (err: any) {
            setSnackbarSettings({severity: 'error', message: `Error deleting.`, autoHideDuration: 6000})
        }
    }

    async function initToken(destination: string) {
        const token = await createToken({gcid: trader.gcid})
        if (token && token.data) {
            setAuthToken(`${token.data}`)
            let url = '';

            if (destination === 'dtu') {
                url = `https://indicators${trader.isBetaTester ? "-beta" : ""}.secureonlinedaytradinguniversity.com?authToken=${token.data}`
            } else if (destination === 'greenchart') {
                url = `https://app.greenchart.com?authToken=${token.data}`
            } else if (destination === 'member center') {
                url = `myaccount.greenchart.com?authToken=${token.data}`
            }

            if (url.length) {
                navigator.clipboard.writeText(url)
                setSnackbarSettings({severity: 'info', message: 'Copied to clipboard!', autoHideDuration: 6000})
            }
        }
    }

    return (<>
            <Box style={style}>
                <Box sx={{display: "flex", flexDirection: "column", gap: "24px"}}>
                    <Box>
                        <Box sx={{display: "flex", flexDirection: "row", }}>
                            <Typography variant={"h5"}>{trader.email}</Typography>
                            <Button onClick={() => navigate('/admin/accounts/details')}>Details</Button>
                        </Box>
                        {renderLastLogin("Day Trading University", gtfLastLogin)}
                        {renderLastLogin("GreenChart", greenchartLastLogin)}
                        <Grid container flexDirection={"row"} alignItems={"center"}>
                            <Typography variant={"body2"}>
                                Alert pattern accepted:
                                {patternAlertAcceptedSnapshot?.exists() ? ` ${new Date(patternAlertAcceptedSnapshot.val()).toLocaleString()}` : " Never"}
                            </Typography>
                            {
                                patternAlertAcceptedSnapshot?.exists() ? <Button><ClearIcon onClick={() => removePatternAlertAccepted()}/></Button> : <></>
                            }
                        </Grid>
                        <Stack direction="column" alignItems="flex-start">
                            {trader.tradingHouses.gtf
                                && isChartAccessStatus(trader.tradingHouses.gtf.currentStatus.status)
                                && <Button
                                sx={{paddingLeft: 0, width: 'auto', maxWidth: 'fit-content'}}
                                disabled={loadingCreateToken}
                                onClick={() => initToken('dtu')}
                            >
                                {loadingCreateToken ? "creating..." : 'Copy DTU Chart Link'}
                            </Button>}
                            {trader.tradingHouses.greenchart
                                && isChartAccessStatus(trader.tradingHouses.greenchart.currentStatus.status)
                                && <Button
                                sx={{paddingLeft: 0, width: 'auto', maxWidth: 'fit-content'}}
                                disabled={loadingCreateToken}
                                onClick={() => initToken('greenchart')}
                            >
                                {loadingCreateToken ? "creating..." : 'Copy GreenChart Direct Chart Link'}
                            </Button>}
                            <Button
                                sx={{ paddingLeft: 0, width: 'auto', maxWidth: 'fit-content' }}
                                disabled={loadingCreateToken}
                                onClick={() => initToken('member center')}
                            >
                                {loadingCreateToken ? "creating..." : 'Copy Member Center Link'}
                            </Button>
                            {authToken && (
                                <Button
                                    sx={{ paddingLeft: 0, width: 'auto', maxWidth: 'fit-content' }}
                                    onClick={() => setShowAuthToken(!showAuthToken)}
                                >
                                    {showAuthToken ? "Hide Token" : "Show Token"}
                                </Button>
                            )}
                            <Box sx={{ width: "750px", overflowWrap: "break-word" }}>
                                {showAuthToken && authToken ? authToken : ""}
                            </Box>
                        </Stack>

                    </Box>

                    {renderAlertAccountInfo()}
                    {renderBacktestInfo()}
                    {renderSubscriptionDetails()}
                    {renderTradingPlans()}
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <TradesOutcomeDurationScatterAnalytics tradeHistory={liveTradeHistory} title={"Live Trades: Duration - Profitability"}/>
                        <TradesOutcomeDurationScatterAnalytics tradeHistory={replayTradeHistory} title={"Replay Trades: Duration - Profitability"}/>
                    </Box>
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <TradesSymbolPieAnalytics tradeHistory={liveTradeHistory} title={"Symbol: Number of live trades"}/>
                        <TradesSymbolPieAnalytics tradeHistory={replayTradeHistory} title={"Symbol: Number of replay trades"}/>
                    </Box>

                </Box>

            </Box>
        </>)

}
