import {useEffect} from "react";


const useTitle = (title: string) => {
    useEffect(() => {

        // GreenChart •
        window.document.title = `${title} - GreenChart`;

        return () => {
            // set Back to default
            window.document.title = "GreenChart Member Center";
        }

    }, [title])
};

export default useTitle;
