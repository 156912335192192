import {createContext, useState} from "react";

interface breadCrumbsHelperContextInterface {
    breadCrumbs: {name: string, location: string}[],
    clearCrumbs: () => void,
    pushCrumb: (name: string, location: string, clear?: boolean) => void,
    pushCrumbs: (crumbs: {name: string, location: string}[], clear?: boolean) => void,
    removeLastCrumb: () => void,
    removeCrumbsUpTo: (name: string) => void,
}

// @ts-ignore
export const BreadCrumbsHelperContext = createContext<breadCrumbsHelperContextInterface>({} as breadCrumbsHelperContextInterface);

export function BreadCrumbHelper({children}: any) {
    const [breadCrumbs, setBreadCrumbs] = useState<{name: string, location: string}[]>([])

    function getCrumbsCopy() {
        return [...breadCrumbs];
    }

    function clearCrumbs() {
        setBreadCrumbs([]);
    }

    function pushCrumb(name: string, location: string, clear?: boolean) {
        const tempBreadCrumbs = clear ? [] : getCrumbsCopy();
        const crumbInBreadLocation = checkIfCrumbInBread(name);

        if (crumbInBreadLocation !== -1) {
            removeCrumbsUpTo(name);
        } else {
            tempBreadCrumbs.push({name: name, location: location});
            setBreadCrumbs(tempBreadCrumbs);
        }
    }

    function pushCrumbs(crumbs: {name: string, location: string}[], clear?: boolean) {
        const tempBreadCrumbs = clear ? [] : getCrumbsCopy();
        const lastCrumbName = crumbs[crumbs.length - 1].name;
        const crumbInBreadLocation = checkIfCrumbInBread(lastCrumbName);

        if (crumbInBreadLocation !== -1) {
            removeCrumbsUpTo(lastCrumbName);
        } else {
            for (const crumb in crumbs) {
                const {name, location} = crumbs[crumb];
                tempBreadCrumbs.push({name: name, location: location});
            }
            setBreadCrumbs(tempBreadCrumbs);
        }
    }

    function removeLastCrumb() {
        const tempBreadCrumbs = getCrumbsCopy();
        tempBreadCrumbs.pop();

        setBreadCrumbs(tempBreadCrumbs);
    }

    function removeCrumbsUpTo(name: string) {
        const tempBreadCrumbs = getCrumbsCopy();
        const crumbLocation = checkIfCrumbInBread(name);

        tempBreadCrumbs.length = crumbLocation + 1;
        setBreadCrumbs(tempBreadCrumbs);
    }

    function checkIfCrumbInBread(name: string): number {
        return breadCrumbs.findIndex((crumb: {name: string, location: string}) => {
            return crumb.name === name;
        });
    }

    // function getCurrentCrumbsName() {
    //     if (breadCrumbs.length === 0) {
    //         return null;
    //     }
    //
    //     return breadCrumbs[breadCrumbs.length - 1].name;
    // }

    return(
        <BreadCrumbsHelperContext.Provider
            value={{
                breadCrumbs: breadCrumbs,
                clearCrumbs: clearCrumbs,
                pushCrumb: pushCrumb,
                pushCrumbs: pushCrumbs,
                removeLastCrumb: removeLastCrumb,
                removeCrumbsUpTo: removeCrumbsUpTo,
        }}>
            {children}
        </BreadCrumbsHelperContext.Provider>
    )
}