import {createTheme} from "@mui/material";

export const MarketplaceItemsTheme = createTheme({
    components: {
        MuiTypography: {
            variants: [
                {
                    props: {
                        variant: "h3"
                    },
                    style: {
                        fontSize: 64,
                    }
                },
                {
                    props: {
                        variant: "h5"
                    },
                    style: {
                        fontSize: 28,
                        fontWeight: 300,
                    }
                },
                {
                    props: {
                        variant: "h4"
                    },
                    style: {
                        fontSize: 38,
                        fontWeight: 400,
                    }
                },
                {
                    props: {
                        variant: "h1"
                    },
                    style: {
                        fontSize: 20,
                    }
                },
                {
                    props: {
                        variant: "h2"
                    },
                    style: {
                        fontSize: 22,
                    }
                },
                {
                    props: {
                        variant: "h6"
                    },
                    style: {
                        fontSize: 24,
                    }
                },
                {
                    props: {
                        variant: "subtitle1"
                    },
                    style: {
                        fontSize: 16,
                    }
                },
            ]
        },
    }
})