import React from 'react';
import {Box} from "@mui/material";
import NestedList from "../../../components/NestedList";

function gtfProPlusFeatures() {
    const featureList = [
        {
            feature: 'Strategy Suite',
            listItems: [
                'Automated Backtester: 40 credits*',
                'Pattern Alerts: 50 custom alerts',
                'Alert Notifications: 1,000 per month',
                {
                    feature: 'Strategy Showcase',
                    listItems: [
                        'View/copy other users’ Patterns',
                        'View/Copy other traders’ Backtest Settings',
                        'Share Private Strategies - share your Private Strategies with other users'
                    ]
                }
            ]
        },
    ];

    return (
        <Box sx={{
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            lineHeight: "1.5",
            letterSpacing: "0.00938em",
            fontSize: "16px",
            alignItems: "left",
            margin: "0px"
        }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <NestedList items={featureList} />
        </Box>
    );
}

export default gtfProPlusFeatures;
