
export const StatusesMapper: {[status: string]: string[]} = {
    subscriptionStatuses: ['member', 'free', 'active', 'admin'],
    needsActionStatuses: ['trial', 'hold', 'cancelled', 'canceled', 'inactive', 'subscription_needed'],
    gtfProductStatuses: ['member', 'free', 'active', 'admin', 'trial'],
    trialStatuses: ['trial'],
    get hasAccessToCharts() {
        return [...this.subscriptionStatuses, ...this.trialStatuses];
    }
}

export function isChartAccessStatus(status: string) {
    return StatusesMapper.subscriptionStatuses.includes(status) || StatusesMapper.trialStatuses.includes(status);
}