import { createTheme } from "@mui/material";

export const buttonColor = '#9e9797';
export const hoverColor = '#18BB0E';

export const HeaderTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: buttonColor,
                    textTransform: 'none',
                    padding: '0px 20px',
                    fontSize: '16px',
                    transition: 'color 0.4s ease',
                    '&:hover': {
                        color: hoverColor,
                    }
                }
            }
        }
    }
});
