import {Box} from "@mui/material";
import React from "react";
import useGetProductDescription from "./utils/useGetProductDescription";
import useHasGTF from "../../../../common/hooks/useHasGTF";
export default function ProductDescription(){

    const getProductDescription = useGetProductDescription();
    const productDescription = getProductDescription();

    const hasGTF = useHasGTF();

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box
                sx={{
                    fontSize: '1.8rem',
                    fontWeight: 'semi-bold',
                    marginBottom: '1rem',
                    marginTop: '1rem',
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap'
                }}
            >
                {(hasGTF && productDescription.gtfHook) || productDescription?.hook}
            </Box>
            <Box
                sx={{
                    fontSize: '1rem',
                    marginBottom: '1rem',
                }}
            >
                {(hasGTF && productDescription.gtfFeatures) || productDescription?.features}
            </Box>
        </Box>
    )
}