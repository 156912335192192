import React from 'react';
import {Box, Divider} from "@mui/material";
import ProfileDetails from "../../components/profile/ProfileDetails";
import ProfileMenus from "../../components/profile/ProfileMenus";
import Footer from "../../../common/components/Footer";

export default function Profile() {
    return (<>

        <Box p={2} style={{minHeight: '85vh'}}>
            <Box sx={{ display: { xs: 'none', md: 'flex'}, flexDirection: 'row', flexWrap: 'nowrap'}} mx={4} gap={4}>
                <Box sx={{flexBasis: "400px"}}>
                    <ProfileDetails/>
                </Box>
                <Divider orientation="vertical" variant="middle" flexItem />
                <ProfileMenus/>
            </Box>

            <Box sx={{ display: { xs: 'block', md: 'none'}}} mx={4}>
                <ProfileMenus additionalProfileMenus={["Profile"]}/>
            </Box>
        </Box>
        <Footer/>
</>)
}
