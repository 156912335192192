import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import React from "react";

function NestedList({ items, isTopLevel = true }: { items: any[], isTopLevel?: boolean }) {
    const scrollStyle = isTopLevel ? {
        height: '320px', // Use 100% of the parent's height
        overflowY: 'auto'
    } : {};

    return (
        <Box sx={{...scrollStyle, backgroundColor: '#f8f8f8'}}>
            <List disablePadding
                  sx={{
                      paddingLeft: '12px',
                      listStyle: 'none',
                      '& > li': {
                          position: 'relative',
                          marginBottom: '12px',
                          '&::before': {
                              // content: isTopLevel ? '""' : '"✓"',
                              color: '#008000',
                              fontWeight: 'normal',
                              fontSize: '1em',
                              display: 'inline-block',
                              position: 'absolute',
                              left: '0px',
                              top: '10px'
                          },
                          '& ul': {
                              paddingLeft: '24px',
                              '& li': {
                                  marginBottom: '4px',
                                  position: 'relative',
                                  '&::before': {
                                      // content: 'attr(data-deep-level) == "1" ? "✓" : "•"',
                                      color: '#008000',
                                      fontWeight: 'normal',
                                      fontSize: '14px',
                                      display: 'inline-block',
                                      position: 'absolute',
                                      left: '-15px',
                                      top: '0'
                                  },
                                  '& ul': {
                                      paddingLeft: '24px',
                                      '& li': {
                                          '&::before': {
                                              // content: '"•"',
                                              color: '#008000',
                                              fontWeight: 'normal',
                                              fontSize: '14px',
                                              display: 'inline-block',
                                              position: 'absolute',
                                              left: '-15px',
                                              top: '0'
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }}
            >
                {items.map((item, index) => (
                    <ListItem
                        key={index} sx={{ padding: 0, display: 'block' }}>
                        {typeof item === 'string' ? (
                            <ListItemText primary={
                                <Typography variant="body1" component="span" sx={{ fontWeight: 'normal', fontSize: '16px', '&::before': { content: '"• "', color: 'green' } }}>
                                    {item}
                                </Typography>
                            } />
                        ) : (
                            <>
                                <ListItemText primary={
                                    <Typography variant="body1" component="span" sx={{ fontWeight: item.listItems ? 'bold' : 'normal', fontSize: item.listItems ? '16px' : '14px' }}>
                                        {item.feature}
                                    </Typography>
                                } />
                                {item.listItems && <NestedList items={item.listItems} isTopLevel={false} />}
                            </>
                        )}
                    </ListItem>
                ))}
            </List>
        </Box>
    );
}

export default NestedList;
