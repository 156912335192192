import React from "react";
import GreenChartStartPlusFeatures from "./products/strategySuite/greenchart/GreenChartStarterPlusFeatures";
import GreenChartProFeatures from "./products/strategySuite/greenchart/GreenChartProFeatures";
import GreenChartProPlusFeatures from "./products/strategySuite/greenchart/GreenChartProPlusFeatures";
import GreenChartStarterFeatures from "./products/strategySuite/greenchart/GreenChartStarter";
import GreenChartStarterPlusFeatures from "./products/strategySuite/greenchart/GreenChartStarterPlusFeatures";
import gtfStarterFeatures from "./products/strategySuite/gtf/gtfStarter";
import gtfStarterPlusFeatures from "./products/strategySuite/gtf/gtfStarterPlusFeatures";
import gtfProFeatures from "./products/strategySuite/gtf/gtfProFeatures";
import gtfProPlusFeatures from "./products/strategySuite/gtf/gtfProPlusFeatures";

interface FeaturesMap {
    [key: string]: React.ComponentType<any>;
}

// Assuming that 'price_trader', 'price_basic', and 'price_pro' are consistent identifiers across environments
const productFeaturesMap: FeaturesMap = {
    'greenchart Starter': GreenChartStarterFeatures,
    'greenchart Trial Plus': GreenChartStarterPlusFeatures, // TODO remove
    'greenchart Starter Plus': GreenChartStartPlusFeatures,
    'greenchart Pro' : GreenChartProFeatures,
    'greenchart Pro Plus' : GreenChartProPlusFeatures,

    'gtf Starter': gtfStarterFeatures,
    'gtf Trial Plus': gtfStarterPlusFeatures, // TODO remove
    'gtf Starter Plus': gtfStarterPlusFeatures,
    'gtf Pro' : gtfProFeatures,
    'gtf Pro Plus' : gtfProPlusFeatures,
};

export default function getFeatureByPriceId(priceName: string, tradingHouse: string | null | undefined): React.ComponentType<any> | undefined {
    if (!tradingHouse) return React.Fragment;

    const component = productFeaturesMap[`${tradingHouse} ${priceName}`];
    if (!component) {
        console.error(`No component found for price named ${priceName}.`);
        return React.Fragment;
    }

    return component;
}