import * as React from "react";
import {collection, collectionGroup, getDocs, query, where} from "firebase/firestore";
import {firebase_firestore, firebase_functions} from "../../config/firebaseConfig";
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import AnalyticCountCard from "../components/analytics/AnalyticCountCard";
import Divider from "@mui/material/Divider";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import ExportDataButton from "../components/ExportDataButton";
import {useHttpsCallable} from "react-firebase-hooks/functions";
import {useEffect, useState} from "react";
import BacktestDashboard from "../components/analytics/backtests/BacktestDashboard";
import BacktestUsermetaUsage from "../components/analytics/backtests/BacktestUsermetaUseage";


export default function Analytics() {

    const [gtfMembers] = useCollectionDataOnce(query(collection(firebase_firestore, `usermeta`), where("tradingHouses.gtf.currentStatus.status", 'in', ["member", "free", "trial"])));
    const [tradingPlans] = useCollectionDataOnce(query(collection(firebase_firestore, `tradingPlans`)));

    const [usermetaMembers, setusermetaMembers] = useState<any>([]);
    const [usermetaFree, setusermetaFree] = useState<any>([]);
    const [usermetaTrials, setusermetaTrials] = useState<any>([]);

    const [gcDirectCount, setGcDirectCount] = useState(0);

    useEffect(() => {
        if (!gtfMembers) {
            return
        }

        const members = gtfMembers.filter((member: any) => member.tradingHouses.gtf.currentStatus.status === "member");
        const free = gtfMembers.filter((member: any) => member.tradingHouses.gtf.currentStatus.status === "free");
        const trials = gtfMembers.filter((member: any) => member.tradingHouses.gtf.currentStatus.status === "trial");

        setusermetaMembers(members);
        setusermetaFree(free);
        setusermetaTrials(trials);

    }, [gtfMembers]);

    const [getGCDirectMembers] = useHttpsCallable(
        firebase_functions,
        'getGCDirectMembers'
    );

    useEffect(() => {
        getGCDirectMembers().then((result: any) => setGcDirectCount(result.data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function hasAlerts(trader: any) {
        if (!tradingPlans) {
            return
        }

        return tradingPlans.find(tradingPlan => tradingPlan.user.gcid === trader.gcid)
    }

    async function getUserData() {
        const usermeta = (await getDocs(query(collection(firebase_firestore, `usermeta`),
            where('tradingHouses.gtf.currentStatus.status', '==', 'active'),
        )));
        const usermetaTrials = (await getDocs(query(collection(firebase_firestore, `usermeta`),
            where('tradingHouses.gtf.currentStatus.status', '==', 'trial'),
        )));
        const usermetaMember = (await getDocs(query(collection(firebase_firestore, `usermeta`),
            where('tradingHouses.gtf.currentStatus.status', '==', 'member'),
        )));
        const usermetaFree = (await getDocs(query(collection(firebase_firestore, `usermeta`),
            where('tradingHouses.gtf.currentStatus.status', '==', 'free'),
        )));

        const subscriptions: any[] = [];
        (await getDocs(query(collectionGroup(firebase_firestore, 'subscriptions')))).forEach(subscription => {
            subscriptions.push(subscription.data());
        })

        async function getAccountDetails(user: any) {
                const userData = user.data();

                const alerts = await getDocs(query(collection(firebase_firestore, 'tradingPlans'), where('user.gcid', '==', userData.gcid), where('active', '==', true)));
                userData.activeAlerts = alerts.docs.length;

                userData.subscriptions = subscriptions.find((subscription: any) => {
                    return subscription.gcid === userData.gcid;
                });

                return userData;
        }

        async function mapUsermeta(request: any) {
            return await Promise.all(request.docs.map(async (user: any) => {
                return await getAccountDetails(user)
            }))
        }

        let allUsermeta: any[] = [];

        allUsermeta = allUsermeta.concat(await Promise.all(usermeta.docs.map(async (user) => {
            return await getAccountDetails(user)
        })));

        const finishedUsermetaCalls = await Promise.all([
            await mapUsermeta(usermeta),
            await mapUsermeta(usermetaTrials),
            await mapUsermeta(usermetaMember),
            await mapUsermeta(usermetaFree),
        ]);

        finishedUsermetaCalls.forEach(usermeta => {
            allUsermeta = allUsermeta.concat(usermeta);
        })


        return allUsermeta;

    }

    return (<>
        <ExportDataButton filename={`alerts_report_${new Date().toLocaleDateString()}`} onclick={getUserData}/>

        <Grid container flexDirection={"column"} gap={4}>
            <BacktestDashboard/>
            <Divider flexItem/>
            <Grid container flexDirection={"row"} gap={3}>
                <Box>
                    <AnalyticCountCard  title={"GTF Members w/ Alert Package"}
                                        listTotal={usermetaMembers.concat(usermetaFree).filter((trader: any) => trader.products && trader.products.alerts && trader.products.alerts.name && trader.products.alerts.name !== "Admin Access Alerts").length}
                    />
                    <AnalyticCountCard  title={"Package Members w/ Alert"}
                                        listTotal={usermetaMembers.concat(usermetaFree).filter((trader: any) => trader.products && trader.products.alerts && trader.products.alerts.name && trader.products.alerts.name !== "Admin Access Alerts").length}
                                        listCount={usermetaMembers.concat(usermetaFree).filter((trader: any) => trader.products && trader.products.alerts && trader.products.alerts.name && trader.products.alerts.name !== "Admin Access Alerts" && hasAlerts(trader)).length}
                    />
                    <AnalyticCountCard  title={"Package Members w/ Activated Phone Number"}
                                        listTotal={usermetaMembers.concat(usermetaFree).filter((trader: any) => trader.products && trader.products.alerts && trader.products.alerts.name && trader.products.alerts.name !== "Admin Access Alerts").length}
                                        listCount={usermetaMembers.concat(usermetaFree).filter((trader: any) => trader.products && trader.products.alerts && trader.products.alerts.name && trader.products.alerts.name !== "Admin Access Alerts" && hasAlerts(trader) && trader.alertPhoneNumber).length}
                    />
                </Box>
                <Divider orientation={"vertical"} flexItem/>
                <Box>
                    <AnalyticCountCard  title={"GTF Members w/o Alerts Package"}
                                        listTotal={usermetaMembers.filter((trader: any) => (!trader.products || !trader.products.alerts)).length}
                    />
                    <AnalyticCountCard  title={"Members w/ Free Alert"}
                                        listTotal={usermetaMembers.filter((trader: any) => (!trader.products || !trader.products.alerts)).length}
                                        listCount={usermetaMembers.filter((trader: any) => (!trader.products || !trader.products.alerts) && hasAlerts(trader)).length}
                    />
                    <AnalyticCountCard  title={"Members w/ Activated Phone Number"}
                                        listTotal={usermetaMembers.filter((trader: any) => (!trader.products || !trader.products.alerts) && hasAlerts(trader)).length}
                                        listCount={usermetaMembers.filter((trader: any) => (!trader.products || !trader.products.alerts) && hasAlerts(trader) && trader.alertPhoneNumber).length}
                    />
                </Box>
                <Divider orientation={"vertical"} flexItem/>
                <Box>
                    <AnalyticCountCard  title={"GTF Trial Members"}
                                        listTotal={usermetaTrials.length}
                    />
                    <AnalyticCountCard  title={"Trial Members w/ Free Alert"}
                                        listTotal={usermetaTrials.length}
                                        listCount={usermetaTrials.filter((trader: any) => hasAlerts(trader)).length}
                    />
                    <AnalyticCountCard  title={"Trial Members w/ Activated Phone Number"}
                                        listTotal={usermetaTrials.filter((trader: any) => hasAlerts(trader)).length}
                                        listCount={usermetaTrials.filter((trader: any) => hasAlerts(trader) && trader.alertPhoneNumber).length}
                    />
                </Box>
            </Grid>
            <Divider/>
            <Grid>
                <AnalyticCountCard  title={"Trialing Alerts Package"}
                                    listTotal={usermetaMembers.concat(usermetaFree).filter((trader: any) => trader.products && trader.products.alerts && trader.products.alerts.name && trader.products.alerts.name.endsWith("Promo")).length}
                />
                <AnalyticCountCard  title={"Purchased Alerts Package"}
                                    listTotal={usermetaMembers.concat(usermetaFree).filter((trader: any) => trader.products && trader.products.alerts && trader.products.alerts.name && trader.products.alerts.name !== "Admin Access Alerts" && !trader.products.alerts.name.endsWith("Promo")).length}
                />
            </Grid>
            <Divider/>
            <AnalyticCountCard  title={"GreenChart Direct members"}
                                listTotal={gcDirectCount}
            />

           <BacktestUsermetaUsage/>
        </Grid>
    </>)

}
