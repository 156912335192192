import Chart from "react-apexcharts";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function TradesOutcomeDurationScatterAnalytics(props: any) {
    const {tradeHistory, title} = props

    const [chartTradeHistoryData, setChartTradeHistoryData] = useState<any>([])

    useEffect(() => {

        if (!tradeHistory) {
            setChartTradeHistoryData([])
            return
        }

        const data = tradeHistory.map((trade: any) => ([(trade.exit - trade.entry) / 60000, trade.profit]))

        const profit = data.filter(([, profit]: any) => profit > 0)
        const loss = data.filter(([, profit]: any) => profit <= 0)

        setChartTradeHistoryData([profit, loss])

    }, [tradeHistory])

    const chartSettings = {

        series: [{
            name: `Profit (${(chartTradeHistoryData[0] && chartTradeHistoryData[0].length) || 0})`,
            data: chartTradeHistoryData[0]
        },
            {
                name: `Loss (${(chartTradeHistoryData[1] && chartTradeHistoryData[1].length) || 0})`,
                data: chartTradeHistoryData[1]
            },
        ],
        options: {
            chart: {
                height: 350,
            },
            xaxis: {
                tickAmount: 10,
                labels: {
                    formatter: function(val: any) {

                        val = parseInt(val)

                        if (`${val}`.length > 3) {
                            val = (val / 60).toFixed(0)
                            if (val <= 24) {
                                return `${val} hrs`
                            }

                            val = (val / 24).toFixed(0)

                            return `${val} days`
                        }

                        return `${val} mins`
                    }
                }
            },
            yaxis: {
                labels: {
                    formatter: function(val: any) {
                        return "$"+parseFloat(val).toFixed(2)
                    }
                }
            },
        },
    };

    function renderChart() {
        if (!chartTradeHistoryData || !chartTradeHistoryData.length) {
            return;
        }

        return (
            <Box sx={{width: "fit-content"}}>
                <Typography sx={{mr: 2, ml: 2}} variant={"h6"}>{title}</Typography>
                <Chart
                    options={chartSettings.options}
                    series={chartSettings.series}
                    type="scatter"
                    height={350}
                />
            </Box>
        )

    }


    return (
        <>
            {renderChart()}
        </>
    );

}
