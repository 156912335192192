import React from 'react';
import Typography from '@mui/material/Typography';

// Define the structure of the 'status' prop
type SubscriptionStatus = {
    item: {
        price: {
            product: {
                name: string;
            };
            nickname: string;
            metadata: {
                monthlyEquivalent?: boolean;
            };
        };
    };
};

// Define the props for the SubscriptionTitle component
type SubscriptionTitleProps = {
    status: SubscriptionStatus;
};
export default function SubscriptionTitle(props: SubscriptionTitleProps) {
    const { status } = props;

    // Extracting values from status
    const name = status.item.price.product.name;
    const subtitle = status.item.price.nickname;
    const yearly = status.item.price.metadata.monthlyEquivalent ? ' Yearly' : '';

    // Render the component
    return (
        <Typography component='div' variant='h5'>
            {name}: {subtitle}
            {yearly && (
                <Typography component='span' variant='caption' color='textSecondary' sx={{fontSize: '16px'}}>
                    {yearly}
                </Typography>
            )}
        </Typography>
    );
}
