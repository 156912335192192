import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Typography from "@mui/material/Typography";
import _duration from 'format-duration-time';

type DataPoint = {
    actualCost: number;
    credits: number;
    metadata: {
        patternName: string,
        dateRange: string
    }
};

type BacktestEstimatorScatterGraphProps = {
    backtests: any[];
};

const BacktestEstimatorScatterGraph: React.FC<BacktestEstimatorScatterGraphProps> = ({ backtests }) => {
    const [dataPoints, setDataPoints] = useState<DataPoint[]>([]);
    const [averageCostPerCredit, setAverageCostPerCredit] = useState<number | null>(null);

    useEffect(() => {
        let tempDataPoints: DataPoint[] = [];
        let totalCost = 0;
        let totalCredits = 0;

        backtests
            .filter(backtest => new Date(backtest.startTime) > new Date(2023, 10, 7) )
            .forEach(backtest => {
            if (!backtest.system || !backtest.system.credits) return;

            const cost = backtest.system.cost || 0;
            const creditsUsed = backtest.system.credits || 0;


            totalCost += cost;
            totalCredits += creditsUsed;


            const dateRange = `${new Date(backtest.backtestSettings.dateRange.startDate).toDateString()} - ${new Date(backtest.backtestSettings.dateRange.endDate).toDateString()} (${_duration(backtest.backtestSettings.dateRange.endDate - backtest.backtestSettings.dateRange.startDate).format('d[ days]')})`;

            tempDataPoints.push({ actualCost: cost, credits: creditsUsed, metadata: {patternName: backtest.patternName, dateRange: dateRange} });
        });

        const avgCostPerCredit = totalCredits !== 0 ? totalCost / totalCredits : 0;
        setAverageCostPerCredit(avgCostPerCredit);
        setDataPoints(tempDataPoints);
    }, [backtests]);

    return (
        <div>
            <Typography variant="h6">Cost per credit ${(averageCostPerCredit || 0).toFixed(9)}</Typography>
            <Chart
                options={{
                    chart: {
                        id: "credits-cost-scatter-chart",
                    },
                    markers: {
                        size: 6, // Adjust as needed for visibility
                    },
                    yaxis: {
                        labels: {
                            formatter: (value) => `$${value.toFixed(2)}`,
                        },
                        title: {
                            text: "Actual Cost"
                        }
                    },
                    xaxis: {
                        title: {
                            text: "Credits Used"
                        }
                    },
                    tooltip: {
                        enabled: true,
                        custom: function({ dataPointIndex }) {
                            // Access the specific data point using seriesIndex and dataPointIndex
                            const dataPoint = dataPoints[dataPointIndex];


                            // Customize and return the tooltip as you need
                            return `<div style="padding: 10px">
                                        <strong>Cost:</strong> $${(dataPoint.actualCost).toFixed(2)}<br/>
                                        <strong>Credits:</strong> ${dataPoint.credits}<br/>
                                        <strong>Pattern:</strong> ${dataPoint.metadata.patternName}<br/>
                                        <strong>Date range:</strong> ${dataPoint.metadata.dateRange}<br/>
                                        <!-- Add more customized data here -->
                                  </div>`;
                        }
                    }
                }}
                series={[
                    {
                        name: "Backtest cost",
                        data: dataPoints.map(item => [item.credits, item.actualCost])
                    },
                ]}
                type="scatter"
                width="75%"
            />
        </div>
    );
};

export default BacktestEstimatorScatterGraph;
