import { useState, useCallback } from 'react';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { firebase_functions } from '../../config/firebaseConfig';

interface IUserDetails {
    firstName: string;
    lastName: string;
    phoneNumber?: {
        number: string | number | undefined,
        verified: boolean,
    }
}

interface IFormInfo {
    firstName: string;
    lastName: string;
    phone?: string;
}

function useSubmitUserDetails() {
    const [submittingUserDetails, setSubmittingUserDetails] = useState(false);

    // Assuming the name of your callable function is 'updateTraderAccountDetails'
    const [updateUserDetails] = useHttpsCallable<IUserDetails, void>(
        firebase_functions,
        'updateTraderAccountDetails'
    );

    const submitUserDetails = useCallback(async (formInfo: IFormInfo) => {
        setSubmittingUserDetails(true);

        try {
            await updateUserDetails({
                firstName: formInfo.firstName,
                lastName: formInfo.lastName,
                phoneNumber: {
                    number: formInfo.phone,
                    verified: false,
                },
            });
            // Handle success if needed
        } catch (error) {
            // Handle error if needed
        } finally {
            setSubmittingUserDetails(false);
        }
    }, [updateUserDetails]);

    return { submittingUserDetails, submitUserDetails };
}

export default useSubmitUserDetails;
