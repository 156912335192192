import React from 'react';
import {Box} from "@mui/material";
import NestedList from "../../../components/NestedList";

function GreenChartStartProFeatures() {
    const featureList = [
        {
            feature: 'Strategy Suite',
            listItems: [
                'Pattern Builder: unlimited patterns',
                'Automated Backtester: 20 credits*',
                'Pattern Alerts: 15 custom alerts',
                'Alert Notifications: 360 per month',
                'Strategy Showcase - View/copy other users’ Patterns',
            ]
        },
        {
            feature: 'Trading Course: Currency Trading Unleashed',
            listItems: [
                '6 weeks of intensive training',
                '20 recorded live webinars',
                '15 key topics with prep material and quizzes',
                'Go at your own pace - webinars are recorded',
            ]
        },
        {
            feature: 'Weekly Webinars',
            listItems: [
                'Live Trading Room - Watch expert coaches trade live (Tues, Thur)',
                'Study Hall - Q&A with trading coaches (Wed)',
                'Strategy Suite Live - Learn to build and test your own strategies',
            ]
        },
    ];

    return (
        <Box sx={{
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            lineHeight: "1.5",
            letterSpacing: "0.00938em",
            fontSize: "16px",
            alignItems: "left",
            margin: "0px"
        }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <NestedList items={featureList} />
        </Box>
    );
}

export default GreenChartStartProFeatures;
