import PromoHelper from "./PromoHelper";
import MiniDrawer from "../components/Drawer";
import React from "react";
import Box from '@mui/material/Box';
import Header from "../../common/components/Header";
import {Navigate, Route, Routes} from "react-router-dom";
import TraderAccounts from "./TraderAccounts";
import Analytics from "./Analytics";
import AccountDetails from "./AccountDetails";
import {TraderAccountHelper} from "../contexts/TraderAccountContext";
import DataViewer from "./DataViewer";
import SubscriptionSummary from "./SubscriptionSummary";
import Footer from "../../common/components/Footer";
import GiftSubscription from "./GiftSubscription";

export default function AdminPanel() {

    return (<>
        <Box sx={{display: "flex", flexDirection: "row", minHeight: "95vh"}}>
            <MiniDrawer/>

            <Box sx={{ display: 'flex', flexDirection: "column", flexGrow: 1 }}>
                <Header/>
                <Box component="main" sx={{ flexGrow: 1, px: 4, py: 2}}>
                    <TraderAccountHelper>
                        <Routes>
                            <Route path='/' element={ <Navigate to="/admin/accounts" /> }/>
                            <Route path="/accounts" element={
                                <TraderAccounts/>
                            }/>
                            <Route path="/promo" element={
                                <PromoHelper/>
                            }/>
                            <Route path="/analytics" element={
                                <Analytics/>
                            }/>
                            <Route path="/accounts/details" element={
                                <AccountDetails/>
                            }/>
                            <Route path="/data" element={
                                <DataViewer/>
                            }/>
                            <Route path="/summary" element={
                                <SubscriptionSummary/>
                            }/>
                            <Route path="/gift" element={
                                <GiftSubscription/>
                            }/>
                        </Routes>
                    </TraderAccountHelper>
                </Box>
            </Box>
        </Box>
        <Footer/>
    </>)
}
